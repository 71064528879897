/********************************************************************
 * Created ON:06/11/2018
 * Created by: Avaya
 * Description: This is the root module of the application.
 *              All components and modules need to include in this file.
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { AuthGuard } from './shared/services/auth.guard';
import { ErrormodalComponent } from './shared/component/errormodal/errormodal.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';

import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { MenuComponent } from './menu/menu.component';
import { ProfileComponent } from './myprofile/profile/profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';

// import {
//   SocialLoginModule,
//   AuthServiceConfig,
//   GoogleLoginProvider,
//   FacebookLoginProvider,
//   LinkedinLoginProvider
// } from 'angular5-social-auth';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';

import { InitComponent } from './init/init.component';
import { HomeComponent } from './home/home.component';
import { SuccessmodalComponent } from './shared/component/successmodal/successmodal.component';
import { PaymentComponent } from './admin/payment/payment.component';
import { ViewDetailsComponent } from './admin/view-details/view-details.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ViewBillComponent } from './myprofile/view-bill/view-bill.component';
import { MylearningComponent } from './myprofile/mylearning/mylearning.component';
import { EditProfileComponent } from './myprofile/edit-profile/edit-profile.component';
import { ChangepasswordComponent } from './myprofile/changepassword/changepassword.component';
import { CommonService } from './shared/services/common.service';
import { RequestInterceptor } from './shared/services/httpInterceptor.service';
import { ErrorHandler } from './shared/services/errorHandler.service';
import { ImagePreloadDirective } from './shared/directive/image.directive';
import { CertificateComponent } from './myprofile/certificate/certificate.component';
import { QuizComponent } from './quiz/quiz.component';
import { MyProfileModule } from './myprofile/myprofile.module';
import { MuteService } from './shared/services/mute.service';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ServicelevelComponent } from './servicelevel/servicelevel.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ZoomComponent } from './zoom/zoom.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BadgesComponent } from './myprofile/badges/badges.component';
//import { LinkedinrouteComponent } from './auth/linkedinroute/linkedinroute.component';

// export function getAuthServiceConfigs() {
//   const config = new AuthServiceConfig(
//     [
//       {
//         id: FacebookLoginProvider.PROVIDER_ID,
//         provider: new FacebookLoginProvider(environment.fbKey)
//       },
//       {
//         id: GoogleLoginProvider.PROVIDER_ID,
//         provider: new GoogleLoginProvider(environment.googleKey)
//       },
//       {
//         id: LinkedinLoginProvider.PROVIDER_ID,
//         provider: new LinkedinLoginProvider(environment.liKey)
//       },
//     ]);
//   return config;
// }

/**
 *
 */
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    InitComponent,
    HomeComponent,
    MenuComponent,
    ProfileComponent,
    PaymentComponent,
    ViewBillComponent,
    EditProfileComponent,
    MylearningComponent,
    CertificateComponent,
    BadgesComponent,
    ChangepasswordComponent,
    SuccessmodalComponent,
    ErrormodalComponent,
    ViewDetailsComponent,
    ImagePreloadDirective,
    QuizComponent,
    TermsComponent,
    PrivacyComponent,
    ServicelevelComponent,
    ZoomComponent,
    //LinkedinrouteComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    MyProfileModule,
    SocialLoginModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    AuthModule,
  ],
  providers: [
    // {
    //   provide: AuthServiceConfig,
    //   useFactory: getAuthServiceConfigs
    // },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('clientId'),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    AuthGuard,
    ErrorHandler,
    BsModalRef,
    BsModalService,
    ComponentLoaderFactory,
    PositioningService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    CommonService,
    MuteService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
