import { NodeService } from '../shared/services/node.service';
import { StorageService } from '../shared/services/storage.service';

import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../shared/constant/apiEndpoint.constant';
import { WebApiService } from '../shared/services/web-api.service';
import { Router, NavigationEnd } from '@angular/router';
// import { BsModalService } from 'ngx-bootstrap/model';
import { BsModalService } from 'ngx-bootstrap/modal';

// import { BrowserDomAdapter } from '@angular/platform-browser/src/browser/browser_adapter';
import { CommonService } from '../shared/services/common.service';

/**
 *
 */
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  [x: string]: any;
  @Input() public menuOpen: boolean;
  @Input() public menuClose: boolean;
  public loading = false;
  public exp1 = 0;
  profileFirstName: string;
  public img: any;
  profileLastName: string;
  profileEmailId: string;
  profileImage: string;
  public islogin = true;
  public sound = '';
  public pauseFlag = true;
  public locationPointer = 99;
  public modalOpen = false;
  public audioUrl = '';
  public audioId: HTMLAudioElement;
  public closeFocusCounter = 0;
  public volumeIcon = 'Volume';
  public viewProg = false;
  buildings = {
    administration: '',
    buisness: '',
    commonSense: '',
    cooperative: '',
  };
  public pageOpen = false;

  /**
   *
   * @param modalService
   * @param modalRef
   * @param api
   * @param receptionServ
   * @param router
   * @param storageService
   * @param nodeService
   */
  constructor(
    private modalService: BsModalService,
    private modalRef: BsModalRef,
    private api: WebApiService,
    private receptionServ: CommonService,
    private router: Router,
    private storageService: StorageService,
    private nodeService: NodeService,
  ) {
    /** changes image, class and location pointer based on the cureent school*/
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.buildings.administration =
          '../../assets/img/menu/Administration-Building.png';
        this.buildings.commonSense =
          '../../assets/img/menu/School-of-Common-Sense-Design.png';
        this.buildings.buisness =
          '../../assets/img/menu/School-of-Business-Development.png';
        this.buildings.cooperative =
          '../../assets/img/menu/School-of-Cooperative-Understanding.png';
        switch (event.url) {
          case '/admin/1':
            this.locationPointer = 1;
            this.buildings.cooperative =
              '../../assets/img/menu/School-of-Cooperative-Understanding-sel.png';
            this.img =
              '../../assets/img/helix_bg/School_of_Cooperative_Understanding.jpg';
            this.receptionServ.setReceptionImg(this.img, 'admin_one');
            break;
          case '/admin/2':
            this.locationPointer = 2;
            this.buildings.commonSense =
              '../../assets/img/menu/School-of-Common-Sense-Design-sel.png';
            this.img =
              '../../assets/img/helix_bg/School_of_Common_Sense_Design.jpg';
            this.receptionServ.setReceptionImg(this.img, 'admin_two');
            break;
          case '/admin/3':
            this.locationPointer = 3;
            this.buildings.buisness =
              '../../assets/img/menu/School-of-Business-Development-sel.png';
            this.img = '../../assets/img/helix_bg/Business_School.jpg';
            this.receptionServ.setReceptionImg(this.img, 'admin_three');
            break;
          case '/admin/0':
            this.locationPointer = 0;
            this.buildings.administration =
              '../../assets/img/menu/Administration-Building-sel.png';
            this.img = '../../assets/img/helix_bg/Reception.png';
            this.receptionServ.setReceptionImg(this.img, 'default');
            break;
          default:
            this.img = '../../assets/img/helix_bg/Reception.png';
            this.receptionServ.setReceptionImg(this.img, 'default');
            this.locationPointer = 99;
        }
      }
    });
    this.loading = true;
  }

  /**
   *
   */
  ngOnInit() {
    this.modalRef.hide();
    this.storageService.getFromSessioStorage('userInfo');
    const LocalStorageToken =
      this.storageService.getFromLocalStorage('loginToken');
    if (LocalStorageToken) {
      this.storageService.addToSessionStorage('loginToken', LocalStorageToken);
    }
    const loginToken = this.storageService.getFromSessioStorage('loginToken');
    this.sound = '../../assets/img/sound-white.svg';
    if (!loginToken) {
      this.islogin = false;
    } else {
      if (loginToken) {
        const viewProfileReqData = {
          endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
          data: {
            user_token: loginToken,
          },
          params: {
            token: loginToken,
            function: API_FUNCTIONS.VIEW_PROFILE,
            format: 'json',
          },
        };
        this.api.postApiCall(viewProfileReqData).subscribe(
          (profileResponse: any) => {
            this.nodeService.addNode({
              profileImg: profileResponse.profile_img,
            });
            this.profileImage = profileResponse.profile_img;
            const userDetails = {
              first_name: profileResponse.firstname,
              last_name: profileResponse.lastname,
              email: profileResponse.email,
              profile_image: profileResponse.profile_img,
              user_id: profileResponse.id,
            };
            this.storageService.addToSessionStorage('userInfo', userDetails);
          },
          () => {},
        );
      }
    }
    // *******Checking if did login then change the flag to view the icons *******/
    this.nodeService.nodeObservable.subscribe((node) => {
      if (node['isLogin'] !== undefined) {
        this.islogin = node['isLogin'];
      }
      if (node['profileImg'] || node['profileImg'] == '') {
        this.profileImage = node['profileImg'];
      }
      if (node['modalopen'] !== undefined) this.modalOpen = node['modalopen'];
      if (node['viewProg'] !== undefined) this.viewProg = node['viewProg'];
      if (node['pageOpen'] !== undefined) this.pageOpen = node['pageOpen'];
    });

    if (this.storageService.getFromSessioStorage('mutePressed')) {
      this.muteAudio();
    }
  }
  /**
   * Pause function for audio
   */

  public muteAudio = () => {
    const allAudioTags = document.querySelectorAll('audio');
    this.pauseFlag = !this.pauseFlag;
    if (!this.pauseFlag) {
      this.sound = '../../assets/img/mute_icon.svg';
      this.volumeIcon = 'Muted';
      this.audioUrl = '../../assets/audio/menu/Audio_Off.mp3';
      this.storageService.addToSessionStorage('mutePressed', true);
      for (let i = 0; i < allAudioTags.length; i++) {
        allAudioTags[i].muted = true;
      }
    } else {
      this.sound = '../../assets/img/sound-white.svg';
      this.volumeIcon = 'Volume';
      this.audioUrl = '../../assets/audio/menu/Audio_On.mp3';
      this.storageService.removeFromSessionStorage('mutePressed');
      for (let i = 0; i < allAudioTags.length; i++) {
        allAudioTags[i].muted = false;
      }
    }
  };

  /**
   *
   */
  public menuOpenProfile() {
    this.router.navigate(['/mylearning']);
  }

  /**function to monitor opening and closing of the campus map */
  /**
   *
   */
  public menuFun() {
    this.menuOpen = !this.menuOpen;
    this.menuClose = !this.menuClose;
    this.menuOpen
      ? (this.audioUrl = '../../assets/audio/menu/Campus_Map_Page_Welcome.mp3')
      : (this.audioUrl = '');
    this.menuClose ? (this.closeFocusCounter = 0) : null;
    this.nodeService.addNode({ menuopen: this.menuOpen });
    if (this.menuOpen) {
      document.body.classList.add('menu-opened');
    } else {
      document.body.classList.remove('menu-opened');
      this.audioUrl = '';
    }
  }
  /**
   *
   */
  homePage() {
    this.router.navigate(['']);
    this.locationPointer = null;
    this.menuFun();
  }

  /**
   *
   * @param id
   */
  buildingSelection(id) {
    this.router.navigate(['/admin', id]);
    this.menuFun();
  }
  /**loads audios for each element */
  /**
   *
   * @param elementNo
   */
  public loadAudio(elementNo) {
    switch (elementNo) {
      case 0:
        if (this.menuOpen) {
          this.audioUrl = '../../assets/audio/shared/Close_Button.mp3';
          this.closeFocusCounter++;
        } else {
          this.audioUrl = '../../assets/audio/menu/Campus_Map_Icon.mp3';
        }
        break;
      case 1:
        this.audioUrl = '../../assets/audio/menu/Audio_Speaker_Icon.mp3';
        break;
      case 2:
        this.audioUrl = '../../assets/audio/shared/My_Learning_Button.mp3';
        break;
      case 3:
        this.audioUrl =
          '../../assets/audio/menu/Admissions_Building_on_Campus_Map.mp3';
        break;
      case 4:
        this.audioUrl =
          '../../assets/audio/menu/Common_Sense_Design_Building_on_Campus_Map.mp3';
        break;
      case 5:
        this.audioUrl =
          '../../assets/audio/menu/Business_Development_Building_on_Campus_Map.mp3';
        break;
      case 6:
        this.audioUrl = '../../assets/audio/menu/Cooperative_Map.mp3';
        break;
      case 7:
        this.audioUrl = '../../assets/audio/menu/Back_to_Campus_Icon.mp3';
        break;
      default:
        if (!(this.closeFocusCounter == 0 && this.menuOpen)) {
          this.audioUrl = '';
        }
    }

    /**paused the page audio on focusing or hovering on the menu components */
    this.audioId = document.getElementById('audiofile') as HTMLAudioElement;
    if (this.audioId)
      this.audioUrl === ''
        ? this.audioId.ended
          ? null
          : this.audioId.play()
        : this.audioId.pause();
    const adminAudio = <HTMLAudioElement>document.getElementById('AdminAudio');
    if (adminAudio && this.viewProg) {
      this.audioUrl == '' ? adminAudio.play() : adminAudio.pause();
    }
    const profile = <HTMLAudioElement>document.getElementById('myProfileAudio');
    if (profile && this.pageOpen) {
      this.audioUrl == '' ? profile.play() : profile.pause();
    }
    const classroom = <HTMLAudioElement>document.getElementById('classAudio');
    const classVideo = <HTMLVideoElement>document.getElementById('classVideo');
    if (classroom) {
      this.audioUrl == ''
        ? classroom.ended
          ? null
          : classroom.play()
        : classroom.pause();
      classVideo && this.audioUrl == ''
        ? classVideo.play()
        : classVideo.pause();
    }
  }
}
