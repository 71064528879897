/********************************************************************
 * Created ON:31/12/2018
 * Created by: Avaya
 * Description: Component for showing image. If for some reason
 * image is not loading, it will display the default image.
 * Revision History:
 * Modification date:
 * Description: T
 **********************************************************************/
import { Directive, Input, HostBinding } from '@angular/core';
/**
 *
 */
@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
  },
})
export class ImagePreloadDirective {
  @Input() src: string;
  @Input() default: string;
  @Input() newclass: string;
  @HostBinding('class') className;

  /**
   *
   */
  updateUrl() {
    this.src = this.default;
  }
  /**
   *
   */
  load() {
    this.className = this.newclass; // 'image-loaded';
  }
}
