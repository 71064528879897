/********************************************************************
 * Created ON:25/11/2018
 * Created by: Pallavi
 * Description: Subscribed program and lessons display file.
 * Every programs and lessons that is enrolled by the user is
 * displayed here
 * Revision History:
 * Modification date: 02/12/2018
 * Description: Unexpected hiding of the program view was fixed
 **********************************************************************/

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { WebApiService } from '../../shared/services/web-api.service';
import jspdf from 'jspdf';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { StorageService } from '../../shared/services/storage.service';
import { CommonService } from '../../shared/services/common.service';
import { NodeService } from '../../shared/services/node.service';
import { MuteService } from '../../shared/services/mute.service';
import html2canvas from 'html2canvas';

/**
 * Represents the data structure for a course.
 */
interface CourseData {
  token: any;
  course_id: any;
  lesson_id?: any;
} 

/**
 *
 */
@Component({
  selector: 'app-mylearning',
  templateUrl: './mylearning.component.html',
  styleUrls: ['./mylearning.component.css'],
})
export class MylearningComponent implements OnInit {
  @ViewChild('accGroup') el: ElementRef;
  public programListData: any = []; // incoming program details from profile modal;
  public mylearnings = false;
  public accordionIsOpen = false;
  public loading = false;
  public loginToken: any;
  public userDetails: any;
  public filter = 'all';
  public errormsg = 'No data available';
  public accordionIdx = -1;
  public toggleBtn = false;
  public page = '';
  public allAudioTags: any;
  public menuOpen = false;
  public prevState = {
    myLearningModal: false,
    filter: this.filter,
  };
  public accOpenState = [];
  public modalopen = false;

  /**
   *
   * @param api
   * @param router
   * @param bsModalRef
   * @param storageService
   * @param commonService
   * @param modalService
   * @param nodeService
   * @param muteService
   */
  constructor(
    private api: WebApiService,
    private router: Router,
    private bsModalRef: BsModalRef,
    private storageService: StorageService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private nodeService: NodeService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  public ngOnInit() {
    this.nodeService.nodeObservable.subscribe((node) => {
      if (node['menuopen'] !== undefined) {
        const profileAudio = <HTMLAudioElement>(
          document.getElementById('myProfileAudio')
        );
        profileAudio ? profileAudio.pause() : null;
      }
      if (node['modalopen'] !== undefined) {
        this.modalopen = node['modalopen'];
      }
    });

    const flag = this.storageService.getFromLocalStorage('prevState');
    if (flag != null && flag.myLearningModal) {
      this.filter = flag.filter;
    }
    this.page = flag.page;
    this.loginToken = this.storageService.getFromSessioStorage('loginToken');
    this.changeFilter(this.filter);
    this.userDetails = this.storageService.getFromSessioStorage('userInfo');
    this.muteService.muteFun();
  }

  /**
   *
   * @param filter based on lesson progress
   * method for filtering the user program based on progress made
   */
  changeFilter(filter) {
    this.loading = true;
    this.prevState.filter = filter;
    const requestDataMyLearning = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        token: this.loginToken,
        filter: filter,
      },
      params: {
        token: this.loginToken,
        function: API_FUNCTIONS.MY_COURSE,
        format: 'json',
      },
    };
    this.filter = filter;
    this.api.postApiCall(requestDataMyLearning).subscribe(
      (response: any) => {
        this.modalService.hide(this.modalService.getModalsCount());
        this.programListData = response.programs;
        for (let progIdx = 0; progIdx < this.programListData.length; progIdx++)
          this.accOpenState[progIdx] = false;
        this.programListData.forEach((program) => {
          program.lessons.forEach((lesson) => {
            let counter = 0;
            const modulescount = lesson.modules.length - 1;
            lesson.modules.forEach((moduleContent) => {
              if (
                moduleContent.mod_name != 'quiz' &&
                moduleContent.module_progress == 100
              ) {
                counter++;
              }
            });
            if (modulescount == counter) {
              lesson.quiz = true;
              //console.log(modulescount, '--->', counter)
            } else {
              lesson.quiz = false;
            }
          });
        });
        this.mylearnings = true;
        this.loading = false;
      },
      () => {
        this.loading = false;
        // this.modalService.hide(this.modalService.getModalsCount());
      },
    );
  }

  /**
   *
   * @param prog_id
   * @param school_id
   * @param lesson_id
   * @param rating
   * api call to rate lessons
   */
  public starRating(lesson_id, rating) {
    const requestRating = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        token: this.loginToken,
        section_id: lesson_id,
        star: rating,
      },
      params: {
        token: this.loginToken,
        function: API_FUNCTIONS.ADD_LESSON_RATING,
        format: 'json',
      },
    };
    this.api.postApiCall(requestRating).subscribe(
      () => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );
  }

  /**
   * API calls to rate Program
   *
   * @param prog_id
   * @param rating
   */
  public starRatingProg(prog_id, rating) {
    const requestRating = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        token: this.loginToken,
        course_id: prog_id,
        star: rating,
      },
      params: {
        token: this.loginToken,
        function: API_FUNCTIONS.ADD_USER_RATING,
        format: 'json',
      },
    };
    this.api.postApiCall(requestRating).subscribe(
      () => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );
  }

  /**
   *
   * @param event
   * @param pId
   * @param lid
   * @param progname
   * @param type
   */
  public downloadInvoice(event, pId, lid, progname, type) {
    event.stopPropagation();
    let data: CourseData = {
      token: this.loginToken,
      course_id: pId,
    };
    
    if (type !== 'prog') {
      data.lesson_id = lid;
    }

    const requestRating = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: data,
      params: {
        token: this.loginToken,
        function: API_FUNCTIONS.PROG_CERTIFICATE,
        format: 'json',
      },
    };
    this.loading = true;
    this.api.postApiCall(requestRating).subscribe(
      (response: any) => {
        this.loading = false; 
        if (!response.hasOwnProperty('certificate_url')) {
          alert(response);
        }
        else {
          window.open(response.certificate_url);
        }
        // this.bsModalRef.hide();
        // const initialState = { url: response.certificate_url };
        // this.bsModalRef = this.modalService.show(CertificateComponent, Object.assign({ initialState }, MODAL_CONFIG.certificateconfig));
      },
      () => {
        this.loading = false;
      },
    );
  }

  /**
   *
   * @param url
   */
  public openNewTab(url) {
    const newtab = window.open('', '_blank');
    newtab.document.write(
      "<object data='" +
        url +
        "' width='100%' height='auto' ></object><div><button id='donwloadBtn' data-url=" +
        url +
        ' >Download Certificate</button></div>',
    );

    const div = newtab.document.createElement('div');
    const t = newtab.document.createElement('template');
    t.innerHTML =
      "Check Console tab for javascript output: Hello world!!!<br/><script type='text/javascript' >setTimeout(function(){ alert(1); }, 3000);</script>";

    for (let i = 0; i < t.content.childNodes.length; i++) {
      const node = newtab.document.importNode(t.content.childNodes[i], true);
      div.appendChild(node);
    }
    newtab.document.body.appendChild(div);
  }
  /**
   * downloads pdf format certificate
   */

  /**
   *
   */
  downloadInvoicenew() {
    html2canvas(document.body).then(function (canvas) {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jspdf('p', 'px', [400, 400]);
      pdf.addImage(imgData, 'JPEG', 0, 0, 400, 400);
      pdf.save('screen-3.pdf');
    });
  }
  /**
   *
   * @param classroomObj
   * Redirection to classroom
   * @param progIdx
   * @param lessonIdx
   */
  public classroomSctions(progIdx, lessonIdx) {
    this.prevState.myLearningModal = true;
    this.storageService.addToLocalStorage('prevState', this.prevState);
    const queryParams = {
      pid: null,
      lid: null,
      mid: null,
      sid: 0,
    };
    const prog = this.programListData[progIdx];
    queryParams.pid = prog.program_id;
    if (lessonIdx !== null) {
      const lesson = prog.lessons[lessonIdx];
      queryParams.lid = lesson.lesson_id;
      for (let midx = 0; midx < lesson.modules.length; midx++) {
        if (
          lesson.modules[midx].mod_name != 'quiz' ||
          lesson.modules[midx].mod_name != 'zoom'
        )
          queryParams.mid = lesson.modules[midx].module_id;
        if (lesson.modules[midx].module_progress !== 100 && queryParams.mid)
          break;
      }
    } else {
      let foundMod = false;
      for (let lidx = 0; lidx < prog.lessons.length; lidx++) {
        for (let midx = 0; midx < prog.lessons[lidx].modules.length; midx++) {
          if (
            prog.lessons[lidx].modules.mod_name != 'quiz' ||
            prog.lessons[lidx].modules.mod_name != 'zoom'
          ) {
            queryParams.lid = prog.lessons[lidx].lesson_id;
            queryParams.mid = prog.lessons[lidx].modules[midx].module_id;
          }
          if (
            prog.lessons[lidx].modules[midx].module_progress !== 100 &&
            queryParams
          ) {
            foundMod = true;
            break;
          }
        }
        if (foundMod) break;
      }
    }
    this.router.navigate(['/courses/classroom'], {
      queryParams: queryParams,
    });
    this.bsModalRef.hide();
  }

  /**
   *
   * @param lessonId
   */
  public goToQuiz(lessonId) {
    let quizUrl = '';
    let moduleId: any;
    let lessonName: any;
    let progName: any;
    this.programListData.forEach((program) => {
      program.lessons.forEach((lesson) => {
        if (lesson.lesson_id == lessonId) {
          lesson.modules.forEach((modContent) => {
            if (modContent.mod_name == 'quiz') {
              quizUrl = modContent.content;
              moduleId = modContent.module_id;
              lessonName = lesson.lesson_title;
              progName = program.program_name;
            }
          });
        }
      });
    });
    quizUrl = quizUrl + '?id=' + moduleId + '&token=' + this.loginToken;
    this.router.navigate(['/myquiz'], {
      queryParams: {
        url: encodeURIComponent(quizUrl),
        progName: progName,
        lessonName: lessonName,
      },
    });
  }

  /**
   *
   * @param idx
   */
  public onKeyPressed(idx) {
    if (idx == this.accordionIdx)
      this.accordionIdx = this.accordionIdx >= 0 ? -1 : idx;
    else this.accordionIdx = idx;
  }
  /**
   *
   */
  public focusTotoggle() {
    this.toggleBtn = true;
  }
  /**
   *
   */
  public focusOutTotoggle() {
    this.toggleBtn = false;
  }
  /**
   *
   * @param eleNo
   */
  public loadAudio(eleNo) {
    const profileAudio = <HTMLAudioElement>(
      document.getElementById('myProfileAudio')
    );
    switch (eleNo) {
      case 0:
        profileAudio.src =
          '../../assets/audio/myprofile/mylearning/My_Learning_All_Button.mp3';
        break;
      case 1:
        profileAudio.src =
          '../../assets/audio/myprofile/mylearning/My_Learning_New_Button.mp3';
        break;
      case 2:
        profileAudio.src =
          '../../assets/audio/myprofile/mylearning/My_Learning_InProgress_Button.mp3';
        break;
      case 3:
        profileAudio.src =
          '../../assets/audio/myprofile/mylearning/My_Learning_Completed_Button.mp3';
        break;
      case 4:
        profileAudio.src =
          '../../assets/audio/myprofile/mylearning/Continue_Lesson_Button.mp3';
        break;
      case 5:
        profileAudio.src =
          '../../assets/audio/myprofile/mylearning/Start_Quiz_Button.mp3';
        break;
      case 6:
        profileAudio.src = '../../assets/audio/shared/Start_Lesson_Button.mp3';
        break;
      case 7:
        profileAudio.src = '../../assets/audio/shared/Start_Program_Button.mp3';
        break;
      case 8:
        profileAudio.src =
          '../../assets/audio/myprofile/mylearning/View_Certificate.mp3';
        break;
      default:
        profileAudio.src = '';
    }
  }
  /**
   *
   * @param status
   * @param progIdx
   */
  public accdChange(status, progIdx) {
    this.accOpenState[progIdx] = status;
  }
  /**
   *
   * @param progIdx
   */
  public accordionExpandAudio(progIdx) {
    const profileAudio = <HTMLAudioElement>(
      document.getElementById('myProfileAudio')
    );
    if (this.accOpenState[progIdx] == true) {
      profileAudio.src =
        '../../assets/audio/shared/Minimize_Accordion_Group_Button.mp3';
    } else
      profileAudio.src = '../../assets/audio/shared/Expand_Accordion_Group.mp3';
  }
}
