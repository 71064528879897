<div class="modal-body" *ngIf="!openState">
  <a
    (focus)="loadAudio(0)"
    (focusout)="loadAudio(99)"
    (mouseenter)="loadAudio(0)"
    (mouseleave)="loadAudio(99)"
    href="javascript:void(0)"
    type="button"
    class="close-back pull-left"
    aria-label="Close"
    (click)="closeModal()"
    tooltip="Close"
    placement="bottom"
  >
    <span aria-hidden="true">
      <img
        aria-hidden="true"
        src="../../assets/img/icn_back.svg"
        class="ic_back"
      />
    </span>
  </a>
  <div class="enroll-header" tabindex="0">
    <h3 [attr.aria-label]="'school name:' + schoolName">{{ schoolName }}</h3>
    <div class="left" style="width: calc(100% - 360px)">
      <h4>{{ programDetails.name }}</h4>
      <ul>
        <li>
          <img
            aria-hidden="true"
            src="../../assets/img/icn-author.svg"
            height="18"
          />
          <span [attr.aria-label]="'Author:' + programDetails.program_author">
            {{ programDetails.program_author }}</span
          >
        </li>
        <li>
          <img
            aria-hidden="true"
            src="../../assets/img/icn-lesson.svg"
            height="18"
          />
          <span
            [attr.aria-label]="
              'Total number of lessons' +
              programDetails.total_lessons +
              'Lessons'
            "
          >
            {{ programDetails.total_lessons }} Lessons
          </span>
        </li>
        <li>
          <img
            aria-hidden="true"
            src="../../assets/img/icn-time.svg"
            height="18"
          />
          <span
            [attr.aria-label]="
              'Duration of the program:' + programDetails.program_duration
            "
          >
            {{ programDetails.program_duration }}
          </span>
        </li>
        <li>
          <!-- <div class="rating">
            <rating [attr.aria-label]="programDetails.program_rating" [(ngModel)]="programDetails.program_rating"
              [max]="max" [readonly]="isReadonly" [titles]="['one','two','three']"></rating>
            <span>({{programDetails.program_rate_by}} {{programDetails.program_rate_by>1?'Ratings':'Rating'}})</span>
          </div> -->
        </li>
      </ul>
    </div>
    <div class="right" style="width: 330px">
      <h3
        [attr.aria-label]="'Cost :' + programDetails.cost"
        *ngIf="!programDetails.program_payment; else availProg"
        style="margin-left: 67px"
      >
        ${{ programDetails.cost }}
      </h3>

      <ng-template #availProg>
        <div
          class="acc-bottom-lt"
          style="width: calc(100% - 150px); margin-top: 6px; margin-left: -20px"
        >
          <div class="pro-bar pull-left">
            <progressbar [value]="programDetails.progress"></progressbar>
          </div>
          <div class="pro-bar-val">{{ programDetails.progress }}%</div>
        </div>
      </ng-template>
      <button
        (focus)="loadAudio(1, programDetails)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(1, programDetails)"
        (mouseleave)="loadAudio(99)"
        class="button button--aylen btn btn-primary"
        (click)="programActions(programDetails)"
      >
        {{
          programDetails.program_payment
            ? programDetails.progress == 0
              ? start
              : continue
            : enroll
        }}
      </button>
    </div>
  </div>
  <div class="enroll-content">
    <!-- <p *ngIf="programDetails.description!=''">{{programDetails.description}}</p> -->
    <p
      *ngIf="programDetails.description != ''"
      [innerHTML]="programDetails.description"
    ></p>
    <h6>Curriculum for this program</h6>
    <div class="enroll-content-inner">
      <accordion>
        <accordion-group
          [ngClass]="{ 'hide-child': lesson.modules.length == 0 }"
          [tabindex]="0"
          [id]="x + '-acc'"
          *ngFor="let lesson of programDetails.lessons; let x = index"
          (isOpenChange)="accdChange($event, x)"
          [isDisabled]="lesson.modules.length == 0 ? true : false"
          [isOpen]="x == accordionIdx ? true : false"
        >
          <div accordion-heading>
            <div class="head-wrap">
              <div class="rt-con">
                <div class="left-con" style="width: calc(100% - 270px)">
                  <div class="top-line">
                    <h4>Lesson {{ x + 1 }} : {{ lesson.name }}</h4>
                  </div>
                  <div class="bottom-line">
                    <ul>
                      <li>
                        <img
                          src="../../assets/img/icn-lesson.svg"
                          height="18"
                        />

                        {{ lesson.modules.length }} Modules
                      </li>
                      <li>
                        <img src="../../assets/img/icn-time.svg" height="18" />
                        {{ lesson.lesson_status.lesson_duration }}
                      </li>
                      <!-- <li>
                        <div class="rating">
                          <rating [(ngModel)]="lesson.lesson_status.lesson_rating" [max]="max" [readonly]="isReadonly"
                            [titles]="['one','two','three']"></rating>
                          ({{lesson.lesson_status.lesson_rate_by}}
                          {{lesson.lesson_status.lesson_rate_by>1?'Ratings':'Rating'}})
                        </div>
                      </li> -->
                    </ul>
                  </div>
                  <div style="clear: both"></div>
                  <div
                    (focus)="focusTotoggle()"
                    (focusout)="focusOutTotoggle()"
                    (focus)="accordionExpandAudio(x)"
                    (focusout)="accordionExpandAudio(-1)"
                    (mouseenter)="accordionExpandAudio(x)"
                    (mouseleave)="accordionExpandAudio(-1)"
                    [tabindex]="modalopen ? -1 : 0"
                    role="button"
                    aria-pressed="false"
                    (keypress)="onKeyPressed(x)"
                    class="ng-view-collapse"
                  >
                    <h5
                      class="view_details_accordian plus c-type"
                      style="text-align: left !important"
                      title="Press enter to expand"
                      [attr.aria-label]="
                        toggleBtn ? 'Press enter to view details' : x + 1
                      "
                    >
                      View Details
                    </h5>
                    <h5
                      class="view_details_accordian minus c-type"
                      style="text-align: left !important"
                      title="Press enter to collapse details"
                      [attr.aria-label]="
                        toggleBtn ? 'Press enter to collapse' : x + 1
                      "
                    >
                      Collapse Details
                    </h5>
                  </div>
                </div>

                <!-- <div *ngIf="lesson.lesson_cost==0" class="right-con">
                  <h3>${{lesson.lesson_cost}}</h3>
                  <button class="btn btn-primary" (click)="makePayment($event)" (click)="lessonAction(false,lesson)">Join</button>
                </div> -->
                <div
                  *ngIf="
                    !lesson.lesson_payment && !programDetails.program_payment;
                    else lessonPurchased
                  "
                  class="right-con"
                >
                  <h3>${{ lesson.lesson_cost }}</h3>
                  <div style="display: flex; flex-direction: column; float: right">
                  <button
                    (focus)="loadAudio(2)"
                    (focusout)="loadAudio(99)"
                    (mouseenter)="loadAudio(2)"
                    (mouseleave)="loadAudio(99)"
                    class="button button--aylen btn btn-primary"
                    (click)="makePayment($event)"
                    (click)="lessonAction(false, lesson, x)"
                    [disabled]="!lesson.allow_enrollment"
                  >
                    Enroll
                  </button>
                  <p *ngIf="!lesson.allow_enrollment" style="font-style: italic; margin-top: 2px; margin-left: 5px;">Included in course</p>
                  </div>
                </div>
                <div style="clear: both"></div>
                <!-- <ng-template #lessonPurchased>
                  <div class="right-con" style="width:270px">
                    <button *ngIf="lesson.lesson_status.progress == 0; else lessonProgressBar" (click)="lessonAction(true,lesson,x)"
                      (click)="modRef.hide()" class="btn btn-primary">Start</button>
                    <ng-template #lessonProgressBar>
                      <div *ngIf="lesson.lesson_status.progress == 100" class="acc-bottom-lt">
                        <div class="pro-bar">
                          <progressbar [value]="lesson.lesson_status.progress"> </progressbar>
                        </div>
                        <div class="pro-bar-val">{{lesson.lesson_status.progress}}% Completed</div>
                      </div>
                    </ng-template>
                  </div>
                </ng-template> -->
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
          <div class="acc-content">
            <div class="row">
              <p [innerHTML]="lesson.description"></p>
              <ul>
                <!-- <li>
                  <div class="lt-con">
                    <img src="../../assets/img/doc.svg" class="doc-icon" />
                    <div class="con-list">Introduction</div>
                    <button class="btn btn-secondary hide">free</button>
                    <button class="btn btn-secondary ">
                      <img src="../../assets/img/icn-tick-white.svg" width="14" /> Completed</button>
                  </div>
                  <div class="rt-con">
                    <h3 class="c-type">Start</h3>
                    <h3 class="c-type hide">Start Again</h3>
                  </div>
                </li> -->
                <!-- 24-Dec
                  == lesson.quizCounter? true:false):true 
                  to
                  != lesson.quizCounter? true:false):true
                 -->
                <li
                  *ngFor="let module of lesson.modules; let y = index"
                  [tabindex]="0"
                  [ngClass]="{
                    'view-details-border': y === 0 && lesson.lesson_description,
                    'view-border-bottom': y == lesson.modules.length - 1
                  }"
                >
                  <div class="lt-con" style="padding-top: 2px">
                    <img
                      src="../../assets/img/play-grey.svg"
                      [ngClass]="{
                        playactive:
                          (module.freeorintro ||
                            lesson.lesson_payment ||
                            programDetails.program_payment) &&
                          (module.mod_name == 'quiz'
                            ? lesson.moduleCount != lesson.quizCounter
                              ? true
                              : false
                            : true)
                      }"
                      class="play-icon"
                    />
                    <div class="con-list">
                      Module {{ y + 1 }}: {{ module.name }}
                    </div>
                    <!-- <img src="../../assets/img/icn-time.svg" class="play-icon" />

                    <div class="con-list">{{module.module_duration}}</div> -->
                    <span
                      *ngIf="
                        module.freeorintro &&
                        !(
                          lesson.lesson_payment ||
                          programDetails.program_payment
                        )
                      "
                    >
                      <div
                        *ngIf="module.module_progress !== 100"
                        class="modulestatus completed"
                      >
                        <img
                          src="../../assets/img/icn-tick-white.svg"
                          width="14"
                        />
                        free
                      </div>
                    </span>
                    <span
                      *ngIf="
                        (lesson.lesson_payment ||
                          programDetails.program_payment) &&
                        (module.mod_name == 'quiz'
                          ? lesson.moduleCount != lesson.quizCounter
                            ? true
                            : false
                          : true)
                      "
                    >
                      <div
                        *ngIf="
                          module.module_progress == 0;
                          else moduleCompleted
                        "
                        class="modulestatus start"
                      >
                        <img src="../../assets/img/icn-time.svg" height="16" />
                        {{ module.module_duration }}
                      </div>
                      <ng-template #moduleCompleted>
                        <div
                          *ngIf="module.module_progress == 100; else InProgress"
                          class="modulestatus completed"
                        >
                          <img
                            src="../../assets/img/icn-tick-white.svg"
                            width="14"
                          />
                          Completed
                        </div>
                        <ng-template #InProgress>
                          <div class="modulestatus in-progress">
                            <img
                              src="../../assets/img/icn-time-white.svg"
                              height="15"
                            />
                            In Progress
                          </div>
                        </ng-template>
                      </ng-template>
                    </span>
                  </div>
                  <div
                    (focus)="loadAudio(3)"
                    (focusout)="loadAudio(99)"
                    (mouseenter)="loadAudio(3)"
                    (mouseleave)="loadAudio(99)"
                    *ngIf="
                      module.freeorintro &&
                      !(lesson.lesson_payment || programDetails.program_payment)
                    "
                    class="rt-con insideBtn"
                    [tabindex]="0"
                    role="button"
                    aria-pressed="false"
                    (click)="
                      showFreeLesson(
                        module.module_id,
                        module.name,
                        freeModule,
                        lesson.name,
                        lesson.lesson_id
                      )
                    "
                    (keypress)="
                      showFreeLesson(
                        module.module_id,
                        module.name,
                        freeModule,
                        lesson.name,
                        lesson.lesson_id
                      )
                    "
                  >
                    <!-- Changes done here for classroom view -->
                    <h3
                      *ngIf="
                        module.module_progress == 0 ||
                          module.module_progress == 50;
                        else moduleCompletedBtn
                      "
                      class="c-type"
                    >
                      Begin
                    </h3>
                  </div>

                  <div
                    (focus)="loadAudio(4, programDetails, lesson)"
                    (focusout)="loadAudio(99)"
                    (mouseenter)="
                      loadAudio(
                        4,
                        programDetails,
                        lesson.lesson_payment,
                        module.module_progress
                      )
                    "
                    (mouseleave)="loadAudio(99)"
                    [tabindex]="0"
                    role="button"
                    aria-pressed="false"
                    (keypress)="moduleAction(lesson.lesson_id, module)"
                    *ngIf="
                      (lesson.lesson_payment ||
                        programDetails.program_payment) &&
                      (module.mod_name == 'quiz'
                        ? lesson.moduleCount != lesson.quizCounter
                          ? true
                          : false
                        : true)
                    "
                    class="rt-con insideBtn"
                    (click)="moduleAction(lesson.lesson_id, module)"
                  >
                    <h3
                      *ngIf="
                        module.module_progress == 0;
                        else moduleCompletedBtn
                      "
                      class="c-type"
                    >
                      Begin
                    </h3>
                    <ng-template #moduleCompletedBtn>
                      <h3
                        *ngIf="
                          module.module_progress == 100;
                          else InProgressBtn
                        "
                        class="c-type"
                      >
                        Start Again
                      </h3>
                      <ng-template #InProgressBtn>
                        <h3 class="c-type">Resume</h3>
                      </ng-template>
                    </ng-template>
                  </div>
                  <div style="clear: both"></div>
                  <div
                    class="inner-lesson-content"
                    [innerHTML]="module.lesson_short_description"
                  ></div>
                  <!-- <div class="rt-con" *ngIf="lesson.purchase_status">
                    <h3 class="c-type" (click)="moduleAction(module.module_status, lesson.lid,module.module_no,module.start_from,module.content_type)">
                      {{module.module_status}}</h3>
                  </div> -->
                </li>
              </ul>
            </div>
          </div>
          <div style="clear: both"></div>
        </accordion-group>
      </accordion>
      <div style="clear: both"></div>
    </div>
  </div>
</div>
<ng-template #freeModule>
  <div class="modal-body">
    <a
      href="javascript:void(0)"
      type="button"
      class="close-back pull-left"
      aria-label="Close Free Module"
      (click)="closeFreeModule()"
      (focus)="loadAudio(0)"
      (focusout)="loadAudio(99)"
      (mouseenter)="loadAudio(0)"
      (mouseleave)="loadAudio(99)"
    >
      <span aria-hidden="true" tooltip="close" placement="bottom">
        <img src="../../assets/img/icn_back.svg" class="ic_back" />
      </span>
    </a>
    <div class="enroll-header">
      <p>{{ freeModuleLesson }}</p>
      <div class="left" style="width: calc(100% - 360px)">
        <h2>{{ freeModName }}</h2>
      </div>
    </div>
    <div class="enroll-content-inner">
      <div *ngIf="!videoSupport; else videoNotSupported">
        <video
          width="100%"
          height="100%"
          controls
          #videoPlayer
          [src]="freeModUrl"
          preload="auto"
          autoplay
        ></video>
      </div>
      <ng-template #videoNotSupported>
        <div>This type of video is not supported</div>
      </ng-template>
    </div>
  </div>
</ng-template>
