/********************************************************************
 * Created ON:07/11/2018
 * Created by: Avaya
 * Description: This will hold the route for auth module
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { SignupComponent } from './signinsignup/signup.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { GooglerouteComponent } from './googleroute/googleroute.component';
import { FacebookrouteComponent } from './facebookroute/facebookroute.component';
import { LinkedinrouteComponent } from './linkedinroute/linkedinroute.component';

const routes: Routes = [
  { path: 'signup', component: SignupComponent },
  { path: 'frogot-password', component: ForgotpasswordComponent },
  { path: 'reset-password', component: ResetpasswordComponent },
  { path: 'google', component: GooglerouteComponent },
  { path: 'facebook', component: FacebookrouteComponent },
  { path: 'linkedin', component: LinkedinrouteComponent },
];

/**
 *
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
