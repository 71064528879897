<div>
  <audio
    id="audiofile"
    src="{{ audioUrl }}"
    autoPlay="true"
    controls
    class="hide"
  >
    <track
      label="Español"
      kind="subtitles"
      srclang="es"
      [src]="textUrl"
      (loadedmetadata)="mediaLoaded()"
    />
  </audio>
  <div [class]="popoverClass">
    <a
      class="m-circle-popover"
      (focus)="loadAudio(3)"
      (focusout)="loadAudio(99)"
      (mouseenter)="loadAudio(3)"
      (mouseleave)="loadAudio(99)"
      [attr.aria-label]="popoverText"
      (click)="hidePopoverDialogue()"
      href="javascript:void(0)"
      [tooltip]="popoverText"
      placement="left"
    >
      <span [attr.aria-hidden]="true" class="m-circle-sound"></span>
      <span [attr.aria-hidden]="true" class="sound-link">
        <span [attr.aria-hidden]="true" class="sound-icon">
          <img
            [attr.aria-hidden]="true"
            class="m-sound-icon"
            [src]="popoverIcon"
            alt="Toggle Text Box"
          />
        </span>
      </span>
    </a>
  </div>
  <img id="charImg" alt="decorative" [class]="newClass" [src]="animatedCharecter" />
  <div [class]="hideTextBox === true ? newClassDialogue : ' hide '">
    <div class="in-con" id="subtitles"></div>
  </div>
</div>
