import { tap } from 'rxjs/operators';
/**
 *
 * HTTP Interceptor
 * Description: API call passes through this Interceptor
 * Created Date: 05/11/2018
 * Created by: Avaya
 * Revision History:
 * Modification date:
 * Description:
 *
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { ErrorHandler } from './errorHandler.service';

/**
 *
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  /**
   *
   * @param errorHandler
   */
  constructor(
    public errorHandler: ErrorHandler, // public spinner: NgxSpinnerService
  ) {}

  /**
   *
   * @param request
   * @param next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // this.spinner.hide();
            this.errorHandler.handleError(err);
          }
        },
      ),
    );
  }
}
