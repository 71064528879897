export const MODAL_CONFIG = {
  freeModConfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'freeModal',
  },
  paymentconfig: {
    animated: true,
    keyboard: true,
    backdrop: false,
    ignoreBackdropClick: true,
    class: 'enroll-modal payment-modal',
  },
  viewdetailsconfig: {
    animated: true,
    keyboard: true,
    backdrop: false,
    ignoreBackdropClick: true,
    class: 'viewDetailsModal',
  },
  errormodalconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'childmodal successmodal',
  },
  signinconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'signin-modal',
  },
  successmodalconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'childmodal successmodal',
  },
  forgotconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'forgotmodal childmodal',
  },
  contactconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'contact-modal',
  },
  termsConfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'terms-modal',
  },
  profileconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'profile-modal',
  },
  certificateconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'certificate-modal',
  },
  editprofileconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'childmodal editprofile',
  },
  changepasswordconfig: {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'childmodal changepassword',
  },
};
