/********************************************************************
 * Created ON:29/11/2018
 * Created by: Avaya
 * Description: Forgot password feature from Signin Modal popup
 *
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { SuccessmodalComponent } from './../../shared/component/successmodal/successmodal.component';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WebApiService } from './../../shared/services/web-api.service';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../shared/services/common.service';
import { ErrormodalComponent } from '../../shared/component/errormodal/errormodal.component';
import { MODAL_CONFIG } from '../../shared/constant/modalConfig.constant';
import { NodeService } from '../../shared/services/node.service';
import { StorageService } from '../../shared/services/storage.service';
import { MuteService } from '../../shared/services/mute.service';
/**
 *
 */
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
})
export class ForgotpasswordComponent implements OnInit {
  public loading = false;
  public forgotPwdAudio = '';
  public allAudioTags: any;

  /**
   *
   * @param api
   * @param bsmodalref
   * @param commonService
   * @param bsModalService
   * @param nodeService
   * @param storageService
   * @param muteService
   */
  constructor(
    private api: WebApiService,
    private bsmodalref: BsModalRef,
    private commonService: CommonService,
    private bsModalService: BsModalService,
    private nodeService: NodeService,
    private storageService: StorageService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.muteService.muteFun();
  }
  /**
   * Forgot password form validation
   */
  public forgotPasswordForm = new UntypedFormGroup({
    emailId: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]),
  });
  /**
   * Forgot pasword API call
   */
  public forgotPassword = () => {
    this.loading = true;
    const requestData = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        email: this.forgotPasswordForm.controls.emailId.value,
      },
      params: {
        token: this.commonService.getWsToken(),
        function: API_FUNCTIONS.FORGOT_PASSWORD,
        format: 'json',
      },
    };

    this.api.postApiCall(requestData).subscribe(
      (response: any) => {
        this.loading = false;
        if (response.error_code) {
          const initialState = {
            success_message:
              'You’ve successfully submitted your password reset request.',
            from_module: 'forgot_password',
          };
          this.modalClose();
          this.bsmodalref = this.bsModalService.show(
            SuccessmodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.successmodalconfig),
          );
          this.nodeService.addNode({ modalopen: true });
          this.forgotPwdAudio =
            '../../assets/audio/Succesfully_Submitted_Password_Reset_Request.mp3';
        } else {
          const initialState = { error_message: response.message };
          this.modalClose();
          this.nodeService.addNode({ modalopen: true });
          this.bsmodalref = this.bsModalService.show(
            ErrormodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.successmodalconfig),
          );
        }
      },
      () => {
        this.loading = false;
      },
    );
  };

  public modalClose = () => {
    this.bsmodalref.hide();
    this.nodeService.addNode({ modalopen: false });
  };

  /**
   *
   * @param elementNo
   */
  public forgotPasswordAudio(elementNo) {
    switch (elementNo) {
      case 0:
        this.forgotPwdAudio = '../../assets/audio/Email_Field.mp3';
        break;
      case 1:
        this.forgotPwdAudio =
          '../../assets/audio/Submit_Password_Reset_Request_Button.mp3';
        break;
      default:
        this.forgotPwdAudio = '';
    }
  }
}
