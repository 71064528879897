<div class="rt-con-tab" style="overflow: scroll">
  <h2 class="title">Badges</h2>
  <div
    class="sign-other badges_details"
    *ngFor="let badge of badgeData; let certIdx = index"
  >
    <div class="left"><img src="{{ badge.image_url }}" /></div>
    <div class="right">
      <h3>Recipient details</h3>
      <dl>
        <dt>Name</dt>
        <dd>{{ profileFulltName }}</dd>
      </dl>
      <h3>Issuer details</h3>
      <dl>
        <dt>Issuer name</dt>
        <dd>{{ badge.issuer_name }}</dd>
        <dt>Contact</dt>
        <dd>
          <a href="mailto:{{ badge.issuer_contact }}">{{
            badge.issuer_contact
          }}</a>
        </dd>
      </dl>
      <h3>Badge details</h3>
      <dl>
        <dt>Name</dt>
        <dd>{{ badge.name }}</dd>
        <dt>Description</dt>
        <dd>{{ badge.description }}</dd>
        <dt>Crietria</dt>
        <dd [innerHTML]="badge.criteria"></dd>
      </dl>
      <h3>Badge expiry</h3>
      <dl>
        <dt>Date issued</dt>
        <dd>{{ badge.expirydate }}</dd>
        <dt>Evidence</dt>
        <dd [innerHTML]="badge.evidence"></dd>
      </dl>
    </div>
    <div style="clear: both"></div>
    <div class="share_details">
      <h4>Share your Achievements on</h4>
      <ul>
        <li class="linked-in">
          <a
            aria-label="Sign in with linkedin"
            href="javascript:void(0)"
            (focus)="loadAudio(2)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(2)"
            (mouseleave)="loadAudio(99)"
            (click)="shareOnLinkedIn(badge)"
          >
            <img src="../../assets/img/ic-in.svg" />
          </a>
        </li>
        <li class="twitter-icon">
          <a
            aria-label="Sign in with twitter"
            href="javascript:void(0)"
            (focus)="loadAudio(3)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(3)"
            (mouseleave)="loadAudio(99)"
            (click)="shareOnTwitter(badge)"
          >
            <img src="../../assets/img/ic-twitter.svg" />
          </a>
        </li>
        <li class="f-icon">
          <a
            aria-label="Sign in with facebook"
            href="javascript:void(0)"
            (focus)="loadAudio(4)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(4)"
            (mouseleave)="loadAudio(99)"
            (click)="shareOnFb(badge)"
          >
            <img src="../../assets/img/ic-fb.svg" />
          </a>
        </li>
      </ul>
    </div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }">
  </ngx-loading>
</div>
