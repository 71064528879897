<div class="modal-body">
  <a
    tooltip="close"
    placememt="left"
    href="javascript:void(0)"
    class="close pull-right"
    aria-label="Close"
    (click)="modalClose()"
    (focus)="closeAudio(0)"
    (focusout)="closeAudio(99)"
    (mouseleave)="closeAudio(99)"
    (mouseenter)="closeAudio(0)"
  >
    <span aria-hidden="true" class="btn-close">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <div class="left">
    <!-- <img src="assets/img/WorldChangersPhoto.jpg" class="responsive-img" /> -->
    <div class="left-con">
      <h2>Welcome To The <br />Helix Opportunity Institute</h2>
      <p>
        Please enter your information to sign in to, or register for, your
        account.
      </p>
    </div>
  </div>
  <div class="right">
    <div class="middle-con">
      <tabset type="pills" (click)="tabChange($event)">
        <tab
          class="signin"
          id="signin"
          heading="Sign in"
          (focus)="tabAudio(0)"
          (focusout)="tabAudio(99)"
          (mouseleave)="tabAudio(99)"
          (mouseenter)="tabAudio(0)"
        >
          <div class="tab-con">
            <form [formGroup]="signInForm">
              <div class="text-center">
                <span
                  *ngIf="
                    !loginResponse.loginsuccess && !signInForm.controls.touched
                  "
                  >{{ loginResponse.errormsg }}</span
                >
              </div>
              <div class="form-group mt-1">
                <label for="exampleInputEmail1">Email ID</label>
                <input
                  (focus)="signInLoadAudio(0)"
                  (focusout)="signInLoadAudio(99)"
                  (mouseenter)="signInLoadAudio(0)"
                  (mouseleave)="signInLoadAudio(99)"
                  type="email"
                  class="form-control"
                  id="exampleInputEmailSignin"
                  placeholder="Email"
                  formControlName="userName"
                />
                <span *ngIf="signInForm.controls.userName.touched">
                  <span
                    *ngIf="
                      signInForm.controls.userName.errors
                        ? signInForm.controls.userName.errors.required
                        : false;
                      else invalidSignEmail
                    "
                  >
                    This field is required
                  </span>
                  <ng-template #invalidSignEmail>
                    <span *ngIf="signInForm.controls.userName.invalid"
                      >Invalid email address</span
                    >
                  </ng-template>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <div class="input-group pwd-input">
                  <input
                    (focus)="signInLoadAudio(1)"
                    (focusout)="signInLoadAudio(99)"
                    (mouseenter)="signInLoadAudio(1)"
                    (mouseleave)="signInLoadAudio(99)"
                    [type]="showPwdSignIn ? 'text' : 'password'"
                    class="form-control"
                    id="exampleInputPasswordSignin"
                    placeholder="Password"
                    formControlName="password"
                  />
                  <span
                    class="input-group-addon"
                    [ngClass]="{ active: showPwdSignIn == true }"
                    style="width: 40px"
                    (click)="showPassword('signin')"
                    href="javascript:void(0)"
                  >
                    <img
                      class="pull-right eye-icon"
                      src="assets/img/eyeicon-hidden.svg"
                      alt=""
                    />
                  </span>
                </div>
                <!-- <input type="password" class="form-control" id="exampleInputPasswordSignin" placeholder="Password"
                  formControlName="password">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye" class="svg-inline--fa fa-eye fa-w-18 fa-fw fa-lg"
                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
                </svg> -->
                <span *ngIf="signInForm.controls.password.touched">
                  <span
                    *ngIf="
                      signInForm.controls.password.errors
                        ? signInForm.controls.password.errors.minlength
                        : false;
                      else invalidPassword
                    "
                  >
                    Password must contain atleast 8 characters
                  </span>
                  <ng-template #invalidPassword>
                    <span *ngIf="signInForm.controls.password.invalid"
                      >Password is required</span
                    >
                  </ng-template>
                </span>
              </div>
              <div class="checkbox" (click)="remembermeHandler()">
                <!-- <input type="checkbox" id="test1" class="form-control" formControlName="rememberMe" /> -->
                <input
                  [checked]="test"
                  (keyup.enter)="remembermeHandler()"
                  (focus)="signInLoadAudio(2)"
                  (focusout)="signInLoadAudio(99)"
                  type="checkbox"
                  id="rememberme"
                  class="form-control"
                  formControlName="rememberMe"
                />
                <label
                  (mouseenter)="signInLoadAudio(2)"
                  (mouseleave)="signInLoadAudio(99)"
                  for="test1"
                  >Remember me</label
                >
                <a
                  (focus)="signInLoadAudio(3)"
                  (focusout)="signInLoadAudio(99)"
                  (mouseenter)="signInLoadAudio(3)"
                  (mouseleave)="signInLoadAudio(99)"
                  href="javascript:void(0);"
                  class="forgot-pass pull-right"
                  (click)="openForgotPasswordModal()"
                  >Forgot Password</a
                >
              </div>
              <button
                type="button"
                (focus)="signInLoadAudio(4)"
                (focusout)="signInLoadAudio(99)"
                (mouseenter)="signInLoadAudio(4)"
                (mouseleave)="signInLoadAudio(99)"
                class="btn btn-default button button--aylen"
                [disabled]="!signInForm.valid"
                (click)="signIn()"
                (keypress)="signIn()"
              >
                SIGN IN
              </button>
              <div class="sign-other">
                <h4>Or Sign in with</h4>
                <ul>
                  <li class="linked-in">
                    <a
                      (focus)="signInLoadAudio(7)"
                      (focusout)="signInLoadAudio(99)"
                      (mouseenter)="signInLoadAudio(7)"
                      [attr.aria-label]="'Sign in with linkedin'"
                      (mouseleave)="signInLoadAudio(99)"
                      [href]="ssourl ? ssourl.Linkedin_login_url : ''"
                    >
                      <img
                        src="../../assets/img/ic-in.svg"
                        (click)="socialSignIn('linkedin')"
                      />
                    </a>
                  </li>
                  <li class="g-plus">
                    <a
                      (focus)="signInLoadAudio(6)"
                      (focusout)="signInLoadAudio(99)"
                      (mouseenter)="signInLoadAudio(6)"
                      [attr.aria-label]="'Sign in with google'"
                      (mouseleave)="signInLoadAudio(99)"
                      [href]="ssourl ? ssourl.Google_login_url : ''"
                    >
                      <img
                        src="../../assets/img/ic-g.svg"
                        (click)="socialSignIn('google')"
                      />
                    </a>
                  </li>
                  <li class="f-icon">
                    <a
                      (focus)="signInLoadAudio(5)"
                      (focusout)="signInLoadAudio(99)"
                      (mouseenter)="signInLoadAudio(5)"
                      [attr.aria-label]="'Sign in with facebook'"
                      (mouseleave)="signInLoadAudio(99)"
                      [href]="ssourl ? ssourl.facebook_login_url : ''"
                    >
                      <img
                        src="../../assets/img/ic-fb.svg"
                        (click)="socialSignIn('facebook')"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </tab>
        <tab
          class="register"
          id="register"
          heading="Register"
          (focus)="tabAudio(1)"
          (focusout)="tabAudio(99)"
          (mouseleave)="tabAudio(99)"
          (mouseenter)="tabAudio(1)"
        >
          <div class="tab-con register">
            <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
              <div style="margin-bottom: 5px" class="text-center">
                <span
                  style="font-weight: bold"
                  *ngIf="!signupResponse.signupSuccess"
                  >{{ signupResponse.errormsg }}</span
                >
              </div>
              <div class="row">
                <div class="col-xs-6">
                  <div class="form-group">
                    <label for="FirstName">First Name</label>
                    <input
                      (focus)="registerLoadAudio(0)"
                      (focusout)="registerLoadAudio(99)"
                      (mouseleave)="registerLoadAudio(99)"
                      (mouseenter)="registerLoadAudio(0)"
                      type="text"
                      class="form-control"
                      id="FirstName"
                      placeholder="First Name"
                      formControlName="firstName"
                    />
                    <span
                      *ngIf="
                        signUpForm.controls.firstName.errors
                          ? signUpForm.controls.firstName.errors.maxlength
                          : false;
                        else requiredFirstName
                      "
                    >
                      Maximum length exceeded
                    </span>
                    <ng-template #requiredFirstName>
                      <span
                        *ngIf="
                          signUpForm.controls.firstName.errors
                            ? signUpForm.controls.firstName.errors.required &&
                              signUpForm.controls.firstName.touched
                            : false;
                          else invalidFirstName
                        "
                      >
                        This field is required
                      </span>
                      <ng-template #invalidFirstName>
                        <span
                          *ngIf="
                            signUpForm.controls.firstName.invalid &&
                            signUpForm.controls.firstName.touched
                          "
                          >Invalid First name</span
                        >
                      </ng-template>
                    </ng-template>
                  </div>
                </div>
                <div class="col-xs-6">
                  <div class="form-group">
                    <label for="LastName">Last Name</label>
                    <input
                      (focus)="registerLoadAudio(1)"
                      (focusout)="registerLoadAudio(99)"
                      (mouseleave)="registerLoadAudio(99)"
                      (mouseenter)="registerLoadAudio(1)"
                      type="text"
                      class="form-control"
                      id="LastName"
                      placeholder="Last Name"
                      formControlName="lastName"
                    />
                    <span
                      *ngIf="
                        signUpForm.controls.lastName.errors
                          ? signUpForm.controls.lastName.errors.maxlength
                          : false;
                        else invalidLastName
                      "
                    >
                      Maximum length exceeded
                    </span>
                    <ng-template #invalidLastName>
                      <span
                        *ngIf="
                          signUpForm.controls.lastName.invalid &&
                          signUpForm.controls.lastName.touched
                        "
                        >Invalid Last name</span
                      >
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email ID</label>
                <input
                  (focus)="registerLoadAudio(2)"
                  (focusout)="registerLoadAudio(99)"
                  (mouseleave)="registerLoadAudio(99)"
                  (mouseenter)="registerLoadAudio(2)"
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Email"
                  formControlName="emailId"
                />
                <span
                  *ngIf="
                    signUpForm.controls.emailId.errors
                      ? signUpForm.controls.emailId.errors.maxlength
                      : false;
                    else ReqiredEmail
                  "
                >
                  Maximum length exceeded
                </span>
                <ng-template #ReqiredEmail>
                  <div *ngIf="signUpForm.controls.emailId.touched">
                    <span
                      *ngIf="
                        signUpForm.controls.emailId.errors
                          ? signUpForm.controls.emailId.errors.required
                          : false;
                        else invalidEmail
                      "
                    >
                      This field is required
                    </span>
                    <ng-template #invalidEmail>
                      <span *ngIf="signUpForm.controls.emailId.invalid"
                        >Invalid email address</span
                      >
                    </ng-template>
                  </div>
                </ng-template>
              </div>
              <div class="form-group">
                <label for="Password">Password</label>
                <div class="input-group pwd-input">
                  <input
                    (focus)="registerLoadAudio(3)"
                    (focusout)="registerLoadAudio(99)"
                    (mouseleave)="registerLoadAudio(99)"
                    (mouseenter)="registerLoadAudio(3)"
                    [type]="showPwdRegister ? 'text' : 'password'"
                    class="form-control"
                    id="Password"
                    placeholder="Password"
                    formControlName="passwords"
                  />

                  <span
                    class="input-group-addon"
                    [ngClass]="{ active: showPwdRegister == true }"
                    style="width: 40px"
                    (click)="showPassword('register')"
                  >
                    <img
                      class="pull-right eye-icon"
                      src="assets/img/eyeicon-hidden.svg"
                      alt=""
                    />
                  </span>
                </div>
                <span
                  *ngIf="
                    signUpForm.controls.passwords.errors
                      ? signUpForm.controls.passwords.errors.maxlength
                      : false;
                    else MinPassword
                  "
                >
                  Password length exceeded
                </span>
                <ng-template #MinPassword>
                  <div *ngIf="signUpForm.controls.passwords.touched">
                    <span
                      *ngIf="
                        signUpForm.controls.passwords.errors
                          ? signUpForm.controls.passwords.errors.minlength
                          : false;
                        else requiredPassword
                      "
                    >
                      Password must contain atleast 8 characters
                    </span>
                    <ng-template #requiredPassword>
                      <span
                        *ngIf="
                          signUpForm.controls.passwords.errors
                            ? signUpForm.controls.passwords.errors.required
                            : false;
                          else InvalidPassword
                        "
                      >
                        This field is required
                      </span>
                      <ng-template #InvalidPassword>
                        <span *ngIf="signUpForm.controls.passwords.invalid"
                          >Invalid password! Password must contain:
                          <div>
                            1.Atleast one numeric character and one special
                            character
                          </div>
                          <div>2.One uppercase and lowercase character</div>
                        </span>
                      </ng-template>
                    </ng-template>
                  </div>
                </ng-template>
              </div>
              <div class="form-group">
                <label for="ConfirmPassword">Confirm Password</label>
                <div class="input-group pwd-input">
                  <input
                    (focus)="registerLoadAudio(4)"
                    (focusout)="registerLoadAudio(99)"
                    (mouseleave)="registerLoadAudio(99)"
                    (mouseenter)="registerLoadAudio(4)"
                    [type]="showPwdConfirm ? 'text' : 'password'"
                    [ngClass]="{
                      'has-error':
                        signUpForm.hasError('mismatch') == true &&
                        signUpForm.controls.confirmPasswords.touched
                    }"
                    class="form-control"
                    id="ConfirmPassword"
                    placeholder="Confirm Password"
                    formControlName="confirmPasswords"
                  />
                  <span
                    class="input-group-addon"
                    [ngClass]="{ active: showPwdConfirm == true }"
                    style="width: 40px"
                    (click)="showPassword('confirm')"
                  >
                    <img
                      class="pull-right eye-icon"
                      src="assets/img/eyeicon-hidden.svg"
                      alt=""
                    />
                  </span>
                </div>
                <div
                  *ngIf="
                    signUpForm.controls.confirmPasswords.touched &&
                    (signUpForm.controls.confirmPasswords.invalid ||
                      signUpForm.hasError('mismatch'))
                  "
                >
                  <span
                    *ngIf="
                      signUpForm.controls.confirmPasswords.touched &&
                        signUpForm.controls.confirmPasswords.invalid;
                      else pwdMismatch
                    "
                  >
                    This field is required
                  </span>
                  <ng-template #pwdMismatch>
                    <span> Passwords do not match</span>
                  </ng-template>
                </div>
              </div>

              <button
                (focus)="registerLoadAudio(5)"
                (focusout)="registerLoadAudio(99)"
                (mouseleave)="registerLoadAudio(99)"
                (mouseenter)="registerLoadAudio(5)"
                type="submit"
                class="btn btn-default button button--aylen"
                [disabled]="!signUpForm.valid"
              >
                Register
              </button>
            </form>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
<audio
  id="signInAudio"
  [src]="signinAudio"
  autoPlay="true"
  controls
  class="hide"
></audio>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }">
</ngx-loading>
