import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

/**
 *
 */
@Injectable()
export class MuteService {
  public allAudioTags: any;
  /**
   *
   * @param storageService
   */
  constructor(private storageService: StorageService) {}
  /**
   *
   */
  public muteFun() {
    const muteFlag = this.storageService.getFromSessioStorage('mutePressed');
    this.allAudioTags = document.querySelectorAll('audio');
    if (muteFlag && this.allAudioTags) {
      for (let i = 0; i < this.allAudioTags.length; i++) {
        this.allAudioTags[i].muted = true;
      }
    } else {
      for (let i = 0; i < this.allAudioTags.length; i++) {
        this.allAudioTags[i].muted = false;
      }
    }
  }
}
