<div class="modal-body policy">
  <a
    tooltip="close"
    placememt="left"
    href="javascript:void(0)"
    class="close pull-right"
    aria-label="Close"
    (click)="modalClose()"
  >
    <span aria-hidden="true" class="btn-close">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <h2 class="heading" style="text-align: center">Privacy Policy</h2>
  <h2 class="heading">Posted: September 18, 2020</h2>
  <div class="policy_p">
    <!-- <p class="policy_p">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
      The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
      here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
      use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
      infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
      the like).</p> -->
    <p>
      Your information privacy is important to us. We provide this Privacy
      Policy to explain how we collect, use, protect, and disclose information
      and data when you use the Helix Opportunity website (“Site”) and any Helix
      Opportunity mobile application, application programming interfaces, other
      services offered by Helix Opportunity, and any other time you interact
      with our Company, Helix Opportunity. (“Services”). This Privacy Policy
      also explains your choices for managing your information preferences,
      including opting out of certain uses of your Personal Information (defined
      below). This Privacy Policy applies to all users of the Site and Services.
      The Site and Services are provided by Helix Opportunity (“<b>Company”</b>
      “<b>we</b>” “<b>our</b>” “<b>us</b>”). <br />
      <br />
      BY USING THE SITE OR SERVICES, YOU ARE CONSENTING TO THIS PRIVACY POLICY.
      PLEASE READ IT CAREFULLY. <br />
      <br />
      <b>1. Personal Information We Collect </b> <br />
      We collect the following categories and types of
      <span style="font-style: italic">“Personal Information”: </span>
      <br /><br />
      <span style="padding-left: 2em; display: inline-block">
        <ul>
          <li>
            <b>Contact Information:</b> your first and last name, alias, mailing
            address, email address, and phone number;
          </li>
          <br />
          <li>
            <b>Other identifying information:</b> IP address, social media user
            names, passwords and other security information for registration,
            authentication, and access;
          </li>
          <br />
          <li>
            <b>Financial Information:</b> credit card, debit card and bank
            account information;
          </li>
          <br />
          <li>
            <b>Demographic information:</b> gender, age, and employment
            information;
          </li>
          <br />
          <li>
            <b>Internet or other electronic activity:</b> your browsing and
            click history, including information about how you navigate within
            our Site and Services and which elements of our Site and Services
            you use the most;
          </li>
          <br />
          <li><b>Audio and visual information:</b> video or photo content;</li>
          <br />
          <li>
            <b>Commercial information:</b> Services purchased or viewed on our
            Site; and
          </li>
          <br />
          <li>
            <b>Inferences drawn from the categories described above</b> in order
            to create a profile about you to reflect your preferences,
            characteristics, behavior and attitude.
          </li>
          <br />
        </ul>
      </span>
      <b>2. How We Use Personal Information </b> <br />
      We use your Personal Information for the following categories of use:
      <br />

      <span style="padding-left: 2em; display: inline-block">
        <ul>
          <li>
            <b>Transactional Purposes: </b> We use your contact information,
            financial information, and commercial information to: <br /><br />
            <ul>
              <li>
                Receive, process, confirm, and track your registration,
                subscription and training progress;
              </li>
              <li>
                Communicate with you about your registration, subscription and
                training, including requests for feedback on training; and
              </li>
              <li>
                Process any subscription or registration you make to one of our
                Services.
              </li>
            </ul>
          </li>
          <li>
            <b>Analytical Purposes: </b> We use your other identifying
            information, internet activity and browsing history, commercial
            information, and demographic information, to analyze preferences,
            trends and statistics. <br /><br />
          </li>
          <li>
            <b>Marketing and Promotional Purposes: </b> We use your contact
            information, commercial information, demographic information,
            internet or other electronic activity, and inferences to:
            <br /><br />
            <ul>
              <li>Inform you of our new products, services and offers; and</li>
              <li>
                Provide you with other information from and about our Company,
                including personalized marketing communications.
              </li>
            </ul>
          </li>

          <li>
            <b>Maintenance and Improvement of the Site and Services: </b> We use
            your contact information, commercial information, and internet
            activity and browsing history to: <br /><br />
            <ul>
              <li>
                Provide you with the Site and Services, including to send you
                alerts about your account;
              </li>
              <li>Handle your customer services requests; and</li>
              <li>
                Help us diagnose technical and service problems and administer
                our stores, websites and apps.
              </li>
            </ul>
          </li>
          <br />
          <li>
            <b>Review and content creation purposes: </b> We use your contact
            information, commercial information, and audio and visual
            information to enable reviews of our products and to display content
            that you have created and allowed us to display on our Site and
            Services. <br />
          </li>
          <li>
            <b>Security and Fraud Prevention: </b> We use your contact
            information, other identifying information, commercial information,
            financial information, internet activity and browsing history, and
            inferences to protect the Site and services, our Company, and others
            and to prevent fraud, theft and misconduct. <br />
          </li>
        </ul>
      </span>

      <b>3. Sources of Personal Information </b> <br />
      We collect Personal Information from the following sources: <br /><br />
      <span style="padding-left: 2em; display: inline-block">
        <ul>
          <li>
            <b>We collect information directly from you.</b>We collect contact
            and demographic information directly from you. We also collect
            payment information from you.
          </li>
          <li>
            <b>We collect information from you passively.</b>We collect Internet
            or other electronic activity passively using tools like browser
            cookies. This activity is further described in the Cookies and
            Advertising and Online Tracking sections below.
          </li>
        </ul>
      </span>

      <b>4. Categories of Third Parties We Share Personal Information With </b>
      <br />
      <span style="font-style: italic">Service Providers</span><br /><br />

      From time to time, we may establish a business relationship with other
      businesses whom we believe trustworthy and who have confirmed that their
      privacy practices are consistent with ours (“<b>Service Providers</b>”).
      For example, we may contract with Service Providers to provide certain
      services, such as hosting and maintenance, data storage and management,
      and marketing and promotions. We only provide our Service Providers with
      the information necessary for them to perform these services on our
      behalf. Each Service Provider must agree to use reasonable security
      procedures and practices, appropriate to the nature of the information
      involved, in order to protect your Personal Information from unauthorized
      access, use, or disclosure. Service Providers are prohibited from using
      Personal Information other than as specified by us. <br /><br />
      <span style="font-style: italic">Our Affiliates</span><br /><br />

      We may share Personal Information with businesses controlling, controlled
      by, or under common control with our Company.
      <br /><br />
      <span style="font-style: italic">Corporate Transactions</span><br /><br />

      If our Company is merged, acquired, or sold, or in the event of a transfer
      of some or all of our assets, we may disclose or transfer Personal
      Information in connection with such transaction. You will have the
      opportunity to opt out of any such transfer if, in our discretion, it will
      result in the handling of your Personal Information in a way that differs
      materially from this Privacy Policy.
      <br /><br />
      <span style="font-style: italic"
        >Compliance with Laws and Law Enforcement</span
      ><br /><br />

      We cooperate with government and law enforcement officials and private
      parties to enforce and comply with the law. We may disclose Personal
      Information and any other information about you to government or law
      enforcement officials or private parties if, in our discretion, we believe
      it is necessary or appropriate in order to respond to legal requests
      (including court orders and subpoenas), to protect the safety, property,
      or rights of our company or of any third party, to prevent or stop any
      illegal, unethical, or legally actionable activity, or to comply with the
      law<br /><br />

      <b>5. Managing Your Information Preferences</b><br /><br />
      You can review, correct, update, or change your Personal Information or
      opt out of receiving certain e-mails by changing the relevant settings in
      your account or by e-mailing us at [dfazio@helixopp.com. You can also opt
      out of receiving marketing e-mails from us by following the link provided
      at the bottom of all marketing e-mails you receive from us. You are able
      to opt out of receiving marketing e-mails from us, however, you cannot opt
      out of receiving all e-mails from us, such as e-mails about the status of
      your account. If you have questions or concerns regarding this Privacy
      Policy, please e-mail us at that same address.
      <br /><br />

      <b>6. Be Careful When You Share Information with Others</b><br /><br />

      Please be aware that whenever you share information on any public area of
      the Site or Services, that information may be accessed by others. In
      addition, please remember that when you share information in any other
      communications with third parties, that information may be passed along or
      made public by others. This means that anyone with access to such
      information can potentially use it for any purpose, including sending
      unsolicited communications.
      <br /><br />

      <b>7. Security</b><br /><br />

      We maintain physical, electronic, and procedural safeguards to protect the
      confidentiality and security of information transmitted to us. However, no
      data transmission over the Internet or other network can be guaranteed to
      be 100% secure. As a result, while we strive to protect information
      transmitted on or through the Site or Services, we cannot and do not
      guarantee the security of any information you transmit on or through the
      Site or Services, and you do so at your own risk.
      <br /><br />

      <b>8. Links</b><br /><br />

      Our Site and Services may contain links to other websites or allow others
      to send you such links. A link to a third party’s website does not mean
      that we endorse it or that we are affiliated with it. We do not exercise
      control over third-party websites. You access such third-party websites or
      content at your own risk. You should always read the privacy policy of a
      third-party website before providing any information to the website.
      <br /><br />

      <b>9. Children’s Privacy</b><br /><br />

      The Site and Services are intended for users who are 13 years old or
      older. We do not knowingly collect Personal Information from children
      under the age of 13. If we become aware that we have inadvertently
      received Personal Information from a child under the age of 13, we will
      delete such information from our records.
      <br /><br />

      <b>10. Processing in the United States</b><br /><br />

      Please be aware that your Personal Information and communications may be
      transferred to and maintained on servers or databases located outside your
      state, province, or country. If you are located outside of the United
      States, please be advised that we process and store all information in the
      United States. The laws in the United States may not be as protective of
      your privacy as those in your location. By using the Site or Services, you
      are agreeing to the collection, use, transfer, and disclosure of your
      Personal Information and communications will be governed by the applicable
      laws in the United States.

      <br /><br />

      <b>11. Special Note for EU Users—Your Rights and Choices</b><br /><br />

      The data controller for the Site and Services is:<br /><br />
      David Fazio, Helix Opportunity LLC at 833 Market Street Suite 421, San
      Francisco, CA 94103. You may have certain rights under EU data protection
      legislation with respect to your Personal Information, such as the right
      to:<br /><br />

      <span style="padding-left: 1.5em; display: inline-block">
        <ul>
          <li>
            request access to and be provided with information on the processing
            of your Personal Information;
          </li>
          <li>
            request corrections of your Personal Information if such Personal
            Information proves inaccurate or incomplete;
          </li>
          <li>
            request your Personal Information to be deleted (i) if it is no
            longer necessary for the purposes described in this Privacy Policy,
            (ii) should you decide to withdraw your consent where you gave it,
            (iii) should you object to its processing for direct marketing
            purposes, (iv) should you identify that it would have been
            unlawfully processed, (v) should we be under a legal obligation to
            erase it to comply with EU law or the law of an EU country and
            always provided we are not under a legal obligation to keep it;
          </li>
          <li>
            restrict the processing of your Personal Information (i) if you
            contest its accuracy; (ii) if the processing is unlawful and you
            oppose the erasure; (iii) if it is no longer needed for the purposes
            set out in this Privacy Policy but you require it for the
            establishment, exercise or defense of a legal claim, (iv) if you
            object to profiling;
          </li>
          <li>
            object to the processing of your Personal Information for direct
            marketing purposes or to any processing which we would have based on
            our legitimate interest (e.g. profiling);
          </li>
          <li>
            receive Personal Information which you provided to us in a
            structured, commonly used and machine-readable format and to
            transmit it to another provider provided it is processed based on
            your consent or our contract; and
          </li>
          <li>
            lodge a complaint with your relevant data protection authority.
          </li>
        </ul>
      </span>
      <br />
      If you wish to exercise any of your above rights, please reach out to us
      by any of the ways mentioned in the “Managing Your Information
      Preferences” section of this Privacy Policy. The principle that we apply
      is that your Personal Information will be deleted when they are no longer
      necessary for the purpose for which they were collected.<br /><br />

      <b>12. Special Note for California Users – Your Rights and Choices </b
      ><br /><br />
      If you are a California resident, you have certain privacy rights under
      the California Consumer Privacy Act (“<b>CCPA Rights</b>”). This Section
      describes those rights and how you can exercise them with our Company.
      <br /><br />
      <span style="text-decoration-line: undeline !important; font-weight: bold"
        >CCPA Rights Requests</span
      ><br /><br />
      <span style="text-decoration: underline">Right to Know</span><br /><br />
      You can request what Personal Information we have collected about you in
      the preceding 12 months.<br /><br />
      You can request the specific pieces of Personal Information we have
      collected about you.<br /><br />
      You can also request the following categories of information:<br /><br />

      <span style="padding-left: 1.5em; display: inline-block">
        <ul>
          <li>
            The categories of Personal Information we have collected about you.
          </li>
          <li>
            The categories of sources from which the Personal Information was
            collected.
          </li>
          <li>
            The business or commercial purpose for collecting or selling the
            Personal Information.
          </li>
          <li>
            The categories of third parties with whom we shared Personal
            Information.
          </li>
        </ul> </span
      ><br /><br />
      To submit a request to know, please send an email to dfazio@helixopp.com.
      You must use “California Request to Know Information” or “California Right
      to Know Categories” in the subject line of your email, as appropriate. You
      must provide us with your first name, last name, email address, and phone
      number. To verify your identity, we will match that information to the
      information we have on our systems. To designate an authorized agent to
      act on your behalf you must send a signed, written authorization
      containing your first name, last name, email address, and phone number to:
      <br /><br />
      <span style="text-align: right"
        >[833 Market Street Suite 421, San Francisco, CA 94103.</span
      ><br /><br />

      You can also call us toll free at +1 866-795-6890 to submit your request
      by phone. You must provide us with all of the information set forth above
      in any such request, including what request you are making. <br /><br />

      <span style="text-decoration-line: undeline !important; font-weight: bold"
        >Right to Deletion </span
      ><br /><br />

      You can also request that we delete your Personal Information. We may not
      delete all of your Personal Information if one of the following exceptions
      applies: <br /><br />

      <span style="padding-left: 1.5em; display: inline-block">
        <ul>
          <li>
            <b>Transactional:</b> to complete a transaction for which the
            Personal Information was collected, provide a good or service
            requested by you, or perform a contract we have with you;
          </li>
          <li><b>Security:</b> to detect data security incidents;</li>
          <li><b>Error Correction:</b> to debug or repair any errors;</li>
          <li>
            <b>Legal:</b> to protect against fraud or illegal activity or to
            comply with applicable law or a legal obligation, or exercise rights
            under the law, such as the right to free speech; or
          </li>
          <li>
            <b>Internal Use:</b> to use your Personal Information, internally,
            in a lawful manner that is compatible with the context in which you
            provided the information (i.e. to improve our services).
          </li>
        </ul> </span
      ><br /><br />
      We will also maintain a record of your CCPA Rights requests. <br /><br />
      Please note that if we delete your Personal Information, many of our
      services will not work the same. For example, your previous opt out
      requests will not be saved and saved preferences and information will no
      longer be available. <br /><br />
      To submit a request to delete, please send an email to
      dfazio@helixopp.com. You must use “California Request to Delete” in the
      subject line of your email. You must provide us with your first name, last
      name, email address, and phone number. To verify your identity, we will
      match that information to the information we have on our systems. To
      designate an authorized agent to act on your behalf you must send a
      signed, written authorization containing your first name, last name, email
      address, and phone number to:<br /><br />
      [833 Marhet Street Suite 421, San Francisco, CA 94103.<br /><br />
      You can also call us toll free at 866-795-6890 to submit your request by
      phone. You must provide us with all of the information set forth above in
      any such request.<br /><br />
      <b>Do Not Sell My Personal Information </b><br /><br />
      We do not “sell” (as sell is defined in CCPA) any of your Personal
      Information.
      <br /><br />
      <b>Non-Discrimination</b><br /><br />
      We will not discriminate against you for exercising any of your CCPA
      Rights and we will not deny you goods or services, charge you a different
      price, or provide you with a lesser quality of goods or services if you
      exercise any of your CCPA Rights.<br /><br />

      <b>13. Privacy Policy Changes</b><br /><br />
      We may change this Privacy Policy from time to time. If we decide to
      change this Privacy Policy, we will inform you by posting the revised
      Privacy Policy on the Site. Those changes will go into effect on the
      “Revised” date shown in the revised Privacy Policy. By continuing to use
      the Site or Services, you are consenting to the revised Privacy Policy.<br /><br />
      <b>14. Contact Us</b><br /><br />
      If you have any questions or concerns, you may contact us at
      dfazio@helixopp.com. Please print a copy of this privacy policy for you
      records and PLEASE check the Site frequently for any changes.<br /><br />
    </p>
  </div>
</div>
