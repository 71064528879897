import { StorageService } from '../shared/services/storage.service';
import { NodeService } from '../shared/services/node.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
//import { Component, OnInit, TemplateRef, HostListener,ngAfterViewInit } from '@angular/core';
import {
  Component,
  OnInit,
  TemplateRef,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { SignupComponent } from '../auth/signinsignup/signup.component';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../shared/constant/apiEndpoint.constant';
import { WebApiService } from '../shared/services/web-api.service';
import { CommonService } from '../shared/services/common.service';
import { ContactUsComponent } from '../shared/component/contact-us/contact-us.component';
import { ErrormodalComponent } from '../shared/component/errormodal/errormodal.component';
import { MODAL_CONFIG } from '../shared/constant/modalConfig.constant';
import { MuteService } from '../shared/services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  public modalRef: BsModalRef;
  public showPanel = false;
  public signInData: any = {};
  public registerData: any = {};
  public isLogin = true;
  public backPressed: boolean;
  public programInfo: any = [];
  public landingData: any = {
    admission: {
      name: '',
      displayText: '',
      audio: '',
    },
    cooperativeSchool: {
      name: '',
      displayText: '',
      audio: '',
    },
    commonSenseSchool: {
      name: '',
      displayText: '',
      audio: '',
    },
    buissnessSchool: {
      name: '',
      displayText: '',
      audio: '',
    },
  };
  public adoUrl = '';
  public txtUrl = '';
  public innerWidth: any;
  public innerHeight: any;
  public totalwh: any;
  public totalScale: any;
  public transformY: any;
  public homeAudioUrl = '';
  public hotspot = false;
  public menuOpen = false;
  public modalOpen = false;
  public loading = false;

  public pop_page1 = false;
  public pop_page2 = false;
  public pop_page3 = false;
  public pop_page4 = false;
  public mylearnings = false;
  public audioId: any;
  private allAudioTags: any;

  /**
   *
   * @param router
   * @param modalService
   * @param api
   * @param regExp
   * @param bsModalRef
   * @param commonService
   * @param storageService
   * @param nodeService
   * @param muteService
   */
  constructor(
    private router: Router,
    private modalService: BsModalService,
    private api: WebApiService,
    private regExp: CommonService,
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private storageService: StorageService,
    private nodeService: NodeService,
    private muteService: MuteService,
  ) {}
  /**
   *
   */
  @HostListener('window:resize', ['$event'])
  ngAfterViewInit() {
    if (!document.getElementById('siteseal').innerHTML.trim()) {
      const node1 = document.createElement('script');
      node1.src =
        'https://seal.godaddy.com/getSeal?sealID=7R1JvfNpVtDYGvlXkFrXaXpYojRgTFi97BMYK0vgWbMh7lrf0sj603q70qQB';
      node1.type = 'text/javascript';
      node1.async = true;
      document.getElementById('siteseal').appendChild(node1);
    }
  }
  /**
   *
   */
  ngOnInit() {
    //alert('hello'+this.commonService.getPreviousUrl());
    if (this.commonService.getPreviousUrl().match(/linkedin.*/)) {
      console.log('inside');
      console.log('' + this.commonService.getPreviousUrl());
      window.location.reload();
    }
    this.nodeService.addNode({ returnHome: true });
    const audioElement = <HTMLAudioElement>document.getElementById('audiofile');
    audioElement ? audioElement.play() : null;

    this.muteService.muteFun();
    this.hotspot = false;
    this.pop_page1 = false;
    this.pop_page2 = false;
    this.pop_page3 = false;
    this.pop_page4 = false;
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.totalwh = this.innerWidth + this.innerHeight;
    this.totalScale = this.totalwh / 2304;
    const aaa = this.innerWidth / this.innerHeight;

    if (aaa < 1.78) {
      const scval = this.totalScale + 0.1;
      this.transformY = 'scaleY(' + scval + ')';
    }
    if (this.totalwh < 1793) {
      this.transformY = 'scaleY(1.34)';
    }
    if (this.totalwh > 1793 && this.totalwh < 2304) {
      this.transformY = 'scaleY(1.12)';
    }

    const localStorageToken =
      this.storageService.getFromLocalStorage('loginToken');
    if (localStorageToken) {
      this.storageService.addToSessionStorage('loginToken', localStorageToken);
    }
    const loginToken = this.storageService.getFromSessioStorage('loginToken');
    if (!loginToken) {
      this.isLogin = false;
    }

    /**
     * Taking the flag value when the user is logout or login
     */
    this.nodeService.nodeObservable.subscribe((node) => {
      if (node['isLogin'] !== undefined) this.isLogin = node['isLogin'];
      if (node['menuopen'] !== undefined) {
        this.menuOpen = node['menuopen'];
      }
      if (node['modalopen'] !== undefined) {
        this.modalOpen = node['modalopen'];
        this.audioId = <HTMLAudioElement>document.getElementById('audiofile');
        if (this.modalOpen == false) {
          this.audioId
            ? this.audioId.ended
              ? null
              : this.audioId.play()
            : null;
        } else {
          this.audioId ? this.audioId.pause() : null;
        }
      }

      /********** */
      // const node1 = document.createElement( 'script' );
      // node1.src = 'https://seal.godaddy.com/getSeal?sealID=7R1JvfNpVtDYGvlXkFrXaXpYojRgTFi97BMYK0vgWbMh7lrf0sj603q70qQB';
      // node1.type = 'text/javascript';
      // node1.async = true;
      // document.getElementById( 'siteseal' ).appendChild(node1);
      /************ */
    });

    /**
     * Calling Config API
     */
    this.loading = true;
    const requestData = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: { session_token: loginToken ? loginToken : '' },
      params: {
        token: this.commonService.getWsToken(),
        function: API_FUNCTIONS.CONFIG,
        format: 'json',
      },
    };
    this.api.postApiCall(requestData).subscribe(
      (response: any) => {
        this.programInfo = [];
        this.loading = false;
        if (response.error_code == 0) {
          /**
           * Setting audio and video file URL
           */
          this.adoUrl = '../../assets/audio/homepage/LMS_Campus_Intro.mp3';
          this.txtUrl = '../../assets/vtt/LMS_Campus_Intro.vtt';

          const schools = response.siteconfig.schools;
          for (let index = 0; index < schools.length; index++) {
            /**Fetching school information based on school order */
            switch (schools[index].orderno) {
              case 0:
                this.landingData.admission = {
                  name: schools[index].name,
                  displayText: schools[index].display_text,
                  ausio: schools[index].audio_url,
                  displayImg: '../../assets/img/hover_img/Reception.png',
                };
                break;
              case 1:
                this.landingData.cooperativeSchool = {
                  name: schools[index].name,
                  displayText: schools[index].display_text,
                  ausio: schools[index].audio_url,
                  displayImg:
                    '../../assets/img/hover_img/School-of-Cooperative-Understanding.jpg',
                };
                break;
              case 2:
                this.landingData.commonSenseSchool = {
                  name: schools[index].name,
                  displayText: schools[index].display_text,
                  ausio: schools[index].audio_url,
                  displayImg:
                    '../../assets/img/hover_img/School-of-Common-Sense-Design.jpg',
                };
                break;
              case 3:
                this.landingData.buissnessSchool = {
                  name: schools[index].name,
                  displayText: schools[index].display_text,
                  ausio: schools[index].audio_url,
                  displayImg: '../../assets/img/hover_img/Business-School.jpg',
                };
                break;
              default:
                this.landingData.admission = {
                  name: schools[index].name,
                  displayText: schools[index].display_text,
                  ausio: schools[index].audio_url,
                  displayImg: '../../assets/img/hover_img/Reception.png',
                };
            }
            /**Organising school's program data to send to admin buildings */
            if (schools[index].orderno != 0) {
              this.programInfo.push(schools[index]);
            }
          }
          this.commonService.setProgListing(this.programInfo);
          const paypalDetails = {
            prog_notify_url: response.siteconfig.course_notify_url,
            paypal_account: response.siteconfig.courselevel_paypal_account,
            currency: response.siteconfig.currency,
            lesson_notify_url: response.siteconfig.lesson_notify_url,
            paypal_address: response.siteconfig.paypal_address,
          };
          this.commonService.setPaypalDetails(paypalDetails);
        } else {
          this.loading = false;
          this.bsModalRef.hide();
          const initialState = { error_message: response.message };
          this.bsModalRef = this.modalService.show(
            ErrormodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
          );
        }
      },
      () => {
        this.loading = false;
      },
    );
  }

  /**To adjust the display to respective screen size */
  /**
   *
   */
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.totalwh = this.innerWidth + this.innerHeight;
    this.totalScale = this.totalwh / 2304;
    this.totalScale;
    const aaa = this.innerWidth / this.innerHeight;

    if (aaa < 1.78) {
      const scval = this.totalScale + 0.1;
      this.transformY = 'scaleY(' + scval + ')';
    }
    if (this.totalwh < 1793) {
      this.transformY = 'scaleY(1.34)';
    }
    if (this.totalwh > 1793 && this.totalwh < 2304) {
      this.transformY = 'scaleY(1.12)';
    }
  }

  public signInForm = new UntypedFormGroup({
    userName: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  /**
   * signup form validation
   */
  // public strongRegex = this.regExp.regExpForPassword();
  public signUpForm = new UntypedFormGroup(
    {
      firstName: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('[A-Za-z ]+'),
      ]),
      lastName: new UntypedFormControl('', [Validators.pattern('[A-Za-z ]+')]),
      userName: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      passwords: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.commonService.strongRegex),
      ]),
      confirmPasswords: new UntypedFormControl('', [Validators.required]),
    },
    this.passwordMatchValidator,
  );

  /**
   * Password retype validation
   */

  /**
   *
   * @param g
   */
  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('passwords').value === g.get('confirmPasswords').value
      ? null
      : { mismatch: true };
  }
  /**
   *
   * @param signin
   * Opening signin modal
   * @param eventSignin
   */
  public signinModal(signin: TemplateRef<any>, eventSignin) {
    eventSignin.preventDefault();
    this.nodeService.addNode({ modalopen: true });
    this.signInForm.reset();
    this.signUpForm.reset();

    this.modalRef = this.modalService.show(
      SignupComponent,
      MODAL_CONFIG.signinconfig,
    );
    this.modalRef.content.onSigninModalClose = () => {
      this.nodeService.addNode({ modalopen: false });
      this.audioId.ended ? null : this.audioId.play();
      this.isLogin = true;
      //******Adding to node after successful login *******/
      this.nodeService.addNode({ isLogin: this.isLogin });
      this.modalRef.hide();
    };
  }

  /**
   * opening contactus modal
   */
  public contactModal() {
    this.modalRef = this.modalService.show(
      ContactUsComponent,
      MODAL_CONFIG.contactconfig,
    );
    this.nodeService.addNode({ modalopen: true });
  }
  /**
   *
   * @param id
   * onclick of the explore in home page.
   */
  exploreadminPage(id) {
    this.showPanel = true;
    this.hotspot = true;
    setTimeout(() => {
      this.router.navigate(['/admin', id]);
    }, 3000);

    if (id == 1) {
      this.childadminPage1(event);
    } else if (id == 2) {
      this.childadminPage2(event);
    } else if (id == 0) {
      this.childadminPage3(event);
    } else if (id == 3) {
      this.childadminPage4(event);
    }
  }
  /* Page first */
  /**
   *
   */
  adminPage1() {
    this.pop_page1 = true;
    this.pop_page2 = false;
    this.pop_page3 = false;
    this.pop_page4 = false;
  }
  /**
   *
   * @param event
   */
  childadminPage1(event: Event) {
    event.stopPropagation();
    this.pop_page1 = false;
  }
  /* /Page first */
  /* Page second */
  /**
   *
   */
  adminPage2() {
    this.pop_page1 = false;
    this.pop_page2 = true;
    this.pop_page3 = false;
    this.pop_page4 = false;
  }
  /**
   *
   * @param event
   */
  childadminPage2(event: Event) {
    event.stopPropagation();
    this.pop_page2 = false;
  }
  /* /Page second */
  /* Page third */
  /**
   *
   */
  adminPage3() {
    this.pop_page2 = false;
    this.pop_page1 = false;
    this.pop_page3 = true;
    this.pop_page4 = false;
  }
  /**
   *
   * @param event
   */
  childadminPage3(event: Event) {
    event.stopPropagation();
    this.pop_page3 = false;
  }
  /* /Page third */
  /* Page fourth */
  /**
   *
   */
  adminPage4() {
    this.pop_page4 = true;
    this.pop_page2 = false;
    this.pop_page3 = false;
    this.pop_page1 = false;
  }
  /**
   *
   * @param event
   */
  childadminPage4(event: Event) {
    event.stopPropagation();
    this.pop_page4 = false;
  }
  /* /Page fourth */

  /**
   *
   */
  public gotoClassroom() {
    this.router.navigate(['/mylearning']);
  }

  /**
   * Close modal function
   */
  closeModal() {
    this.modalRef.hide();
  }
  /**
   * Open Teams of Use component modal
   */
  public openToU = () => {
    this.router.navigate(['/terms']);
    //this.modalRef = this.modalService.show(TermsOfUseComponent, MODAL_CONFIG.termsConfig);
    //this.nodeService.addNode({ modalopen: true });
  };
  /**
   * Opening policy modal
   */
  public openPolicy = () => {
    this.router.navigate(['/privacy']);
    // this.modalRef = this.modalService.show(PolicyComponent, MODAL_CONFIG.termsConfig);
    //this.nodeService.addNode({ modalopen: true });
  };

  public openSla = () => {
    this.router.navigate(['/sla']);
    // this.modalRef = this.modalService.show(PolicyComponent, MODAL_CONFIG.termsConfig);
    //this.nodeService.addNode({ modalopen: true });
  };

  /**
   * Function for setting audio and video file URL
   *
   * @param elementNo
   */
  public loadAudio(elementNo) {
    this.audioId = <HTMLAudioElement>document.getElementById('audiofile');
    switch (elementNo) {
      case 0:
        this.adoUrl = '../../assets/audio/homepage/LMS_Admission_Building.mp3';
        this.txtUrl = '../../assets/vtt/LMS_Admission_Building.vtt';
        break;
      case 1:
        this.adoUrl =
          '../../assets/audio/homepage/Cooperative_Understanding_Building_Campus_View.mp3';
        this.txtUrl =
          '../../assets/vtt/Cooperative_Understanding_Building_Campus_View.vtt';
        break;
      case 2:
        this.adoUrl =
          '../../assets/audio/homepage/Common_Sense_Design_Building_Campus_View.mp3';
        this.txtUrl =
          '../../assets/vtt/Common_Sense_Design_Building_Campus_View.vtt';
        break;
      case 3:
        this.adoUrl =
          '../../assets/audio/homepage/Business_Development_Building_Campus_View.mp3';
        this.txtUrl =
          '../../assets/vtt/Business_Development_Building_Campus_View.vtt';
        break;
      case 4:
        this.homeAudioUrl = '../../assets/audio/homepage/Contact_Us_Icon.mp3';
        this.audioId.pause();
        break;
      case 5:
        this.homeAudioUrl = '../../assets/audio/homepage/Register_signin.mp3';
        this.audioId.pause();
        break;
      case 6:
        this.homeAudioUrl = '../../assets/audio/shared/My_Learning_Button.mp3';
        this.audioId.pause();
        break;
      default:
        this.homeAudioUrl = '';
        if (!this.modalOpen) {
          this.audioId.ended ? null : this.audioId.play();
        }
    }
  }
}
