<div
  *ngIf="!menuOpen && !modalOpen"
  class="admission"
  [ngClass]="{ active: showPanel === true, inactive: returnBack === true }"
  [class]="receptionData.receptionClass"
>
  <!--<img src="../../assets/img/BG-Image.jpg" class="home-img" *ngIf="delay_zero" />-->
  {{ moveAnimation }}
  <img
    [src]="receptionData.receptionImg"
    [@state]="state"
    (@state.done)="animationDone($event)"
    class="admission-img"
  />
  <div
    id="foo"
    class="hellix-main admission-main"
    [ngStyle]=""
    ngClass="{{ receptionClass }}"
  >
    <div class="program-wrapper" *ngIf="!modalOpen">
      <div *ngIf="schoolId == 0; else displaySchool" class="program-container">
        <div class="pro-header">
            <img
              tabindex="0"
              [src]="isEnlarged ? '../../assets/img/minimise.png' : '../../assets/img/enlarge.png'"
              aria-label="Enlarge Screen"
              width="2%"
              height="15%"
              style="float: right; margin-top: 113px"
              (click)="enlargeScreen()" (keydown.enter)="enlargeScreen()"
            />
          <h2>WELCOME TO ADMINISTRATION TRAINING CATALOG</h2>
          <p>
            Welcome inside Helix Opportunity Institute’s Admissions. Please,
            browse all of the training content offered by our different schools
            located on the screen behind me.
          </p>
        </div>

        <tabset>
          <tab
            *ngFor="let listData of programListData; let i = index"
            [active]="i === prevSelectedTab"
            [heading]="listData.name"
            [id]="i + 1"
            (select)="changeTab(i)"
          >
            <div
              class="pro-tab-con"
              *ngFor="let programData of listData.programs; let j = index"
              [tabindex]="modalOpen ? -1 : 0"
            >
              <!-- <tab *ngFor="let listData of programListData.schools; let i = index" [hidden]="schoolId === 0? false : (listData.school_id === schoolId? false:true)"          [heading]="listData.school_name" id="tab1"> -->

              <div class="pro-tab-list row">
                <div class="left col-md-4 col-xs-12">
                  <h2 [attr.aria-label]="'Program name is' + programData.name">
                    {{ programData.name }}
                  </h2>
                  <ul>
                    <li>
                      <img
                        aria-hidden="true"
                        src="../../assets/img/icn-author.svg"
                        height="18"
                      />
                      <span
                        [attr.aria-label]="
                          'Author is' + programData.program_author
                        "
                        >{{ programData.program_author }}
                      </span>
                    </li>

                    <li>
                      <img
                        aria-hidden="true"
                        src="../../assets/img/icn-lesson.svg"
                        height="18"
                      />
                      <span
                        [attr.aria-label]="
                          'Total number of lessons is' +
                          programData.total_lessons
                        "
                      >
                        {{ programData.total_lessons }} Lessons
                      </span>
                    </li>

                    <li>
                      <img
                        aria-hidden="true"
                        src="../../assets/img/icn-time.svg"
                        height="18"
                      />
                      <span
                        [attr.aria-label]="
                          'Duration of the program is
                    ' + programData.program_duration
                        "
                        >{{ programData.program_duration }}</span
                      >
                    </li>

                    <!-- <li>                    <div class="rating" [popover]="template" placement="bottom" triggers="focus">                      <rating [attr.aria-label]="'program rating is ' + programData.program_rating"                        [(ngModel)]="programData.program_rating" [max]="max" [readonly]="isReadonly"                        [titles]="['one','two','three']"></rating>                      ({{programData.program_rate_by}} {{programData.program_rate_by>1?'Ratings':'Rating'}})                      <ng-template #template>                        <div class="tooltip-con">                          <h2>Overall Rating</h2>                          <div class="text-center">                            <rating [attr.aria-label]="'overall rating' + programData.program_rating"                              [(ngModel)]="programData.program_rating" [max]="max" [readonly]="isReadonly"                              [titles]="['one','two','three']"></rating>                            ({{programData.program_rate_by}} {{programData.program_rate_by>1?'Ratings':'Rating'}})                          </div>                        </div>                        <ul>                          <li *ngFor="let lesson of programData.lessons; let k = index;">                            Lesson {{k+1}}                            <rating tabindex="0" [attr.aria-label]="lesson.lesson_status.lesson_rating"                              [(ngModel)]="lesson.lesson_status.lesson_rating" [max]="max" [readonly]="isReadonly"                              [titles]="['one','two','three']"></rating>                            ({{lesson.lesson_status.lesson_rate_by}}                            {{lesson.lesson_status.lesson_rate_by>1?'Ratings':'Rating'}})                          </li>                        </ul>                      </ng-template>                    </div>                  </li> -->
                  </ul>
                </div>

                <div class="right col-md-8 col-xs-12">
                  <h3 *ngIf="!programData.program_payment">
                    ${{ programData.cost }}
                  </h3>
                  <button
                    (focus)="loadAudio(0)"
                    (focusout)="loadAudio(99)"
                    (mouseenter)="loadAudio(0)"
                    (mouseleave)="loadAudio(99)"
                    class="button button--aylen btn btn-primary"
                    (click)="ViewDetails(i, j)"
                  >
                    View Details
                  </button>
                  <button
                    (focus)="loadAudio(1, programData)"
                    (focusout)="loadAudio(99)"
                    (mouseenter)="loadAudio(1, programData)"
                    (mouseleave)="loadAudio(99)"
                    class="button button--aylen btn btn-primary"
                    (click)="programActions(programData, j, i)"
                  >
                    {{
                      programData.program_payment
                        ? programData.progress == 0
                          ? start
                          : continue
                        : enroll
                    }}
                  </button>
                  <!-- <button *ngIf="!programData.prog_purchase_status; else PurchasedBlock" class="btn btn-primary" (click)="enrollPayment();">Enroll</button> -->

                  <!-- <ng-template #PurchasedBlock>                  <button *ngIf="programData.prog_progress== '0';else courseContinue" class="btn btn-primary"></button>                  <ng-template #courseContinue>                    <button class="btn btn-primary">Continue</button>                  </ng-template>                </ng-template> -->
                </div>
                <div style="clear: both"></div>
                <div
                  class="short-description"
                  [innerHTML]="programData.short_description"
                ></div>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
    <ng-template #displaySchool>
      <div
        class="program-container"
        *ngFor="let listData of programListData; let i = index"
      >
        <!--                changes extra <a>-->

        <div class="pro-header">
            <img
            [src]="isEnlarged ? '../../assets/img/minimise.png' : '../../assets/img/enlarge.png'"
              width="2%"
              height="15%"
              alt="Toggle screen size"
              style="float: right; margin-top: 113px"
              (click)="enlargeScreen()" (keydown.enter)="enlargeScreen()" tabindex="0"
            />
          <h2>{{ listData.name }}'S TRAINING CATALOG</h2>
          <p>{{ schoolDisplayContent }}</p>
        </div>

        <div class="enroll-content">
          <div *ngFor="let programData of listData.programs; let j = index">
            <div class="pro-tab-con">
              <div class="pro-tab-list row">
                <div class="left col-md-4 col-xs-12">
                  <h2 [attr.aria-label]="'Program name is' + programData.name">
                    {{ programData.name }}
                  </h2>
                  <ul>
                    <li>
                      <img
                        [attr.aria-label]="
                          'Author is' + programData.program_author
                        "
                        src="../../assets/img/icn-author.svg"
                        height="18"
                      />
                      <span>{{ programData.program_author }}</span>
                    </li>

                    <li>
                      <img
                        [attr.aria-label]="
                          'Total number of lessons is' +
                          programData.total_lessons
                        "
                        src="../../assets/img/icn-lesson.svg"
                        height="18"
                      />
                      <span>{{ programData.total_lessons }} Lessons</span>
                    </li>

                    <li>
                      <img
                        [attr.aria-label]="
                          'Duration of the program is ' +
                          programData.program_duration
                        "
                        src="../../assets/img/icn-time.svg"
                        height="18"
                      /><span>{{ programData.program_duration }}</span>
                    </li>

                    <!-- <li>                    <div class="rating" [popover]="template" placement="bottom" triggers="focus">                      <rating [(ngModel)]="programData.program_rating" [max]="max" [readonly]="isReadonly"                        [titles]="['one','two','three']"></rating>                      ({{programData.program_rate_by}} {{programData.program_rate_by>1?'Ratings':'Rating'}})                      <ng-template #template>                        <div class="tooltip-con">                          <h2>Overall Rating</h2>                          <div class="text-center">                            <rating [(ngModel)]="programData.program_rating" [max]="max" [readonly]="isReadonly"                              [titles]="['one','two','three']"></rating>                            ({{programData.program_rate_by}} {{programData.program_rate_by>1?'Ratings':'Rating'}})                          </div>                        </div>                        <ul>                          <li *ngFor="let lesson of programData.lessons; let k = index;">                            Lesson {{k+1}}                            <rating [(ngModel)]="lesson.lesson_status.lesson_rating" [max]="max" [readonly]="isReadonly"                              [titles]="['one','two','three']"></rating>                            ({{lesson.lesson_status.lesson_rate_by}}                            {{lesson.lesson_status.lesson_rate_by>1?'Ratings':'Rating'}})                          </li>                        </ul>                      </ng-template>                    </div>                  </li> -->
                  </ul>
                </div>

                <div class="right col-md-8 col-xs-12">
                  <h3 *ngIf="!programData.program_payment">
                    ${{ programData.cost }}
                  </h3>
                  <button
                    (focus)="loadAudio(0)"
                    (focusout)="loadAudio(99)"
                    (mouseenter)="loadAudio(0)"
                    (mouseleave)="loadAudio(99)"
                    class="button button--aylen btn btn-primary"
                    (click)="ViewDetails(i, j)"
                  >
                    View Details
                  </button>
                  <button
                    (focus)="loadAudio(1, programData)"
                    (focusout)="loadAudio(99)"
                    (mouseenter)="loadAudio(1, programData)"
                    (mouseleave)="loadAudio(99)"
                    class="button button--aylen btn btn-primary"
                    (click)="programActions(programData, j, 0)"
                  >
                    {{
                      programData.program_payment
                        ? programData.progress == 0
                          ? start
                          : continue
                        : enroll
                    }}
                  </button>

                  <!-- <button *ngIf="!programData.prog_purchase_status; else PurchasedBlock" class="btn btn-primary" (click)="enrollPayment();">Enroll</button> -->

                  <!-- <ng-template #PurchasedBlock>                    <button *ngIf="programData.prog_progress== '0';else courseContinue" class="btn btn-primary"></button>                    <ng-template #courseContinue>                      <button class="btn btn-primary">Continue</button>                    </ng-template>                  </ng-template> -->
                </div>
                <div style="clear: both"></div>
                <div
                  class="short-description"
                  [innerHTML]="programData.short_description"
                ></div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <!-- <a href="javascript:void(0)" class="hotspot-circle circle-admission" (focus)="loadCatalogBtnAudio()" style="display: none;"    *ngIf="!viewprograms" (mouseenter)="loadCatalogBtnAudio()" (click)="viewprogram();"    [tabindex]="showPanel && !modalOpen? -1:0" [attr.aria-label]="schoolName+ 'Training Catalog'" tooltip="Training Catalog"    placement="bottom">    <div class="hotspot-circle-inner"></div>    <div class="hotspot-circle-middle">Test</div>    <div class="hotspot-circle-outer">      <div class="pro-header">        <h2>WELCOME TO ADMISSIONS TRAINING CATALOG</h2>        <p>Below you will find all of the training content offered by Helix Opportunity Institute. Please, select any of          these schools that interests you, to enroll in programs and lessons of your choice. </p>      </div>    </div>  </a> -->
</div>
<div
  class="program-content"
  ngClass="{{ receptionClass }}"
  style=""
  *ngIf="isProgramContainer"
>
  <div class="top-image"></div>

  <div class="right-image-1"></div>

  <div class="left-image-1"></div>

  <div class="bottom-image-1"></div>
</div>
<div class="chat-container">
  <app-help-guide
    [audioUrl]="adoUrl"
    [textUrl]="txtUrl"
    [newClassDialogue]="
      schoolId != 3
        ? 'chat-container-con'
        : 'chat-container-con chat-con-business'
    "
    [newClass]="
      schoolId != 3 ? 'chat-icon-home' : 'chat-icon-home businessChar'
    "
    [animatedCharecter]="character"
  >
  </app-help-guide>
</div>
<audio
  id="AdminAudio"
  [src]="adminAudioUrl"
  autoPlay="true"
  controls
  class="hide"
></audio>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }">
</ngx-loading
>"
