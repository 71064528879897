/***
 ** Node Service
 * Description: Common sode servive to do add node, whcih we can subscribe from other page.
 * Created Date: 05/11/2018
 * Created by: Avaya
 * Revision History:
 * Modification date:
 * Description:
 ***/
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 *
 */
@Injectable()
export class NodeService {
  public node: Subject<Node> = new Subject<any>();
  public nodeObservable = this.node.asObservable();
  /**
   *
   * @param data
   */
  public addNode(data: any) {
    this.node.next(data);
  }
  /**
   *
   */
  constructor() {}
}
