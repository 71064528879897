/**
 *
 * Common error handler
 * Description: Common error Handler, which will hold API error passed through HTTPInterceptor
 * Created Date: 29/11/2018
 * Created by: Avaya
 * Revision History:
 * Modification date: 29/05/2018
 * Description:
 *
 */
import { ErrormodalComponent } from './../component/errormodal/errormodal.component';
import { Injectable } from '@angular/core';
import { StorageService } from '../../shared/services/storage.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

/**
 *
 */
@Injectable()
export class ErrorHandler {
  /**
   * Error modal config object
   */
  public errormodalconfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'childmodal successmodal',
  };
  /**
   *
   * @param storageService
   * @param bsModalService
   * @param bsModalRef
   * @param router
   */
  constructor(
    public storageService: StorageService,
    private bsModalService: BsModalService,
    private bsModalRef: BsModalRef,
    private router: Router,
  ) {}
  /**
   *
   * @param err
   * error handler function
   */
  public handleError(err: any) {
    /**
     * With error code setting the error message and passing the value to modal
     */
    if (!navigator.onLine) {
      const initialState = {
        error_message: 'Please check you internet connection',
      };
      this.bsModalRef = this.bsModalService.show(
        ErrormodalComponent,
        Object.assign({ initialState }, this.errormodalconfig),
      );
    } else {
      if (err.status == 404) {
        const initialState = {
          error_message: 'URL not found. Please try after sometime',
        };
        this.bsModalRef = this.bsModalService.show(
          ErrormodalComponent,
          Object.assign({ initialState }, this.errormodalconfig),
        );
      } else if (err.status === 0) {
        const initialState = {
          error_message: 'Serverside error. Please try after sometime',
        };
        this.bsModalRef = this.bsModalService.show(
          ErrormodalComponent,
          Object.assign({ initialState }, this.errormodalconfig),
        );
      } else if (err.status === 400) {
        const initialState = {
          error_message: 'Bad request passed. Please try after sometime',
        };
        this.bsModalRef = this.bsModalService.show(
          ErrormodalComponent,
          Object.assign({ initialState }, this.errormodalconfig),
        );
      } else if (err.status === 401) {
        const initialState = {
          error_message:
            'Yor are in authorized to access thisi page. Please login with valid credentials',
        };
        this.bsModalRef = this.bsModalService.show(
          ErrormodalComponent,
          Object.assign({ initialState }, this.errormodalconfig),
        );
        this.router.navigate(['']);
      } else if (err.status === 500) {
        const initialState = {
          error_message: 'Serverside error. Plesae try after sometime',
        };
        this.bsModalRef = this.bsModalService.show(
          ErrormodalComponent,
          Object.assign({ initialState }, this.errormodalconfig),
        );
      }
    }
  }
}
