import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebApiService } from '../../shared/services/web-api.service';
import { CommonService } from '../../shared/services/common.service';
import { environment } from '../../../environments/environment';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { NodeService } from '../../shared/services/node.service';
import { StorageService } from '../../shared/services/storage.service';

/**
 *
 */
@Component({
  selector: 'app-googleroute',
  templateUrl: './googleroute.component.html',
  styleUrls: ['./googleroute.component.css'],
})
export class GooglerouteComponent implements OnInit {
  public loading = false;
  private apiDomain: string = environment.apiUrl;
  /**
   *
   * @param route
   * @param api
   * @param commonService
   * @param storageService
   * @param router
   * @param nodeService
   */
  constructor(
    private route: ActivatedRoute,
    private api: WebApiService,
    private commonService: CommonService,
    private storageService: StorageService,
    private router: Router,
    private nodeService: NodeService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    /**api call to fetch user details from google */
    this.route.queryParams.subscribe(
      (params) => {
        const providerData = {
          endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
          data: {
            code: params.code,
            callback_url: API_ENDPOINTS.GOOGLE_CALLBACKURL,
            wstoken: this.commonService.getWsToken(),
            wsfunction: API_FUNCTIONS.GOOGLE_LOGIN,
            moodlewsrestformat: 'json',
          },
          contentType: 'application/x-www-form-urlencoded',
        };
        this.loading = true;
        this.api.postApiCall(providerData).subscribe(
          (response: any) => {
            const viewProfileReqData = {
              endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
              data: {
                user_token: response.userinfo.token,
              },
              params: {
                token: response.userinfo.token,
                function: API_FUNCTIONS.VIEW_PROFILE,
                format: 'json',
              },
            };
            this.api.postApiCall(viewProfileReqData).subscribe(
              (profileResponse: any) => {
                this.loading = false;
                const userDetails = {
                  first_name: profileResponse.firstname,
                  last_name: profileResponse.lastname,
                  email: profileResponse.email,
                  profile_image: profileResponse.profile_img,
                  user_id: profileResponse.id,
                };
                this.commonService.setLoginToken(response.userinfo.token);
                this.storageService.addToSessionStorage(
                  'loginToken',
                  response.userinfo.token,
                );
                this.storageService.addToSessionStorage(
                  'userInfo',
                  userDetails,
                );
                this.nodeService.addNode({ isLogin: true });
                this.nodeService.addNode({
                  profileImg: profileResponse.profile_img,
                });

                this.router.navigateByUrl('');
              },
              () => {
                this.loading = false;
              },
            );
          },
          () => {
            this.loading = false;
          },
        );
      },
      () => {
        this.loading = false;
      },
    );
  }
}
