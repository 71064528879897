/********************************************************************
 * Created ON:27/11/2018
 * Created by: Pallavi
 * Description: Invoice display file.
 * Bill for programs or lessons that user has enrolled is displayed here
 * Revision History:
 * Modification date:
 * Description: T
 **********************************************************************/

import { Component, OnInit } from '@angular/core';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { WebApiService } from '../../shared/services/web-api.service';
import { StorageService } from '../../shared/services/storage.service';

import { environment } from '../../../environments/environment';
import { NodeService } from '../../shared/services/node.service';
import { MuteService } from '../../shared/services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-view-bill',
  templateUrl: './view-bill.component.html',
  styleUrls: ['./view-bill.component.css'],
})
export class ViewBillComponent implements OnInit {
  public billData = {
    lessons: [],
    programs: [],
  };
  public loginToken: any;
  public downloadUrl = environment.apiUrl + API_ENDPOINTS.COMMON_ENDPOINT;
  public wsfunction = API_FUNCTIONS.DOWNLOAD_BILL;
  public noPurchasemsg = '';
  public filter = 'prog';
  public billFlag = {
    prog: false,
    lessons: false,
  };
  public allAudioTags: any;
  public modalopen = false;

  /**
   *
   * @param api
   * @param storageService
   * @param nodeService
   * @param muteService
   */
  constructor(
    private api: WebApiService,
    private storageService: StorageService,
    private nodeService: NodeService,
    private muteService: MuteService,
  ) {
    /**
     * API call to get bill details
     */
    this.loginToken = this.storageService.getFromSessioStorage('loginToken');
    const requestDataViewBill = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: { token: this.loginToken },
      params: {
        token: this.loginToken,
        function: API_FUNCTIONS.VIEW_BILL,
        format: 'json',
      },
    };
    // api call to get billing details
    this.api.postApiCall(requestDataViewBill).subscribe(
      (response: any) => {
        this.billData = response;
        this.noPurchasemsg = 'No purchases has been made';
      },
      () => {
        // Error handling
      },
    );
  }

  /**
   *
   */
  ngOnInit() {
    this.filter = 'prog';
    this.loadAudio(0);
    this.muteService.muteFun();
    this.nodeService.nodeObservable.subscribe((node) => {
      if (node['modalopen'] !== undefined) {
        this.modalopen = node['modalopen'];
      }
    });
  }
  /**
   *
   * @param filter
   */
  changeFilter(filter) {
    this.filter = filter;
  }
  /**
   *
   * @param eleNo
   */
  public loadAudio(eleNo) {
    const profileAudio = <HTMLAudioElement>(
      document.getElementById('myProfileAudio')
    );
    switch (eleNo) {
      case 0:
        profileAudio.src =
          '../../assets/audio/myprofile/billing/Billing_Welcome_Message.mp3';
        break;
      case 1:
        profileAudio.src =
          '../../assets/audio/myprofile/billing/Purchased_Programs_Button.mp3';
        break;
      case 2:
        profileAudio.src =
          '../../assets/audio/myprofile/billing/Purchased_Lessons_Button.mp3';
        break;
      case 3:
        profileAudio.src =
          '../../assets/audio/myprofile/billing/Download_Program_Invoice_Button.mp3';
        break;
      case 4:
        profileAudio.src =
          '../../assets/audio/myprofile/billing/Download_Lesson_Invoice_Button.mp3';
        break;
      default:
        profileAudio.src = '';
    }
  }
}
