/********************************************************************
 * Created ON:20/11/2018
 * Created by: Pallavi
 * Description: User profile updation file.
 * User can update profile picture and personal information here.
 * Revision History:
 * Modification date: 29/12/2018
 * Description: Api call for changed of profile picture
 **********************************************************************/

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { WebApiService } from '../../shared/services/web-api.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { SuccessmodalComponent } from '../../shared/component/successmodal/successmodal.component';
import { StorageService } from '../../shared/services/storage.service';
import { NodeService } from '../../shared/services/node.service';
import { ErrormodalComponent } from '../../shared/component/errormodal/errormodal.component';
import { MODAL_CONFIG } from '../../shared/constant/modalConfig.constant';
import { MuteService } from '../../shared/services/mute.service';
import { CommonService } from '../../shared/services/common.service';

/**
 *
 */
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
})
export class EditProfileComponent implements OnInit {
  public infoProfile: any; //incoming data from parent component
  profile_pic = '';
  public loading = false; // flag to start and stop loader
  public userDetails: any;
  public allAudioTags: any;
  // edit Form declaration and validation
  public editProfileForm = new UntypedFormGroup({
    imageFormData: new UntypedFormControl(),
    firstName: new UntypedFormControl('', [
      Validators.pattern(this.commonservice.nameregex),
      Validators.required,
      Validators.maxLength(100),
    ]),
    lastName: new UntypedFormControl('', [
      Validators.pattern(this.commonservice.nameregex),
      Validators.maxLength(100),
    ]),
    emailId: new UntypedFormControl(),
  });

  /**
   *
   * @param api
   * @param bsModalRef
   * @param nodeService
   * @param storageService
   * @param modalService
   * @param muteService
   * @param commonservice
   */
  constructor(
    private api: WebApiService,
    private bsModalRef: BsModalRef,
    private nodeService: NodeService,
    private storageService: StorageService,
    private modalService: BsModalService,
    private muteService: MuteService,
    private commonservice: CommonService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.nodeService.addNode({ modalopen: true });
    this.userDetails = this.storageService.getFromSessioStorage('userInfo');
    this.profile_pic = this.userDetails.profile_image;
    this.editProfileForm.controls.lastName.setValue(this.userDetails.last_name);
    this.editProfileForm.controls.firstName.setValue(
      this.userDetails.first_name,
    );
    this.editProfileForm.controls.emailId.setValue(this.userDetails.email);
    this.muteService.muteFun();
  }

  // method toselect and upload new profile image
  /**
   *
   * @param files
   */
  public fileChange(files: FileList) {
    this.loading = true;
    const fileList: FileList = files;
    if (fileList.length > 0) {
      const loginToken = this.storageService.getFromSessioStorage('loginToken');
      const file: File = fileList[0];
      const data = {
        file: file,
        token: loginToken,
      };
      this.api.postImage(data).subscribe(
        (response: any) => {
          /**TODO
           * refresh page on save.
           */
          const requestData = {
            endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
            data: {
              token: loginToken,
              draft_id: response[0].itemid,
            },
            params: {
              token: loginToken,
              function: API_FUNCTIONS.GET_PROFILE_PIC,
              format: 'json',
            },
          };
          this.api.postApiCall(requestData).subscribe(
            (picResponse: any) => {
              this.profile_pic = picResponse.new_img;
              // this.userDetails = this.storageService.getFromSessioStorage('userInfo');
              this.userDetails.profile_image = picResponse.new_img;
              this.storageService.addToSessionStorage(
                'userInfo',
                this.userDetails,
              );
              this.nodeService.addNode({ profileImg: picResponse.new_img });
              this.loading = false;
            },
            () => {
              // Error handling
              this.loading = false;
            },
          );
        },
        () => {
          this.loading = false;
          // Error handling
        },
      );
    }
  }

  /**
   * function to make api call to update new profile
   *  details and display success modal if updated successfully
   */
  public successprofileModal() {
    this.loading = true;
    const token = this.storageService.getFromSessioStorage('loginToken');
    const firstname = this.editProfileForm.controls.firstName.value;
    const lastname = this.editProfileForm.controls.lastName.value;
    const requestData = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        user_token: token,
        firstname: firstname,
        lastname: lastname,
      },
      params: {
        token: token,
        function: API_FUNCTIONS.UPLOAD_PROFILE,
        format: 'json',
      },
    };
    this.api.postApiCall(requestData).subscribe(
      (response: any) => {
        if (response.status) {
          this.loading = false;
          this.userDetails.first_name = firstname;
          this.userDetails.last_name = lastname;
          this.infoProfile.profileLastName = lastname;
          this.infoProfile.profileFirstName = firstname;
          this.storageService.addToSessionStorage('userInfo', this.userDetails);
          const initialState = {
            success_message:
              'You have successfully saved the changes to your profile.',
            from_module: 'edit_profile',
          };
          this.bsModalRef.hide();
          this.bsModalRef = this.modalService.show(
            SuccessmodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.successmodalconfig),
          );
        } else {
          this.loading = false;
          this.bsModalRef.hide();
          const initialState = { error_message: response.message };
          this.bsModalRef = this.modalService.show(
            ErrormodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
          );
        }
      },
      () => {
        // Error handling
        this.loading = false;
      },
    );
  }
  /**
   *
   * @param eleNo
   */
  public loadAudio(eleNo) {
    const profileAudio = <HTMLAudioElement>(
      document.getElementById('myProfileAudio')
    );
    /**
     * ToDo
     * first name
     * last name audio has to be changed
     */
    switch (eleNo) {
      case 0:
        profileAudio.src = '../../assets/audio/shared/Close_Button.mp3';
        break;
      case 1:
        profileAudio.src =
          '../../assets/audio/myprofile/editprofile/Change_Profile_Picture.mp3';
        break;
      case 2:
        profileAudio.src =
          '../../assets/audio/myprofile/editprofile/Update_First_Name_Field.mp3';
        break;
      case 3:
        profileAudio.src =
          '../../assets/audio/myprofile/editprofile/Update_Last_Name_Field.mp3';
        break;
      case 4:
        profileAudio.src = '../../assets/audio/shared/Save_Button.mp3';
        break;
      default:
        profileAudio.src = '';
    }
  }
  /**
   *
   */
  public closeModal() {
    this.bsModalRef.hide();
    this.nodeService.addNode({ modalopen: false });
  }
}
