import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MODAL_CONFIG } from '../../shared/constant/modalConfig.constant';
import { WebApiService } from '../../shared/services/web-api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../shared/services/common.service';
import { StorageService } from '../../shared/services/storage.service';
import { SignupComponent } from '../../auth/signinsignup/signup.component';
import { NodeService } from '../../shared/services/node.service';
import { MuteService } from '../../shared/services/mute.service';
import { environment } from '../../../environments/environment';

/**
 *
 */
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  public closeEvent: EventEmitter<any> = new EventEmitter();

  /**Incoming data from admin component */
  public paypalPaymentUrl;
  public course_id: string;
  public signinModalState = false;
  public desc: any;
  public cost: any;
  public prog_name: any;
  public message: any;
  public cid: any;
  public purchase_type: any;
  public purchase_status: false;
  public paypalDetails: any;
  public state: any;
  public userInfo: any;
  public custom_value: any;
  public cardType = '';
  public trimmedValue = '';
  public patten = new RegExp(/^[0-9\s]*$/);
  public expiryDate = '';
  public paymentOpen: any;
  public allAudioTags: any;
  public from: any; //incoming data that specifies from which modal it is getting opened

  /**
   *
   * @param bsModalRef
   * @param commonService
   * @param storageService
   * @param modalService
   * @param nodeService
   * @param modalRef
   * @param api
   * @param muteservice
   */
  constructor(
    public bsModalRef: BsModalRef,
    private commonService: CommonService,
    private storageService: StorageService,
    private modalService: BsModalService,
    private nodeService: NodeService,
    private modalRef: BsModalRef,
    private api: WebApiService,
    private muteservice: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.paypalPaymentUrl = environment.paypalUrl;
    this.paymentOpen = true;
    document.body.classList.add('payment-open');
    /**Checks if mute is activated and mutes or unmute the audio accordingly*/
    this.muteservice.muteFun();

    this.paypalDetails = this.commonService.getPaypalDetails();
    this.userInfo = this.storageService.getFromSessioStorage('userInfo');
    this.nodeService.nodeObservable.subscribe((node) => {
      if (node['progPurchaseState'] !== undefined)
        this.purchase_status = node['progPurchaseState'];
      if (node['returnPressed'] && this.paymentOpen) {
        this.closeModal();
      }
      if (node['returnHome']) {
        this.closeModal();
      }
    });
    if (this.userInfo) {
      this.custom_value =
        this.purchase_type == 'program'
          ? this.userInfo.user_id + '-' + this.course_id + '-' + this.cid
          : this.userInfo.user_id + '-' + this.cid;
    }
  }

  /**
   *
   */
  ngOnDestroy() {
    document.body.classList.remove('payment-open');
    this.from == 'catalog'
      ? this.nodeService.addNode({ modalopen: false })
      : this.closeEvent.emit({ paymentModalState: false });
    this.paymentOpen = false;
  }

  /**Sigin or register functionality in case user has not logged in and wishes to purchase a program/lesson */
  /**
   *
   */
  public signinModal() {
    this.modalRef = this.modalService.show(
      SignupComponent,
      MODAL_CONFIG.signinconfig,
    );
    this.modalRef.content.onSigninModalClose = () => {
      const isLogin = true;
      /******Adding to node after successful login *******/
      this.nodeService.addNode({ isLogin: isLogin });
      this.modalService.hide(this.modalService.getModalsCount());
      this.userInfo = this.storageService.getFromSessioStorage('userInfo');
      if (this.userInfo) {
        this.custom_value =
          this.userInfo.user_id +
          '-' +
          this.paypalDetails.course_id +
          '-' +
          this.cid;
      }
    };
  }
  /**Function to close the current modal */
  /**
   *
   */
  public closeModal() {
    this.bsModalRef.hide();
    this.closeEvent.emit({ paymentModalState: false });
  }

  /**Loading audios for each element */
  /**
   *
   * @param eleNo
   */
  public loadAudio(eleNo) {
    const adminAudio = <HTMLAudioElement>document.getElementById('AdminAudio');
    switch (eleNo) {
      case 0:
        adminAudio.src = '../../assets/audio/shared/Close_Button.mp3';
        break;
      case 1:
        adminAudio.src = '../../assets/audio/adminbuilding/Pay_Button.mp3';
        break;
      case 2:
        adminAudio.src = '../../assets/audio/homepage/Register_signin.mp3';
        break;
      default:
    }
  }
}
