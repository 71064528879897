/********************************************************************
 * Created ON:27/11/2018
 * Created by: Avaya
 * Description: Reset Password feature.
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from './../../shared/constant/apiEndpoint.constant';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { WebApiService } from '../../shared/services/web-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../shared/services/common.service';
import { ErrormodalComponent } from '../../shared/component/errormodal/errormodal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SuccessmodalComponent } from '../../shared/component/successmodal/successmodal.component';
import { MODAL_CONFIG } from '../../shared/constant/modalConfig.constant';
import { StorageService } from '../../shared/services/storage.service';
import { MuteService } from '../../shared/services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
})
export class ResetpasswordComponent implements OnInit {
  public resetToken: any;
  public showPwdNew = false;
  public showPwdConfirm = false;
  public resetAudio = '';
  public allAudioTags: any;
  /**
   * Regular expression for password
   */
  // public strongRegex = this.commonService.regExpForPassword();
  public loading = false;

  /**
   * Matching password and confirm password.
   * If matching returning null oterwise returning mismatch variable as true.
   *
   * @param g
   */
  public passwordMatchValidator = (g: UntypedFormGroup) => {
    return g.get('newPassword').value === g.get('confirmPassword').value
      ? null
      : { mismatch: true };
  };

  public resetPasswordForm = new UntypedFormGroup(
    {
      newPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.commonService.strongRegex),
      ]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    },
    this.passwordMatchValidator,
  );

  /**
   *
   * @param api
   * @param route
   * @param bsmodalref
   * @param bsModalService
   * @param router
   * @param commonService
   * @param storageService
   * @param muteService
   */
  constructor(
    private api: WebApiService,
    private route: ActivatedRoute,
    private bsmodalref: BsModalRef,
    private bsModalService: BsModalService,
    private router: Router,
    private commonService: CommonService,
    private storageService: StorageService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.resetToken = params.token;
    });
    this.muteService.muteFun();
  }

  /**
   * Change password API call
   */
  public changePassword = () => {
    this.loading = true;
    const requestData = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        password_reset_token: this.resetToken,
        password: this.resetPasswordForm.controls.newPassword.value,
        confirm_password: this.resetPasswordForm.controls.confirmPassword.value,
      },
      params: {
        token: this.commonService.getWsToken(),
        function: API_FUNCTIONS.RESET_PASSWORD,
        format: 'json',
      },
    };
    this.api.postApiCall(requestData).subscribe(
      (response: any) => {
        this.loading = false;
        if (response.status == 'failed') {
          const initialState = { error_message: response.error_description };
          this.router.navigateByUrl('');
          this.bsmodalref = this.bsModalService.show(
            ErrormodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
          );
        } else {
          const initialState = {
            success_message: 'You have successfully changed your password.',
            from_module: 'reset_password',
          };
          this.router.navigateByUrl('');
          this.bsmodalref = this.bsModalService.show(
            SuccessmodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.successmodalconfig),
          );
        }
      },
      () => {},
    );
  };

  /**
   *
   * @param pwdType
   */
  public showPassword(pwdType) {
    if (pwdType == 'newpwd') this.showPwdNew = !this.showPwdNew;
    else this.showPwdConfirm = !this.showPwdConfirm;
  }

  /**
   *
   * @param elementNo
   */
  public resetPwdAudio(elementNo) {
    switch (elementNo) {
      case 0:
        this.resetAudio = '../../assets/audio/shared/New_Password_Field.mp3';
        break;
      case 1:
        this.resetAudio =
          '../../assets/audio/shared/Password_Confirmation_Field.mp3';
        break;
      default:
        this.resetAudio = '';
    }
  }
}
