/********************************************************************
 * Created ON:12/11/2018
 * Created by: Avaya
 * Description: API endpoints constant file
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/

export const API_ENDPOINTS = {
  CLASSROOM: '5c00c4b03200001000b2885e', //'5beacd9f2f00004523da3cab',
  LOGIN: 'login/token.php',
  VIEW_PROFILE: 'webservice/rest/server.php?',
  MY_COURSE: '5c0f74dd310000b00924ec41',
  MY_CERTIFICATE: '5bead1562f00004c24da3cd2',
  VIEW_BILL: '5bfbdbcb3100006a0039bafa',
  SUCCESS_MESSAGE: '5bee56f72e00006300eee7b0',
  UPLOAD_PROFILE: 'webservice/upload.php',
  UPLOAD_PROFILE_IMAGE: 'webservice/upload.php',
  GET_PROFILE_PIC: 'webservice/rest/server.php?',
  MODULE_DETAILS: '5c08ca832f00002a00637a2c',
  COMMON_ENDPOINT: 'webservice/rest/server.php?',
  GOOGLE_CALLBACKURL: 'https://helixopp.institute/auth/google',
  LINKEDIN_CALLBACKURL: 'https://helixopp.institute/auth/linkedin',
  FACEBOOK_CALLBACKURL: 'https://helixopp.institute/auth/facebook',
};

export const API_FUNCTIONS = {
  REGISTER: 'auth_email_signup_user',
  CONFIG: 'local_customapi_get_site_info',
  CONTACT_US: 'local_customapi_contact_info',
  GET_PROFILE_PIC: 'local_customapi_update_profile_picture',
  VIEW_PROFILE: 'local_customapi_get_user_info',
  FORGOT_PASSWORD: 'local_customapi_forgot_password',
  UPLOAD_PROFILE: 'local_customapi_update_user_info',
  MY_CERTIFICATES: 'local_customapi_user_course_certificate',
  MY_BADGES: 'local_customapi_get_user_badges',
  MY_COURSE: 'local_customapi_get_my_programs',
  RESET_PASSWORD: 'local_customapi_reset_password',
  CHANGE_PASSWORD: 'local_customapi_change_password',
  IDENTITY_PROVIDER: 'local_customapi_generate_loginurl',
  FACEBOOK_LOGIN: 'local_customapi_login_facebook',
  GOOGLE_LOGIN: 'local_customapi_login_google',
  LINKEDIN_LOGIN: 'local_customapi_login_linkedin',
  VIEW_BILL: 'local_customapi_user_bill_payment',
  ADD_USER_RATING: 'local_customapi_add_user_rating',
  ADD_LESSON_RATING: 'local_customapi_add_user_lesson_rating',
  PROG_CERTIFICATE: 'local_customapi_course_certificate',
  GET_MODULE: 'local_customapi_get_module_content',
  CLASSROOM: 'local_get_classroom_content',
  FREE_LESSONS: 'local_customapi_enrol_free_lesson',
  FREE_PROGRAM: 'local_customapi_enrol_free_course',
  DOWNLOAD_BILL: 'local_customapi_generate_bill_pdf',
  MODULE_SAVESTATE: 'local_customapi_update_module_progress_state',
  MODULE_COMPLETION: 'local_customapi_user_complete_activity',
  DOWNLOAD_CERTIFICATE: 'local_customapi_download_certificate',
  FREE_MODULE: 'local_customapi_free_or_intro_module',
};
