<!-- change password -->
<div class="modal-body">
  <a
    (focus)="loadAudio(0)"
    (focusout)="loadAudio(99)"
    (mouseenter)="loadAudio(0)"
    (mouseleave)="loadAudio(99)"
    href="javascript:void(0)"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="changepasswordhideModal()"
    tooltip="Close"
  >
    <span aria-hidden="true" class="btn-close">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <h2>Change Password</h2>
  <form
    class="change-password"
    [formGroup]="changePasswordForm"
    (ngSubmit)="successprofileModal()"
  >
    <span *ngIf="wrongPassword">{{ error_msg }}</span>
    <div class="form-group">
      <label for="exampleInputEmail1">Current Password</label>
      <div class="input-group pwd-input">
        <input
          (focus)="loadAudio(1)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(1)"
          (mouseleave)="loadAudio(99)"
          [type]="showPwdCurrent ? 'text' : 'password'"
          class="form-control"
          id="CurrentPassword"
          placeholder="Enter current password"
          formControlName="currentPassword"
        />
        <span
          class="input-group-addon"
          [ngClass]="{ active: showPwdCurrent == true }"
          style="width: 40px"
          (click)="showPassword('pwd')"
        >
          <img
            class="pull-right eye-icon"
            src="assets/img/eyeicon-hidden.svg"
            alt=""
          />
        </span>
      </div>
      <!-- <span *ngIf="changePasswordForm.controls.currentPassword.invalid && changePasswordForm.controls.currentPassword.touched">This
        field is required</span> -->
      <span *ngIf="changePasswordForm.controls.currentPassword.touched">
        <span
          *ngIf="
            changePasswordForm.controls.currentPassword.errors
              ? changePasswordForm.controls.currentPassword.errors.minlength
              : false;
            else invalidPassword
          "
        >
          Password must contain atleast 8 characters
        </span>
        <ng-template #invalidPassword>
          <span *ngIf="changePasswordForm.controls.currentPassword.invalid"
            >Password is required</span
          >
        </ng-template>
      </span>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">New Password</label>
      <div class="input-group pwd-input">
        <input
          (focus)="loadAudio(2)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(2)"
          (mouseleave)="loadAudio(99)"
          [type]="showPwdNew ? 'text' : 'password'"
          class="form-control"
          id="exampleInputEmail1"
          placeholder="Enter new password"
          formControlName="newPassword"
        />
        <span
          class="input-group-addon"
          [ngClass]="{ active: showPwdNew == true }"
          style="width: 40px"
          (click)="showPassword('newpwd')"
        >
          <img
            class="pull-right eye-icon"
            src="assets/img/eyeicon-hidden.svg"
            alt=""
          />
        </span>
      </div>

      <span
        *ngIf="
          changePasswordForm.controls.newPassword.errors
            ? changePasswordForm.controls.newPassword.errors.maxlength
            : false;
          else MinPassword
        "
      >
        Password length exceeded
      </span>
      <ng-template #MinPassword>
        <div *ngIf="changePasswordForm.controls.newPassword.touched">
          <span
            *ngIf="
              changePasswordForm.controls.newPassword.errors
                ? changePasswordForm.controls.newPassword.errors.minlength
                : false;
              else requiredPassword
            "
          >
            Password must contain atleast 8 characters
          </span>
          <ng-template #requiredPassword>
            <span
              *ngIf="
                changePasswordForm.controls.newPassword.errors
                  ? changePasswordForm.controls.newPassword.errors.required
                  : false;
                else InvalidPassword
              "
            >
              This field is required
            </span>
            <ng-template #InvalidPassword>
              <span *ngIf="changePasswordForm.controls.newPassword.invalid"
                >Invalid password! Password must contain:
                <div>
                  1.Atleast one numeric character and one special character
                </div>
                <div>2.One uppercase and lowercase character</div>
              </span>
            </ng-template>
          </ng-template>
        </div>
      </ng-template>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Confirm Password</label>
      <div class="input-group pwd-input">
        <input
          (focus)="loadAudio(3)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(3)"
          (mouseleave)="loadAudio(99)"
          [type]="showPwdConfirm ? 'text' : 'password'"
          [ngClass]="{
            'has-error':
              changePasswordForm.hasError('mismatch') == true &&
              changePasswordForm.controls.confirmPassword.touched
          }"
          class="form-control"
          id="exampleInputEmail1"
          placeholder="Confirm Password"
          formControlName="confirmPassword"
        />
        <span
          class="input-group-addon"
          [ngClass]="{ active: showPwdConfirm == true }"
          style="width: 40px"
          (click)="showPassword('confirmpwd')"
        >
          <img
            class="pull-right eye-icon"
            src="assets/img/eyeicon-hidden.svg"
            alt=""
          />
        </span>
      </div>
      <div
        *ngIf="
          changePasswordForm.controls.confirmPassword.touched &&
          (changePasswordForm.controls.confirmPassword.invalid ||
            changePasswordForm.hasError('mismatch'))
        "
      >
        <span
          *ngIf="
            changePasswordForm.controls.confirmPassword.touched &&
              changePasswordForm.controls.confirmPassword.invalid;
            else pwdMismatch
          "
        >
          This field is required
        </span>
        <ng-template #pwdMismatch>
          <span> Passwords do not match</span>
        </ng-template>
      </div>
    </div>

    <button
      (focus)="loadAudio(4)"
      (focusout)="loadAudio(99)"
      (mouseenter)="loadAudio(4)"
      (mouseleave)="loadAudio(99)"
      type="submit"
      class="btn btn-default button button--aylen"
      [disabled]="!changePasswordForm.valid"
    >
      Save
    </button>
  </form>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }">
  </ngx-loading>
</div>
<!-- /change password -->
