/********************************************************************
 * Created ON:29/11/2018
 * Created by: Avaya
 * Description: Shared module file. In this module we will have all common features,
 * which can be used all over the application.
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { NgModule } from '@angular/core';
import { WebApiService } from '../shared/services/web-api.service';
import { StorageService } from '../shared/services/storage.service';
import { NodeService } from '../shared/services/node.service';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { CommonModule } from '@angular/common';
import { HelpGuideComponent } from './component/help-guide/help-guide.component';
import { LoaderComponent } from './component/loader/loader.component';

import { NgxLoadingModule } from 'ngx-loading';
import { TermsOfUseComponent } from './component/terms-of-use/terms-of-use.component';
import { PolicyComponent } from './component/policy/policy.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SlaComponent } from './component/sla/sla.component';
/**
 *
 */
@NgModule({
  providers: [
    WebApiService,
    StorageService,
    NodeService,
    BsModalService,
    BsModalRef,
  ],
  declarations: [
    HelpGuideComponent,
    LoaderComponent,
    TermsOfUseComponent,
    PolicyComponent,
    ContactUsComponent,
    SlaComponent,
  ],
  imports: [
    NgxLoadingModule.forRoot({}),
    CommonModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  exports: [HelpGuideComponent],
})
export class SharedModule {}
