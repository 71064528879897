/********************************************************************
 * Created ON:07/11/2018
 * Created by: Avaya
 * Description: Auth module file will contains aal the file of authmodule
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { SignupComponent } from './signinsignup/signup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ReactiveFormsModule } from '@angular/forms';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { NgxLoadingModule } from 'ngx-loading';
import { GooglerouteComponent } from './googleroute/googleroute.component';
import { FacebookrouteComponent } from './facebookroute/facebookroute.component';
import { LinkedinrouteComponent } from './linkedinroute/linkedinroute.component';

/**
 *
 */
@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ReactiveFormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
  ],
  exports: [SignupComponent],
  declarations: [
    SignupComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    GooglerouteComponent,
    FacebookrouteComponent,
    LinkedinrouteComponent,
  ],
})
export class AuthModule {}
