<div class="modal-body policy">
  <a
    tooltip="close"
    placememt="left"
    href="javascript:void(0)"
    class="close pull-right"
    aria-label="Close"
    (click)="modalClose()"
  >
    <span aria-hidden="true" class="btn-close">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <h4 class="heading" style="text-align: center">SERVICE LEVEL AGREEMENT</h4>
  <h4 class="heading">Version 1.0 – September 23, 2020</h4>
  <div class="policy_p">
    <p>
      This Service Level Agreement (“SLA”) between Helix Opportunity, LLC
      (“Helix,” “us” or “we”) and users of the Helix Opportunity System
      (“Customer” or “you”) governs the use of the Helix System under the
      provisions of the applicable Terms of Service (the “Terms”). <br /><br />

      Unless otherwise provided herein, the SLA is subject to the provisions of
      the Terms. <br /><br />

      <span style="text-decoration: underline"
        >1. Helix Service Commitment.</span
      >
      Helix shall exercise commercially reasonable efforts to achieve uptime
      (“Performance”) better than 99.7% during any monthly billing cycle (the
      “Target”). The Performance will be calculated as follows: 100 * (Total
      minutes the System is available – Total unavailable minutes in a calendar
      month) / Total minutes in such calendar month.<br /><br />

      <span style="text-decoration: underline">2. Support Service. </span> Helix
      will maintain a help desk Monday through Friday from 8 am to 5 pm Pacific
      Standard Time (“PST”), excluding holidays, for receiving and responding
      email support requests (“Support Hours”). Support service may include VOIP
      support and research time performed by Helix support staff. Customer will
      report Errors by emailing the Support Service at dfazio@helixopp.com.
      Though often the Help Desk will be able to respond immediately, it shall
      make reasonable efforts to at least respond within 24 hours to requests
      submitted during the Support Hours. <br /><br />

      <span style="text-decoration: underline">3. Correction of Errors.</span>
      Helix will correct Errors as provided herein. “Error” shall mean a
      disruption to the operation of the System during the Term of the
      Agreement. <br />

      The definitions of the Error classifications are as follows: <br /><br />
      <span style="text-align: center">
        (a) “Level 1 Error” means a minor function of the System is not
        operational. <br /><br />

        (b) “Level 2 Error” means any Error that is not a Level 1 or Level 3
        Error. <br /><br />

        (c) “Level 3 Error” means the System is inoperative.
      </span>
      <br /><br />
      <span style="text-decoration: underline">4. Procedure.</span> <br /><br />

      <span style="text-decoration: underline">(a) Report of Errors.</span> With
      respect to a report of any Error, Customer personnel making such a report
      will describe to the Helix Help Desk staff the Error in reasonable detail
      and the circumstances under which the Error occurred or is occurring and
      will, with the assistance of the Helix Help Desk staff members, classify
      the Error as a Level 1, 2, or 3 Error.<br /><br />

      <span style="text-decoration: underline">(b) Resolving Errors.</span> In
      the event that an Error is not resolved by the Help Desk, Helix will
      escalate the handling of the Error to its support personnel until
      resolved. Helix support personnel will take reasonable measures to correct
      Errors in a timely fashion, taking into consideration the level of
      severity. <br /><br />

      <span style="text-decoration: underline"
        >(c) Helix’s Level of Effort.</span
      >
      Helix will work diligently until any Error for which a correction or
      workaround has not been achieved has been resolved. If necessary, Helix
      shall work with Customer to expediently develop a plan to resolve the
      Error as soon as possible, including travel to the site of the Error if
      the parties reasonably believe that such travel will increase the
      probability of expeditious resolution of the Error. <br /><br />

      <span style="text-decoration: underline">5. Sole Remedy.</span> In the
      event of three (3) or more Level 3 Errors during any calendar month,
      Customer may terminate the Agreement for material breach pursuant to the
      provisions of Section 11.2 of the Agreement (Termination for Cause),
      provided Customer notifies Helix in writing of termination within five (5)
      days of the end of such calendar month. Such remedy is the Customer’s sole
      remedy for any failure of the System.<br /><br />
    </p>
  </div>
</div>
