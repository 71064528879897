// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   // apiUrl: http://localhost/temp/Helix/ 'https://www.mocky.io/v2/', http://54.151.116.235/Helix_LMS_Moodle_Backend/moodle/
//   apiUrl: 'https://admin.helixopp.institute/',//'http://admin.helixopp.institute/',
//   fbKey: '2286899974678066',
//   liKey: '8196oxaxu0rp4p',
//   googleKey: '967373904936-933kt3t0lakhq0kn7eh902tkbv2okf5j.apps.googleusercontent.com'
// };
export const environment = {
  production: false,
  // apiUrl: http://localhost/temp/Helix/ 'https://www.mocky.io/v2/', http://54.151.116.235/Helix_LMS_Moodle_Backend/moodle/
  apiUrl: 'https://admin-sandbox.helixopp.institute/', //'http://localhost/temp/Helix/',//'http://admin.helixopp.institute/',
  fbKey: '545280596641808',
  liKey: '86uusvz4qjgzsl',
  googleKey:
    '417494315303-j4f9odeie1733rimlml60catjtt9j0cj.apps.googleusercontent.com',
  paypalUrl: 'https://www.paypal.com/cgi-bin/webscr',
};
