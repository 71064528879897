/********************************************************************
 * Created ON:30/11/2018
 * Created by: Avaya
 * Description: Common error popup component
 *
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NodeService } from '../../services/node.service';
import { StorageService } from '../../services/storage.service';
import { MuteService } from '../../services/mute.service';
import {Router} from "@angular/router";
/**
 *
 */
@Component({
  selector: 'app-errormodal',
  templateUrl: './errormodal.component.html',
  styleUrls: ['./errormodal.component.css'],
})
export class ErrormodalComponent implements OnInit {
  public error_message: any; // incoming error message that needs to be displayed
  public allAudioTags: any;
  /**
   *
   * @param bsModalRef
   * @param nodeService
   * @param storageService
   * @param muteService
   * @param router
   */
  constructor(
    public bsModalRef: BsModalRef,
    private nodeService: NodeService,
    private storageService: StorageService,
    private muteService: MuteService,
    private router: Router,
  ) {}

  /**
   *
   */
  ngOnInit() {}
  /**
   *
   */
  public errorhideModal() {
    this.bsModalRef.hide();
    this.nodeService.addNode({ modalopen: false });
    this.muteService.muteFun();
  }
  
  /**
   * Navigate to home page
   */
  public homePage() {
    this.errorhideModal();
    this.router.navigate(['']);
  }
}
