/*********************************************************************************
 *
 * Local Storage
 * Description: Set and remove data to and from Local storage and session storage
 * Created Date: 05/11/2018
 * Created by: Avaya
 * Revision History:
 * Modification date:
 * Description:
 ***********************************************************************************/

import { Injectable } from '@angular/core';

/**
 *
 */
@Injectable()
export class StorageService {
  /**
   * save data in LocalStorage
   *
   * @param key
   * @param data
   */
  public addToLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * Get saved data from LocalStorage
   *
   * @param key
   */
  public getFromLocalStorage(key) {
    // Parse the string back to object.
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    } else {
      return data;
    }
  }

  /**
   *Remove item from the local storage
   *
   * @param key
   */
  public removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  /**
   * save data in sessionStorage
   *
   * @param key
   * @param data
   */
  public addToSessionStorage(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * Get saved data from sessionStorage
   *
   * @param key
   */
  public getFromSessioStorage(key) {
    // Parse the string back to object.
    const data = sessionStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    } else {
      return data;
    }
  }

  /**
   * Remove item from the local storage
   *
   * @param key
   */
  public removeFromSessionStorage(key) {
    sessionStorage.removeItem(key);
  }
}
