<!-- success profile -->
<div class="modal-body">
  <a
    tooltip="close"
    placement="right"
    href="javascript:void(0)"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="errorhideModal()"
  >
    <span aria-hidden="true" class="btn-close">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <div class="row" id="error_message_details">
    <div class="col-xs-12">
      <img src="../../assets/img/ic-error.svg" width="45" alt="Error" />
      <div class="error_message" [innerHTML]="error_message"></div>
    </div>
    <div style="clear: both; margin-bottom: 20px"></div>
    <div>
      <a
         href="javascript:void(0)"
         style="position: relative;"
         class="menu-back-campus"
         (click)="homePage();">
        <div class="menu-back-campus-con">
          <img [attr.aria-hidden]="true" src="../../assets/img/menu/icn-home.svg" class="m-home-icon"/>
          <h6>back to campus</h6>
        </div>
        <img style="position: relative;" [attr.aria-hidden]="true" src="../../assets/img/menu/campus-shadow.png" class="m-home-shadow"/>
      </a>
    </div>
  </div>
</div>
<!-- success profile -->
