<div class="content">
  <!-- <img src="../../assets/img/logo.svg" class="logo" [hidden]="hotspot" /> -->
  <div class="home-img" style="opacity: 0.3"></div>
  <div class="centered">
    <div class="title">Reset Password</div>
    <form
      class="change-password"
      [formGroup]="resetPasswordForm"
      (ngSubmit)="changePassword()"
    >
      <div class="form-group">
        <label [attr.aria-hidden]="true" for="exampleInputEmail"
          >New Password</label
        >
        <div class="input-group pwd-input">
          <input
            (focus)="resetPwdAudio(0)"
            (focusout)="resetPwdAudio(99)"
            (mouseleave)="resetPwdAudio(99)"
            (mouseenter)="resetPwdAudio(0)"
            [type]="showPwdNew ? 'text' : 'password'"
            class="form-control"
            id="exampleInputEmail"
            placeholder="Enter new password"
            formControlName="newPassword"
          />
          <span
            class="input-group-addon"
            [ngClass]="{ active: showPwdNew == true }"
            style="width: 40px"
            (click)="showPassword('newpwd')"
          >
            <img
              class="pull-right eye-icon"
              src="assets/img/eyeicon-hidden.svg"
              alt=""
            />
          </span>
        </div>

        <span
          *ngIf="
            resetPasswordForm.controls.newPassword.errors
              ? resetPasswordForm.controls.newPassword.errors.maxlength
              : false;
            else MinPassword
          "
        >
          Password length exceeded
        </span>
        <ng-template #MinPassword>
          <div *ngIf="resetPasswordForm.controls.newPassword.touched">
            <span
              *ngIf="
                resetPasswordForm.controls.newPassword.errors
                  ? resetPasswordForm.controls.newPassword.errors.minlength
                  : false;
                else requiredPassword
              "
            >
              Password must contain atleast 8 characters
            </span>
            <ng-template #requiredPassword>
              <span
                *ngIf="
                  resetPasswordForm.controls.newPassword.errors
                    ? resetPasswordForm.controls.newPassword.errors.required
                    : false;
                  else InvalidPassword
                "
              >
                This field is required
              </span>
              <ng-template #InvalidPassword>
                <span *ngIf="resetPasswordForm.controls.newPassword.invalid"
                  >Invalid password! Password must contain:
                  <div>
                    1.Atleast one numeric character and one special character
                  </div>
                  <div>2.One uppercase and lowercase character</div>
                </span>
              </ng-template>
            </ng-template>
          </div>
        </ng-template>
      </div>
      <div class="form-group">
        <label [attr.aria-hidden]="true" for="exampleInputEmail1"
          >Confirm Password</label
        >
        <div class="input-group pwd-input">
          <input
            [type]="showPwdConfirm ? 'text' : 'password'"
            (focus)="resetPwdAudio(1)"
            (focusout)="resetPwdAudio(99)"
            (mouseleave)="resetPwdAudio(99)"
            (mouseenter)="resetPwdAudio(1)"
            [ngClass]="{
              'has-error':
                resetPasswordForm.hasError('mismatch') == true &&
                resetPasswordForm.controls.confirmPassword.touched
            }"
            class="form-control"
            id="exampleInputEmail1"
            placeholder="Confirm Password"
            formControlName="confirmPassword"
          />
          <span
            class="input-group-addon"
            [ngClass]="{ active: showPwdConfirm == true }"
            style="width: 40px"
            (click)="showPassword('confirmpwd')"
          >
            <img
              class="pull-right eye-icon"
              src="assets/img/eyeicon-hidden.svg"
              alt=""
            />
          </span>
        </div>
        <div
          *ngIf="
            resetPasswordForm.controls.confirmPassword.touched &&
            (resetPasswordForm.controls.confirmPassword.invalid ||
              resetPasswordForm.hasError('mismatch'))
          "
        >
          <span
            *ngIf="
              resetPasswordForm.controls.confirmPassword.touched &&
                resetPasswordForm.controls.confirmPassword.invalid;
              else pwdMismatch
            "
          >
            This field is required
          </span>
          <ng-template #pwdMismatch>
            <span> Passwords do not match</span>
          </ng-template>
        </div>
      </div>

      <button
        (focus)="resetPwdAudio(1)"
        (focusout)="resetPwdAudio(99)"
        (mouseleave)="resetPwdAudio(99)"
        (mouseenter)="resetPwdAudio(1)"
        type="submit"
        class="btn btn-default button button--aylen"
        [disabled]="!resetPasswordForm.valid"
      >
        Save
      </button>
    </form>
  </div>
</div>
<audio
  id="resetPwdAudio"
  [src]="resetAudio"
  autoPlay="true"
  controls
  class="hide"
></audio>
