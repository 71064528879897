import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../shared/services/common.service';
import { environment } from '../../../environments/environment';
import { WebApiService } from '../../shared/services/web-api.service';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { StorageService } from '../../shared/services/storage.service';
import { NodeService } from '../../shared/services/node.service';

/**
 *
 */
@Component({
  selector: 'app-linkedinroute',
  templateUrl: './linkedinroute.component.html',
  styleUrls: ['./linkedinroute.component.css'],
})
export class LinkedinrouteComponent implements OnInit {
  private apiDomain: string = environment.apiUrl;
  public loading = false;
  /**
   *
   * @param route
   * @param api
   * @param commonService
   * @param storageService
   * @param router
   * @param nodeService
   */
  constructor(
    private route: ActivatedRoute,
    private api: WebApiService,
    private commonService: CommonService,
    private storageService: StorageService,
    private router: Router,
    private nodeService: NodeService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    //alert('Hello');
    console.log(
      'Inside queryParams--->' + JSON.stringify(this.route.queryParams),
    );
    /**api call to fetch user details from LinkedIn */
    this.route.queryParams.subscribe(
      (params) => {
        const providerData = {
          endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
          data: {
            code: params.code,
            callback_url: API_ENDPOINTS.LINKEDIN_CALLBACKURL,
            wstoken: this.commonService.getWsToken(),
            wsfunction: API_FUNCTIONS.LINKEDIN_LOGIN,
            moodlewsrestformat: 'json',
          },
          contentType: 'application/x-www-form-urlencoded',
        };
        this.loading = true;

        this.api.postApiCall(providerData).subscribe(
          (response: any) => {
            console.log('Response--->' + JSON.stringify(response));
            const viewProfileReqData = {
              endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
              data: {
                user_token: response.code,
              },
              params: {
                token: response.userinfo.token,
                function: API_FUNCTIONS.VIEW_PROFILE,
                format: 'json',
              },
            };
            console.log('Here--->' + API_ENDPOINTS.COMMON_ENDPOINT);
            this.api.postApiCall(viewProfileReqData).subscribe(
              (profileResponse: any) => {
                this.loading = false;
                const userDetails = {
                  first_name: profileResponse.firstname,
                  last_name: profileResponse.lastname,
                  email: profileResponse.email,
                  profile_image: profileResponse.profile_img,
                  user_id: profileResponse.id,
                };
                this.commonService.setLoginToken(response.userinfo.token);
                this.storageService.addToSessionStorage(
                  'loginToken',
                  response.userinfo.token,
                );
                this.storageService.addToSessionStorage(
                  'userInfo',
                  userDetails,
                );
                this.nodeService.addNode({ isLogin: true });
                this.nodeService.addNode({
                  profileImg: profileResponse.profile_img,
                });
                setTimeout(() => {
                  this.router.navigateByUrl('');
                }, 1000);
              },
              () => {
                this.loading = false;
              },
            );
          },
          () => {
            this.loading = false;
          },
        );
      },
      () => {
        this.loading = false;
      },
    );
  }
}
