/********************************************************************
 * Created ON:15/11/2018
 * Created by: Pallavi
 * Description: Profile information view file.
 * Revision History:
 * Modification date: 25/12/2018
 * Description: Speration of various functionlity into different modules
 **********************************************************************/

import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NodeService } from '../../shared/services/node.service';
import { StorageService } from '../../shared/services/storage.service';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { WebApiService } from '../../shared/services/web-api.service';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { CommonService } from '../../shared/services/common.service';
import { MODAL_CONFIG } from '../../shared/constant/modalConfig.constant';
import { MuteService } from '../../shared/services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('successchangepasswordModal')
  public successchangepasswordModal: ModalDirective;
  @ViewChild('changepasswordModal') public changepasswordModal: ModalDirective;
  @ViewChild('successprofileModal') public successprofileModal: ModalDirective;

  public programListData: any = {};
  public editProf = false;
  public infoProfile: any = {};
  public mylearnings = true;
  public loading = false;
  public billing = false;
  public badges = false;
  public certificate = false;
  public isActivelearning = true;
  public isActivebilling = false;
  public isActivebadges = false;
  public isActivecertificates = false;
  public userDtls: any;
  public filterStatus = 'all';
  public max = 5;
  public rate = 5;
  public newrate = 0;
  public isReadonly = false;
  public overStar: number;
  public percent: number;
  public myProfileAudioUrl = '';
  public modalopen = false;
  public mylearningsMenu = true;
  public minWindowWidth = 768;
  public prevState = {
    myLearningModal: false,
    filter: this.filterStatus,
    page: 'mylearning',
  };

  public profileinfo = {
    profileFirstName: '',
    profileLastName: '',
    profileEmailId: '',
    profileImage: '',
  };
  public allAudioTags: any;
  private currentWindowWidth: number;

  /**
   *
   * @param bsModalRef
   * @param router
   * @param api
   * @param modalService
   * @param modRef
   * @param commonService
   * @param storageService
   * @param nodeService
   * @param muteService
   */
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private api: WebApiService,
    private modalService: BsModalService,
    private modRef: BsModalRef,
    private commonService: CommonService,
    private storageService: StorageService,
    private nodeService: NodeService,
    private muteService: MuteService,
  ) {
    this.userDtls = this.storageService.getFromSessioStorage('userInfo');
    this.modalService.hide(this.modalService.getModalsCount());
    /**
     * Depending on the login type, assigning the variables
     */
    /**
     * If normal sigup, then there will not be any provider
     */
    if (this.userDtls.provider !== 'undefined') {
      this.profileinfo.profileFirstName = this.userDtls.first_name;
      this.profileinfo.profileLastName = this.userDtls.last_name;
      this.profileinfo.profileEmailId = this.userDtls.email;
      this.profileinfo.profileImage = this.userDtls.profile_image;
    } else {
      this.profileinfo.profileFirstName = this.userDtls.name;
      this.profileinfo.profileLastName = '';
      this.profileinfo.profileEmailId = this.userDtls.email;
      this.profileinfo.profileImage = this.userDtls.profile_image;
    }
    this.infoProfile = this.profileinfo;

    this.nodeService.nodeObservable.subscribe((node) => {
      if (node['profileImg'] || node['profileImg'] == '') {
        this.profileinfo.profileImage = node['profileImg'];
      }
      if (node['modalopen'] !== undefined) {
        this.modalopen = node['modalopen'];
      }
    });
  }

  /**
   *
   */
  ngOnInit() {
    this.nodeService.addNode({ pageOpen: true });
    this.mylearnings = true;
    this.billing = false;
    this.badges = false;
    this.certificate = false;
    this.isActivelearning = true;
    this.isActivebilling = false;
    this.isActivebadges = false;
    this.isActivecertificates = false;
    this.muteService.muteFun();
    this.currentWindowWidth = window.innerWidth;
    this.loadAudio(0);
    this.storageService.addToLocalStorage('prevState', this.prevState);
    this.mylearningsMenu = this.currentWindowWidth > this.minWindowWidth;
  }
  /**
   *
   */
  ngOndestroy() {
    this.nodeService.addNode({ pageOpen: false });
  }
  /**
   * Resetting the sart
   */
  resetStar(): void {
    this.overStar = void 0;
  }
  /**
   * Handles resize of window
   *
   * @param event Window resize event
   */
  public onResize(event) {
    this.currentWindowWidth = event.target.innerWidth;
    this.mylearningsMenu = this.currentWindowWidth > this.minWindowWidth;
  }

  /**
   *
   * @param value
   * On hover of rating
   */
  public hoveringOver(value: number): void {
    this.overStar = value;
    this.percent = (value / this.max) * 100;
  }

  /**
   * Update Menu Visibility BasedOn Window Size
   */
  public updateMylearningsMenuVisibility() {
    if (this.currentWindowWidth < this.minWindowWidth) {
      this.mylearningsMenu = false;
    }
  }

  /**
   * Show My Learning Menu
   */
  public showMylearningsMenuVisibility() {
    this.mylearningsMenu = true;
  }
  /**
   *
   */
  public mylearningsTab() {
    this.mylearnings = true;
    this.billing = false;
    this.prevState.filter = 'all';
    this.prevState.myLearningModal = false;
    this.prevState.page = 'mylearning';
    this.storageService.addToLocalStorage('prevState', this.prevState);
    this.badges = false;
    this.certificate = false;
    this.isActivelearning = true;
    this.isActivebilling = false;
    this.isActivebadges = false;
    this.isActivecertificates = false;
    this.updateMylearningsMenuVisibility();
  }

  /**
   *
   */
  public billingTab() {
    this.mylearnings = false;
    this.billing = true;
    this.badges = false;
    this.certificate = false;
    this.isActivelearning = false;
    this.isActivebilling = true;
    this.isActivelearning = false;
    this.isActivebadges = false;
    this.isActivecertificates = false;
    this.updateMylearningsMenuVisibility();
  }

  /**
   *
   */
  public badgeTab() {
    this.mylearnings = false;
    this.billing = false;
    this.badges = true;
    this.certificate = false;
    this.isActivelearning = false;
    this.isActivebilling = false;
    this.isActivebadges = true;
    this.isActivecertificates = false;
    this.updateMylearningsMenuVisibility();
  }

  /**
   *
   */
  public certificateTab() {
    this.myProfileAudioUrl =
      '../../assets/audio/myprofile/mylearning/certificates_page_intro.mp3';
    this.mylearnings = false;
    this.prevState.filter = 'completed';
    this.prevState.myLearningModal = true;
    this.prevState.page = 'certificate';
    this.storageService.addToLocalStorage('prevState', this.prevState);
    this.billing = false;
    this.badges = false;
    this.certificate = true;
    this.isActivelearning = false;
    this.isActivebilling = false;
    this.isActivebadges = false;
    this.isActivecertificates = true;
    this.updateMylearningsMenuVisibility();
  }

  /**
   *
   */
  public editProfilePage() {
    const initialState = { infoProfile: this.infoProfile };
    this.bsModalRef = this.modalService.show(
      EditProfileComponent,
      Object.assign({ initialState }, MODAL_CONFIG.editprofileconfig),
    );
  }

  /**
   * Logout function. Clearing all the localstorage values
   * and redirecting to home url
   */
  public logout() {
    this.storageService.removeFromSessionStorage('userInfo');
    this.storageService.removeFromLocalStorage('loginToken');
    this.commonService.setLoginToken('');
    this.storageService.removeFromSessionStorage('loginToken');
    this.nodeService.addNode({ isLogin: false });
    this.nodeService.addNode({ profileImg: '' });
    this.router.navigate(['']);
  }
  /**
   * Opening change password modal
   */
  public changepasswordModalFun() {
    this.bsModalRef = this.modalService.show(
      ChangepasswordComponent,
      MODAL_CONFIG.changepasswordconfig,
    );
  }
  /**
   * closing profile modal
   */

  /**
   *
   * @param eleNo
   */
  public loadAudio(eleNo) {
    switch (eleNo) {
      case 0:
        this.myProfileAudioUrl =
          '../../assets/audio/myprofile/My_Learning_Welcome_Message.mp3';
        break;
      case 1:
        this.myProfileAudioUrl =
          '../../assets/audio/myprofile/Change_Password_Link.mp3';
        break;
      case 2:
        this.myProfileAudioUrl =
          '../../assets/audio/myprofile/Edit_Profile_Link.mp3';
        break;
      case 3:
        this.myProfileAudioUrl =
          '../../assets/audio/myprofile/My_Learning_Tab.mp3';
        break;
      case 4:
        this.myProfileAudioUrl =
          '../../assets/audio/myprofile/Billing_Button.mp3';
        break;
      case 5:
        this.myProfileAudioUrl =
          '../../assets/audio/myprofile/My_Learning_LogOut_Button.mp3';
        break;
      case 6:
        this.myProfileAudioUrl =
          '../../assets/audio/myprofile/badges_page_link_audio.mp3';
        break;
      case 7:
        this.myProfileAudioUrl =
          '../../assets/audio/myprofile/certificates_page_link_audio.mp3';
        break;
      default:
        this.myProfileAudioUrl = '';
    }
  }
}
