/********************************************************************
 * Created ON:14/12/2018
 * Created by: Pallavi
 * Description: All common shared services in one place
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
/**
 *
 */
@Injectable()
export class CommonService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  public receptionImg: any;
  public receptionClass: any;
  public openToken = 'aacb30a2122cc41ab49277974483ac20';
  public guestuserToken = '2c249b33f820b63f839c5d0f7a412b27';
  public loginToken = '';
  public classroomData: any;
  public programList: any = [];
  public paypalDetails: any;
  public nameregex = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z])?[a-zA-Z]*)*");
  public strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
  );
  /**
   *
   * @param router
   */
  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }
  // public regExpForPassword() {
  //   const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
  //   return strongRegex;

  // }
  /**
   *
   */
  public getPreviousUrl() {
    return this.previousUrl;
  }
  /**
   *
   */
  public getWsToken() {
    return this.openToken;
  }
  /**
   *
   */
  public getGuestToken() {
    return this.guestuserToken;
  }
  /**
   *
   * @param img
   * @param recepClass
   */
  public setReceptionImg(img: any, recepClass: any) {
    this.receptionImg = img;
    this.receptionClass = recepClass;
  }
  /**
   *
   */
  public getReceptionImg() {
    const receptionData = {
      receptionImg: this.receptionImg,
      receptionClass: this.receptionClass,
    };
    return receptionData;
  }

  /**
   *
   * @param token
   */
  public setLoginToken(token) {
    this.loginToken = token;
  }
  /**
   *
   */
  public getLoginToken() {
    return this.loginToken;
  }

  /**
   *
   * @param progList
   */
  public setProgListing(progList) {
    this.programList = progList;
  }
  /**
   *
   * @param schoolId
   */
  public getProgListing(schoolId) {
    const list: any = [];
    for (let index = 0; index < this.programList.length; index++) {
      if (schoolId == 0) {
        list.push(this.programList[index]);
      } else {
        if (this.programList[index].orderno == schoolId) {
          list.push(this.programList[index]);
        }
      }
    }
    return list;
  }

  /**
   *
   * @param details
   */
  public setPaypalDetails(details) {
    this.paypalDetails = details;
  }

  /**
   *
   */
  public getPaypalDetails() {
    return this.paypalDetails;
  }
  /**
   *
   * @param data
   */
  public setClassroomData(data) {
    this.classroomData = data;
  }
  /**
   *
   * @param progIndex
   * @param lessonIndex
   */
  public getClassroomData(progIndex, lessonIndex) {
    let classData: any = [];
    if (this.classroomData) {
      if (lessonIndex) {
        const lessonData: any = [];
        lessonData.push(this.classroomData[progIndex].lessons[lessonIndex]);
        classData = this.classroomData[progIndex];
        classData.lessons = lessonData;
      } else {
        classData = this.classroomData[progIndex];
      }
    } else {
      classData = [];
    }
    return classData;
  }
}
