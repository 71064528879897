/********************************************************************
 * Created ON:27/12/2018
 * Created by: Avaya
 * Description: Common component for help guild lady.
 * Revision History:
 * Modification date:
 * Description: T
 **********************************************************************/
import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { MuteService } from '../../services/mute.service';
declare let require: any;
/**
 *
 */
@Component({
  selector: 'app-help-guide',
  templateUrl: './help-guide.component.html',
  styleUrls: ['./help-guide.component.css'],
})
export class HelpGuideComponent implements OnInit {
  /**
   * Input variable for audio, vtt file's url and class
   */
  @Input() audioUrl: string;
  @Input() textUrl: string;
  @Input() newClassDialogue: string;
  @Input() newClass: string;
  @Input() animatedCharecter: string;
  public allAudioTags;

  /**
   * Variables for Text box visibilty
   */
  public hideTextBox = false;
  public popoverIcon = '';
  public popoverText = 'Show text box';
  public popoverIconFlag = 'Show';
  public popoverClass = 'a-popover';
  public hideTextBoxFlag = false;
  /**
   *
   * @param storageService provides the token at states saved in local storage
   * @param muteService allow to mute or unmute the sound
   */
  constructor(
    private storageService: StorageService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.muteService.muteFun();
    this.popoverIcon = '../../../assets/img/chat-close.svg';
    const localStorageToken =
      this.storageService.getFromLocalStorage('loginToken');
    if (localStorageToken) {
      this.storageService.addToSessionStorage('loginToken', localStorageToken);
    }
    if (this.newClass == 'chat-icon-default') {
      this.hideTextBox = true;
      this.popoverIcon = '../../assets/img/chat-open.svg';
      this.popoverText = 'Hide Text Box';
      this.popoverIconFlag = 'Hide';
    }
    const loginToken = this.storageService.getFromSessioStorage('loginToken');
    if (loginToken) {
      this.popoverClass = 'a-popover-loggedIn';
    }
  }
  /**
   *
   */
  ngOnChanges() {
    const audioPlayer = <HTMLAudioElement>document.getElementById('audiofile');
    const subtitles = document.getElementById('subtitles');
    let syncData = [];
    const convertVttToJson = require('vtt-json');
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    audioPlayer.addEventListener('timeupdate', function () {
      fetch(new Request(that.textUrl))
        .then((response) => response.text())
        .then(createSubtitle);
      syncData.forEach(function (element, index) {
        let el;
        if (
          audioPlayer.currentTime * 1000 >= element.start &&
          audioPlayer.currentTime * 1000 <= element.end
        ) {
          while (subtitles.hasChildNodes())
            subtitles.removeChild(subtitles.firstChild);

          el = document.createElement('span');
          el.setAttribute('id', 'c_' + 0);
          el.innerText = syncData[index].part + '\n';
          subtitles.appendChild(el);
        }
      });
    });

    function createSubtitle(text) {
      syncData = [];
      convertVttToJson(text).then((result) => {
        let x = 0;
        for (let i = 0; i < result.length; i++) {
          if (result[i].part && result[i].part.trim() != '') {
            syncData[x] = result[i];
            x++;
          }
        }
      });
    }
  }

  /**
   *
   * @param eleNo provides element number
   */
  public loadAudio(eleNo) {
    const profileAudio = <HTMLAudioElement>document.getElementById('menuAudio');
    this.hideTextBoxFlag = !this.hideTextBoxFlag;
    if (eleNo == 99) {
      profileAudio.src = '';
    } else {
      if (this.popoverIconFlag == 'Show') {
        profileAudio.src = '../../assets/audio/show_text_box.mp3';
      }
      if (this.popoverIconFlag == 'Hide') {
        profileAudio.src = '../../assets/audio/hide_text_box.mp3';
      }
    }
    const audioId = document.getElementById('audiofile') as HTMLAudioElement;
    if (audioId)
      profileAudio.src === ''
          ? audioId.ended
              ? null
              : audioId.play()
          : audioId.pause();
  }

  /**
   * Mange to show or Hide TextBox
   */
  public hidePopoverDialogue() {
    this.hideTextBoxFlag = !this.hideTextBoxFlag;
    if (!this.hideTextBoxFlag) {
      this.popoverIcon = '../../assets/img/chat-close.svg';
      this.hideTextBox = false;
      this.popoverText = 'Show text box';
      this.popoverIconFlag = 'Show';
      this.storageService.addToSessionStorage('hideText', true);
    } else {
      this.popoverIcon = '../../assets/img/chat-open.svg';
      this.hideTextBox = true;
      this.popoverText = 'Hide text box';
      this.popoverIconFlag = 'Hide';
      this.storageService.removeFromSessionStorage('ShowText');
    }
  }
  /**
   *
   */
  public mediaLoaded() {
    const audioPlayer = <HTMLAudioElement>document.getElementById('audiofile');
    audioPlayer.play();
  }
}
