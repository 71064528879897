import { NgModule } from '@angular/core';
import { WebApiService } from '../shared/services/web-api.service';
import { StorageService } from '../shared/services/storage.service';
import { NodeService } from '../shared/services/node.service';

import { CommonModule } from '@angular/common';

import { NgxLoadingModule } from 'ngx-loading';
import { CommonService } from '../shared/services/common.service';

/**
 *
 */
@NgModule({
  providers: [WebApiService, StorageService, CommonService, NodeService],
  declarations: [],
  imports: [NgxLoadingModule.forRoot({}), CommonModule],
  exports: [],
})
export class MyProfileModule {}
