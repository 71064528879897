/********************************************************************
 * Created ON:1/08/2023
 * Created by: Mohit Kumar
 * Description: Badges display file.
 * Badges of user will be shown
 **********************************************************************/

import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageService } from '../../shared/services/storage.service';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { environment } from '../../../environments/environment';
import { NodeService } from '../../shared/services/node.service';
import { MuteService } from '../../shared/services/mute.service';
import { WebApiService } from '../../shared/services/web-api.service';

/**
 *
 */
@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.css'],
})
export class BadgesComponent implements OnInit {
  public url: any;
  public twitterShareApi =
    'https://twitter.com/intent/tweet?text=Check out my Certificate&url=';
  public fbShareAPi = 'http://www.facebook.com/sharer.php?u=';
  public linkedInAPi = 'https://www.linkedin.com/shareArticle?mini=false&url=';
  public loginToken = this.storageService.getFromSessioStorage('loginToken');
  public wsfunction = API_FUNCTIONS.MY_BADGES;
  public allAudioTags: any;
  public profileAudio: any;
  public profileFulltName = '';
  public loading = true;
  public userDtls: any;
  public badgeData: any;
  public audioSources: any;

  /**
   * @param api - Service for making API calls.
   * @param bsModalRef - Reference to a Bootstrap modal.
   * @param storageService - Service for handling data storage.
   * @param nodeService - Service related to nodes (possibly in a tree or graph context).
   * @param modalService - Service for managing modals (dialog boxes).
   * @param muteService - Service related to muting or silencing functionality.
   */
  constructor(
    private api: WebApiService,
    private bsModalRef: BsModalRef,
    private storageService: StorageService,
    private nodeService: NodeService,
    private modalService: BsModalService,
    private muteService: MuteService,
  ) {}

  /**
   * initialization logic
   */
  public ngOnInit() {
    this.nodeService.addNode({ modalopen: true });
    // this.muteService.muteFun();
    this.loginToken = this.storageService.getFromSessioStorage('loginToken');
    this.userDtls = this.storageService.getFromSessioStorage('userInfo');
    this.profileFulltName =
      this.userDtls.first_name + ' ' + this.userDtls.last_name;
    this.audioSources = {
      0: '../../assets/audio/myprofile/badges/bage_intro_audio.mp3',
      1: '../../assets/audio/shared/Close_Button.mp3',
      2: '../../assets/audio/myprofile/badges/linkedIn_share_badge.mp3',
      3: '../../assets/audio/myprofile/badges/twitter_share_badge.mp3',
      4: '../../assets/audio/myprofile/badges/facebook_share_badge.mp3',
    };
    this.loadBadges();
    this.loadAudio(0);
  }

  /**
   * method for getting data of the user Badges
   */
  public loadBadges() {
    this.loading = true;
    const requestDataMyLearning = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        token: this.loginToken,
      },
      params: {
        token: this.loginToken,
        function: API_FUNCTIONS.MY_BADGES,
        format: 'json',
      },
    };
    this.api.postApiCall(requestDataMyLearning).subscribe(
      (response: any) => {
        this.modalService.hide(this.modalService.getModalsCount());
        this.badgeData = response.badges;
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.modalService.hide(this.modalService.getModalsCount());
      },
    );
  }

  /**
   * @param badge The badge to be shared on Twitter.
   * @returns Returns `false` to prevent the default behavior of the click event.
   */
  public shareOnTwitter(badge) {
    const url = badge.image_url;
    const title = 'Badge Earned ' + badge.name;
    const description = badge.description;
    let twitterShareUrl = encodeURIComponent(
      `${environment.apiUrl}/twitter_share.php?hash=${badge.uniquehash}&img_url=${url}&title=${title}&description=${description}`,
    );
    twitterShareUrl = this.twitterShareApi + twitterShareUrl;
    window.open(
      twitterShareUrl,
      'twitter-popup',
      'toolbar=0,status=0,width=600,height=350',
    );
    return false;
  }

  /**
   * @param badge The badge to be shared on FaceBook.
   * @returns Returns `false` to prevent the default behavior of the click event.
   */
  public shareOnFb(badge) {
    const url = badge.image_url;
    const title = 'Badge Earned ' + badge.name;
    const description = badge.description;
    let facebookShareUrl = encodeURIComponent(
      `${environment.apiUrl}/facebook_share.php?hash=${badge.uniquehash}&img_url=${url}&title=${title}&description=${description}`,
    );
    facebookShareUrl = this.fbShareAPi + facebookShareUrl;
    window.open(
      facebookShareUrl,
      'sharer',
      'toolbar=0,status=0,width=600,height=350',
    );
    return false;
  }

  /**
   * @param badge The badge to be shared on LinkedIn
   * @returns Returns `false` to prevent the default behavior of the click event.
   */
  public shareOnLinkedIn(badge) {
    const url = badge.image_url;
    const title = 'Badge Earned ' + badge.name;
    const description = badge.description;
    this.url = url;
    let linkedInUrl = encodeURIComponent(
      `${environment.apiUrl}/linkedin_share.php?hash=${badge.uniquehash}&img_url=${url}&title=${title}&description=${description}`,
    );
    linkedInUrl = this.linkedInAPi + linkedInUrl;
    window.open(
      linkedInUrl,
      'sharer',
      'toolbar=0,status=0,width=600,height=350',
    );
    return false;
  }

  /**
   * @param eleNo  The element number used to determine the audio to be loaded and played.
   */
  public loadAudio(eleNo) {
    this.profileAudio = document.getElementById('myProfileAudio');
    this.profileAudio.src = this.audioSources[eleNo] || '';
  }

  /**
   * Closes the modal and updates the node service to indicate that the modal is closed.
   */
  public closeModal() {
    this.bsModalRef.hide();
    this.nodeService.addNode({ modalopen: false });
  }
}
