/********************************************************************
 * Created ON:6/11/2018
 * Created by: Avaya
 * Description: This will have all the main routing.
 *
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { AuthGuard } from './shared/services/auth.guard';

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { ProfileComponent } from './myprofile/profile/profile.component';
import { QuizComponent } from './quiz/quiz.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ServicelevelComponent } from './servicelevel/servicelevel.component';
import { ZoomComponent } from './zoom/zoom.component';
const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'mylearning',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'myquiz',
    component: QuizComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'myzoom',
    component: ZoomComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./course/course.module').then((m) => m.CourseModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'reset',
    component: ResetpasswordComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  // {
  //   path: 'linkedIn',
  //   component: LinkedinrouteComponent
  // },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'sla',
    component: ServicelevelComponent,
  },

  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'admin/:id',
    component: AdminComponent,
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
/**
 *
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
