<a
  class="menu"
  (focus)="loadAudio(0)"
  (focusout)="loadAudio(99)"
  (mouseenter)="loadAudio(0)"
  (mouseleave)="loadAudio(99)"
  [attr.aria-label]="menuOpen ? 'Close' : 'Campus map'"
  [ngClass]="{ open: menuOpen === true }"
  href="javascript:void(0)"
  [tabindex]="modalOpen ? (viewProg ? 0 : -1) : 0"
  [tooltip]="menuOpen ? 'Close' : 'Campus Map'"
  placement="right"
  (click)="menuFun()"
>
  <span [attr.aria-hidden]="true" class="menu-circle"></span>
  <span [attr.aria-hidden]="true" class="menu-link">
    <span [attr.aria-hidden]="true" class="menu-icon">
      <img
        [attr.aria-hidden]="true"
        src="../../assets/img/menu.svg"
        class="m-icon"
      />
    </span>
  </span>
  <span class="menu-circle close" *ngIf="menuClose"></span>
  <span class="menu-link" *ngIf="menuClose">
    <span class="menu-icon">
      <img src="../../assets/img/menu_close.svg" class="c-icon" />
    </span>
  </span>
</a>
<div *ngIf="!menuOpen" class="right-menu">
  <ul>
    <li [attr.aria-label]="'Volume'">
      <a
        id="volumeIcon"
        class="m-circle"
        (focus)="loadAudio(1)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(1)"
        (mouseleave)="loadAudio(99)"
        [attr.aria-label]="volumeIcon"
        (click)="muteAudio()"
        href="javascript:void(0)"
        [tooltip]="volumeIcon"
        placement="left"
        [tabindex]="modalOpen ? (viewProg ? 0 : -1) : 0"
      >
        <span [attr.aria-hidden]="true" class="m-circle-sound"></span>
        <span [attr.aria-hidden]="true" class="sound-link">
          <span [attr.aria-hidden]="true" class="sound-icon">
            <img [attr.aria-hidden]="true" class="m-sound-icon" [src]="sound" alt="Toggle Audio" />
          </span>
        </span>
      </a>
    </li>
    <li *ngIf="islogin" [attr.aria-label]="'Mylearning'">
      <!-- <img [src]="profileImage" class="m-circle profile" (click)="menuOpenProfile();"> -->
      <a
        id="myLearningIcon"
        (focus)="loadAudio(2)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(2)"
        (mouseleave)="loadAudio(99)"
        [attr.aria-label]="'Mylearning'"
        href="javascript:void(0)"
        [tabindex]="modalOpen ? (viewProg ? 0 : -1) : 0"
        class="menu-profile"
        (click)="menuOpenProfile()"
      >
        <img
          [attr.aria-hidden]="true"
          [src]="profileImage"
          default="../../assets/img/profile.svg"
          newclass="m-circle-prof profile"
          tooltip="My Learning"
          placement="bottom"
        />
      </a>
    </li>
  </ul>
</div>
<div class="menu-overlay" [ngClass]="{ open: menuOpen === true }">
  <div class="menu-content">
    <h2>Welcome to the Campus Map.</h2>
    <h5 style="font-size: 20px" class="lt-grey opy_8">
      Please select the area of the Helix Opportunity
    </h5>
    <h5 style="font-size: 20px" class="lt-grey opy_8">
      Institute you would like to visit.
    </h5>
    <div class="menu-container">
      <a
        (focus)="loadAudio(3)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(3)"
        (mouseleave)="loadAudio(99)"
        href="javascript:void(0)"
        class="menu-list menu-admin active"
        (click)="buildingSelection(0)"
      >
        <div [attr.aria-hidden]="true" class="menu-con">
          <img [src]="buildings.administration" class="img-responsive" />
        </div>
        <img
          [attr.aria-hidden]="true"
          src="../../assets/img/menu/shadow-home.png"
          class="admin-shadow"
        />
        <h4>Administration</h4>
        <div class="location" *ngIf="locationPointer === 0">
          <h2>You are here</h2>
          <img src="../../assets/img/menu/icn-location.svg" width="28" />
        </div>
      </a>
      <a
        (focus)="loadAudio(4)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(4)"
        (mouseleave)="loadAudio(99)"
        href="javascript:void(0)"
        class="menu-list menu-School-common active"
        (click)="buildingSelection(2)"
      >
        <div [attr.aria-hidden]="true" class="menu-con">
          <img [src]="buildings.commonSense" class="img-responsive" />
        </div>
        <img
          [attr.aria-hidden]="true"
          src="../../assets/img/menu/shadow-school.png"
          class="school-shadow"
        />
        <h4>School of <br />Common Sense Design</h4>
        <div class="location loc-small" *ngIf="locationPointer === 2">
          <h2>You are here</h2>
          <img src="../../assets/img/menu/icn-location.svg" width="20" />
        </div>
      </a>
      <a
        (mouseenter)="loadAudio(5)"
        (mouseleave)="loadAudio(99)"
        href="javascript:void(0)"
        (focus)="loadAudio(5)"
        (focusout)="loadAudio(99)"
        class="menu-list menu-School-business active"
        (click)="buildingSelection(3)"
      >
        <div [attr.aria-hidden]="true" class="menu-con">
          <img [src]="buildings.buisness" class="img-responsive" />
        </div>
        <img
          [attr.aria-hidden]="true"
          src="../../assets/img/menu/shadow-school.png"
          class="school-shadow"
        />
        <h4>Business Development <br />School of Global Inclusion</h4>
        <div class="location loc-small" *ngIf="locationPointer === 3">
          <h2>You are here</h2>
          <img src="../../assets/img/menu/icn-location.svg" width="20" />
        </div>
      </a>
      <a
        (mouseenter)="loadAudio(6)"
        (mouseleave)="loadAudio(99)"
        href="javascript:void(0)"
        (focus)="loadAudio(6)"
        (focusout)="loadAudio(99)"
        class="menu-list menu-School-Cooperative active"
        (click)="buildingSelection(1)"
      >
        <div [attr.aria-hidden]="true" class="menu-con">
          <img [src]="buildings.cooperative" class="img-responsive" />
        </div>
        <img
          [attr.aria-hidden]="true"
          src="../../assets/img/menu/shadow-school.png"
          class="school-shadow"
        />
        <h4 class="cooperativelabel">
          Organizational Development School <br />of Cooperative Understanding
        </h4>
        <div class="location loc-small" *ngIf="locationPointer === 1">
          <h2>You are here</h2>
          <img src="../../assets/img/menu/icn-location.svg" width="20" />
        </div>
      </a>
      <a
        (mouseenter)="loadAudio(7)"
        (mouseleave)="loadAudio(99)"
        href="javascript:void(0)"
        (focus)="loadAudio(7)"
        (focusout)="loadAudio(99)"
        class="menu-back-campus"
        (click)="homePage()"
      >
        <div class="menu-back-campus-con">
          <img
            [attr.aria-hidden]="true"
            src="../../assets/img/menu/icn-home.svg"
            class="m-home-icon"
          />
          <h6>back to campus</h6>
        </div>
        <img
          [attr.aria-hidden]="true"
          src="../../assets/img/menu/campus-shadow.png"
          class="m-home-shadow"
        />
      </a>
    </div>
  </div>
</div>
<audio
  id="menuAudio"
  [src]="audioUrl"
  autoPlay="true"
  controls
  class="hide"
></audio>
