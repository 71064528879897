/********************************************************************
 * Created ON:26/11/2018
 * Created by: Avaya
 * Description: SignIn and SignUp feature. It includes social singin as well
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { ForgotpasswordComponent } from './../forgotpassword/forgotpassword.component';
import { WebApiService } from './../../shared/services/web-api.service';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from './../../shared/constant/apiEndpoint.constant';
import { Component, OnInit, HostListener } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NodeService } from './../../shared/services/node.service';
// import {
//   AuthService,
//   FacebookLoginProvider,
//   GoogleLoginProvider,
//   LinkedinLoginProvider
// } from 'angular5-social-auth';
import { SocialAuthService } from 'angularx-social-login';

import { Router } from '@angular/router';
import { StorageService } from './../../shared/services/storage.service';
import { SuccessmodalComponent } from '../../shared/component/successmodal/successmodal.component';
import { ErrormodalComponent } from '../../shared/component/errormodal/errormodal.component';
import { CommonService } from '../../shared/services/common.service';
// import { errorHandler } from '@angular/platform-browser/src/browser';
import { MODAL_CONFIG } from '../../shared/constant/modalConfig.constant';
import { MuteService } from '../../shared/services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  /**
   *
   */
  @HostListener('focusin', ['$event']) onFocus() {
    this.loginResponse.loginsuccess = true;
  }
  /**
   *
   * Declaring variables
   */
  public test = false;
  public loading = false;
  public showPwdSignIn = false;
  public showPwdRegister = false;
  public showPwdConfirm = false;
  public loginResponse = {
    loginsuccess: true,
    errormsg: '',
  };
  public signupResponse = {
    signupSuccess: true,
    errormsg: '',
  };
  public onSigninModalClose: any;
  public isLogin = true;
  public ssourl: any;
  public ssoFunction: any;
  public allAudioTags: any;
  public audioId: any;
  public signinAudio: any;
  /**
   *
   * @param socialAuthService
   * @param api
   * @param storageService
   * @param router
   * @param commonService
   * @param bsmodalref
   * @param bsModalService
   * @param nodeService
   * @param muteservice
   */
  constructor(
    private socialAuthService: SocialAuthService,
    private api: WebApiService,
    private storageService: StorageService,
    private router: Router,
    private commonService: CommonService,
    private bsmodalref: BsModalRef,
    private bsModalService: BsModalService,
    private nodeService: NodeService,
    private muteservice: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    // console.log(document.getElementById('rememberme').setAttribute(checked:''));
    const homeAudio = <HTMLAudioElement>document.getElementById('homeAudio');
    homeAudio ? homeAudio.pause() : null;
    this.muteservice.muteFun();
    const providerData = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        callback_url: API_ENDPOINTS.FACEBOOK_CALLBACKURL,
        callback_url_ln: API_ENDPOINTS.LINKEDIN_CALLBACKURL,
        callback_url_g: API_ENDPOINTS.GOOGLE_CALLBACKURL,
        wstoken: this.commonService.getWsToken(),
        wsfunction: API_FUNCTIONS.IDENTITY_PROVIDER,
        moodlewsrestformat: 'json',
      },
      contentType: 'application/x-www-form-urlencoded',
    };
    this.loading = true;
    this.api.postApiCall(providerData).subscribe(
      (response: any) => {
        this.ssourl = response;
        this.loading = false;
        this.signinAudio = '../../assets/audio/SignIn_Tab.mp3';
        document
          .getElementById('signin-link')
          .addEventListener('click', handleSigninTab);
        document
          .getElementById('register-link')
          .addEventListener('click', handleRegisterTab);
        document
          .getElementById('signin-link')
          .addEventListener('keyPress', handleSigninTab);
        document
          .getElementById('register-link')
          .addEventListener('keyPress', handleRegisterTab);
        document
          .getElementById('signin-link')
          .addEventListener('focus', handleSigninTabsFocus);
        document
          .getElementById('register-link')
          .addEventListener('focus', handleRegisterTabsFocus);
        document
          .getElementById('signin-link')
          .addEventListener('mouseover', handleSigninTabsFocus);
        document
          .getElementById('register-link')
          .addEventListener('mouseover', handleRegisterTabsFocus);
        const audioelement = <HTMLAudioElement>(
          document.getElementById('signInAudio')
        );
        function handleSigninTab() {
          audioelement.src = '../../assets/audio/SignIn_Tab.mp3';
        }
        function handleRegisterTab() {
          audioelement.src = '../../assets/audio/Register_Tab.mp3';
        }
        function handleSigninTabsFocus() {
          audioelement.src = '../../assets/audio/signInRegister/sigin_tab.mp3';
        }
        function handleRegisterTabsFocus() {
          audioelement.src =
            '../../assets/audio/signInRegister/Register_Tab_Button.mp3';
        }
      },
      () => {
        this.loading = false;
      },
    );
  }
  /**
   * Signin form validation
   */
  public signInForm = new UntypedFormGroup({
    userName: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    rememberMe: new UntypedFormControl(''),
  });

  // public strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  // public nameregex = new RegExp("^[\\p{L} .'-]+$");
  /**
   * Signup form validation
   */
  public signUpForm = new UntypedFormGroup(
    {
      firstName: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.commonService.nameregex),
        Validators.maxLength(100),
      ]),
      lastName: new UntypedFormControl('', [
        Validators.pattern(this.commonService.nameregex),
        Validators.maxLength(100),
      ]),
      emailId: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(75),
      ]),
      passwords: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.commonService.strongRegex),
        Validators.minLength(8),
        Validators.maxLength(50),
      ]),
      confirmPasswords: new UntypedFormControl('', [Validators.required]),
    },
    this.passwordMatchValidator,
  );
  /**
   *
   * @param g
   * Password and confirm password matching validation
   */
  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('passwords').value === g.get('confirmPasswords').value
      ? null
      : { mismatch: true };
  }
  /**
   *
   * @param socialPlatform
   * Social signIn function.
   * @param modalName
   */
  public showPassword(modalName) {
    if (modalName == 'signin')
      this.showPwdSignIn = this.showPwdSignIn ? false : true;
    else if (modalName == 'register')
      this.showPwdRegister = this.showPwdRegister ? false : true;
    else this.showPwdConfirm = this.showPwdConfirm ? false : true;
  }
  /**
   *
   * @param socialPlatform
   */
  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == 'facebook') {
      socialPlatformProvider = this.ssourl.facebook_login_url;
      this.ssoFunction = API_FUNCTIONS.FACEBOOK_LOGIN;
    } else if (socialPlatform == 'google') {
      socialPlatformProvider = this.ssourl.Google_login_url;
      this.ssoFunction = API_FUNCTIONS.GOOGLE_LOGIN;
    } else if (socialPlatform == 'linkedin') {
      socialPlatformProvider = this.ssourl.Linkedin_login_url;
      this.ssoFunction = API_FUNCTIONS.LINKEDIN_LOGIN;
      console.log(
        'socialPlatformProvider' +
          socialPlatformProvider +
          ' --->' +
          API_FUNCTIONS.LINKEDIN_LOGIN,
      );
    }

    this.socialAuthService.signIn(socialPlatformProvider).then((userData) => {
      console.log(
        socialPlatform + ' sign in data : ',
        JSON.stringify(userData),
      );
      let userDetails = {};
      userDetails = {
        name: userData.name,
        profile_image: userData.photoUrl ? userData.photoUrl : '',
        email: userData.email,
        id: userData.id,
        provider: userData.provider,
        token: userData.authToken,
        idToken: userData.idToken ? userData.idToken : '',
      };
      //******Adding to node after successful login *******/
      if (userData) {
        this.isLogin = true;
        this.nodeService.addNode({ isLogin: this.isLogin });
        this.nodeService.addNode({ profileImg: userData.photoUrl });
      }

      //ToDo: Need to make API call to store user details.
      // That will be userfull in classroom or my learning , as reponse based on user
      //Or we can do it by authKey from backend
      this.storageService.addToSessionStorage('userInfo', userDetails);
      this.storageService.addToLocalStorage('permissionInfo', '');
      this.onSigninModalClose({ value: 1 });
    });
  }
  /**
   * Function for signin. Passing usename and password as post method
   */
  public signIn = () => {
    const user = this.getCookie('HellixAcceptedCookies'); // To Check Terms accepted or not
    if (user != '') {
      const requestData = {
        endPoint: API_ENDPOINTS.LOGIN,
        data: {
          username: this.signInForm.controls.userName.value,
          password: this.signInForm.controls.password.value,
          service: 'angular',
        },
      };
      this.loading = true;
      this.api.postApiCall(requestData).subscribe(
        (response: any) => {
          this.loading = false;
          if (response.error) {
            if (response.errorcode == 'invalidlogin') {
              this.loginResponse.loginsuccess = false;
              this.loginResponse.errormsg = response.error;
            } else {
              this.bsmodalref.hide();
              const initialState = { error_message: response.error };
              this.bsmodalref = this.bsModalService.show(
                ErrormodalComponent,
                Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
              );
            }
            this.signInForm.reset();
          } else {
            this.loginResponse.loginsuccess = true;
            this.loginResponse.errormsg = '';
            const token = response.token;

            const viewProfileReqData = {
              endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
              data: {
                user_token: token,
              },
              params: {
                token: token,
                function: API_FUNCTIONS.VIEW_PROFILE,
                format: 'json',
              },
            };
            this.api.postApiCall(viewProfileReqData).subscribe(
              (profileResponse: any) => {
                const userDetails = {
                  first_name: profileResponse.firstname,
                  last_name: profileResponse.lastname,
                  email: profileResponse.email,
                  profile_image: profileResponse.profile_img,
                  user_id: profileResponse.id,
                };
                this.commonService.setLoginToken(token);
                this.storageService.addToSessionStorage('loginToken', token);
                if (this.signInForm.controls.rememberMe.value == true) {
                  this.storageService.addToLocalStorage('loginToken', token);
                }
                this.storageService.addToSessionStorage(
                  'userInfo',
                  userDetails,
                );
                this.nodeService.addNode({
                  profileImg: userDetails.profile_image,
                });
                const paypalDetails = this.commonService.getPaypalDetails();
                paypalDetails.userid = profileResponse.id;
                this.onSigninModalClose({ value: 1 });
              },
              () => {},
            );
          }
        },
        () => {
          this.loading = false;
        },
      );
    } else {
      this.router.navigate(['/terms']);
    }
  };

  /**
   * Function for Registration
   */
  /**
   * Function to close open modal
   */
  public modalClose = () => {
    this.nodeService.addNode({ modalopen: false });

    this.bsmodalref.hide();
  };
  public signUp = () => {
    const user = this.getCookie('HellixAcceptedCookies'); // To Check Terms accepted or not
    if (user != '') {
      const requestData = {
        endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
        data: {
          firstname: this.signUpForm.controls.firstName.value,
          lastname: this.signUpForm.controls.lastName.value,
          email: this.signUpForm.controls.emailId.value,
          username: this.signUpForm.controls.emailId.value,
          password: this.signUpForm.controls.passwords.value, //sha256(this.signInForm.controls.password.value)
        },
        params: {
          token: this.commonService.getWsToken(),
          function: API_FUNCTIONS.REGISTER,
          format: 'json',
        },
      };
      this.loading = true;
      this.api.postApiCall(requestData).subscribe(
        (response: any) => {
          this.loading = false;
          this.signupResponse.signupSuccess = response.success;
          if (response.success) {
            const initialState = {
              success_message:
                'Your registration has been successfully submitted. Please, click on the confirmation link that was sent to your email address to complete this process.',
              from_module: 'register',
            };
            this.bsmodalref.hide();
            this.bsmodalref = this.bsModalService.show(
              SuccessmodalComponent,
              Object.assign({ initialState }, MODAL_CONFIG.successmodalconfig),
            );
          } else {
            console.log(JSON.stringify(response));
            this.signUpForm.controls.emailId.reset();
            this.signUpForm.controls.passwords.reset();
            this.signUpForm.controls.confirmPasswords.reset();
            this.signupResponse.errormsg = response.message; //response.warnings[0].message;
          }
        },
        () => {
          this.loading = false;
        },
      );
    } else {
      this.router.navigate(['/terms']);
    }
  };

  /**
   * Open Forgot Password Modal
   */
  public openForgotPasswordModal = () => {
    this.modalClose();
    this.nodeService.addNode({ modalopen: true });
    this.bsModalService.show(
      ForgotpasswordComponent,
      MODAL_CONFIG.forgotconfig,
    );
  };

  /**
   *
   * @param elementNo
   */
  public signInLoadAudio(elementNo) {
    switch (elementNo) {
      case 0:
        this.signinAudio = '../../assets/audio/Email_Field.mp3';
        break;
      case 1:
        this.signinAudio = '../../assets/audio/Signin_Password_Field.mp3';
        break;
      case 2:
        this.signinAudio = '../../assets/audio/Remember_Me.mp3';
        break;
      case 3:
        this.signinAudio = '../../assets/audio/Forgot_Password_Link.mp3';
        break;
      case 4:
        this.signinAudio = '../../assets/audio/Siginin_Button.mp3';
        break;
      case 5:
        this.signinAudio = '../../assets/audio/Facebook_Signin.mp3';
        break;
      case 6:
        this.signinAudio = '../../assets/audio/Google_Sigin.mp3';
        break;
      case 7:
        this.signinAudio = '../../assets/audio/LinkedIn_Siginin.mp3';
        break;
      default:
        this.signinAudio = '';
    }
  }
  /**
   *
   * @param elementNo
   */
  public tabAudio(elementNo) {
    switch (elementNo) {
      /**
       * ToDo
       * add audio for register and sigin tab
       */
      case 0:
        this.signinAudio = '';
        break;
      case 1:
        this.signinAudio = '';
        break;
      default:
        this.signinAudio = '';
    }
  }

  /**
   *
   * @param elementNo
   */
  public registerLoadAudio(elementNo) {
    switch (elementNo) {
      case 0:
        this.signinAudio = '../../assets/audio/First_Name.mp3';
        break;
      case 1:
        this.signinAudio = '../../assets/audio/Last_Name.mp3';
        break;
      case 2:
        this.signinAudio = '../../assets/audio/Email_Field.mp3';
        break;
      case 3:
        this.signinAudio = '../../assets/audio/Register_Password_Field.mp3';
        break;
      case 4:
        this.signinAudio =
          '../../assets/audio/shared/Password_Confirmation_Field.mp3';
        break;
      case 5:
        this.signinAudio = '../../assets/audio/Registration_Button.mp3';
        break;
      default:
        this.signinAudio = '';
    }
  }

  /**
   *
   * @param event
   */
  public tabChange(event) {
    const tabName = event.target.innerHTML;
    switch (tabName) {
      case 'Register':
        this.signinAudio = '../../assets/audio/Register_Tab.mp3';
        break;
      case 'Sign in':
        this.signinAudio = '../../assets/audio/SignIn_Tab.mp3';
        break;
      default:
        this.signinAudio = '';
    }
  }
  /**
   *
   * @param eleNo
   */
  public closeAudio(eleNo) {
    switch (eleNo) {
      case 0:
        this.signinAudio = '../../assets/audio/shared/Close_Button.mp3';
        break;
      default:
        this.signinAudio = '';
    }
  }
  /**
   *
   */
  public remembermeHandler() {
    console.log('event');
    // event.checked = !event.checked;
    this.test = !this.test;
    this.signInForm.controls.rememberMe.setValue(this.test);
  }

  /****************Check Cookies Exits or not for terms and usees****/
  /**
   *
   * @param cname
   */
  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}
