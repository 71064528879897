<!-- success profile -->
<div class="modal-body">
  <a
    tooltip="close"
    placement="right"
    href="javascript:voif(0)"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="successhideModal()"
  >
    <span aria-hidden="true" class="btn-close">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <div class="row">
    <div class="col-xs-12">
      <img src="../../assets/img/icn-success.svg" width="75" />
      <h3>{{ success_message }}</h3>
    </div>
  </div>
</div>
<audio
  id="successAudioTag"
  [src]="sucessAudio"
  autoPlay="true"
  controls
  class="hide"
></audio>

<!-- success profile -->
