import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageService } from '../../shared/services/storage.service';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { environment } from '../../../environments/environment';
import { NodeService } from '../../shared/services/node.service';
import { MuteService } from '../../shared/services/mute.service';
import { WebApiService } from '../../shared/services/web-api.service';

/**
 * Represents the data structure for a course.
 */
interface CourseData {
  token: any;
  course_id: any;
  lesson_id?: any;
} 

/**
 *
 */
@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css'],
})
export class CertificateComponent implements OnInit {
  public url: any;
  base64Image: any;
  public dataURL: any;
  public twitterShareApi =
    'https://twitter.com/intent/tweet?text=Check out my Certificate&url=';
  public fbShareAPi = 'http://www.facebook.com/sharer.php?u=';
  public linkedInAPi = 'https://www.linkedin.com/shareArticle?mini=false&url=';
  public downloadUrl = environment.apiUrl + API_ENDPOINTS.COMMON_ENDPOINT;
  public loginToken = this.storageService.getFromSessioStorage('loginToken');
  public wsfunction = API_FUNCTIONS.DOWNLOAD_CERTIFICATE;
  public allAudioTags: any;
  public profileAudio: any;
  public certData: any = [];
  public loading = false;
  public programListData: any = []; // incoming program details from profile modal;
  public accOpenState = [];
  public filter = 'all';

  @ViewChild('myCanvas') myCanvas: ElementRef;
  @ViewChild('imgId', { static: true }) imgTag: ElementRef;
  public context: CanvasRenderingContext2D;
  /**
   *
   * @param api
   * @param bsModalRef
   * @param storageService
   * @param nodeService
   * @param muteService
   */
  constructor(
    private api: WebApiService,
    private bsModalRef: BsModalRef,
    private storageService: StorageService,
    private nodeService: NodeService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.nodeService.addNode({ modalopen: true });
    // this.muteService.muteFun();
    this.loadAudio(0);
    this.loadCertificates(this.filter);
  }

  /**
   *
   */
  public closeCertificate() {
    this.bsModalRef.hide();
  }

  /**
   *
   */
  public shareOnTwitter() {
    let twitterShareUrl =
      'https://lms.helixopp.com/twitter_share.php?img_url=' + this.url;
    twitterShareUrl = this.twitterShareApi + twitterShareUrl;
    window.open(
      twitterShareUrl,
      'twitter-popup',
      'toolbar=0,status=0,width=600,height=350',
    );
    return false;
  }

  /**
   *
   */
  public shareOnFb() {
    let facebookShareUrl =
      'https://lms.helixopp.com/facebook_share.php?img_url=' + this.url;
    facebookShareUrl = this.fbShareAPi + facebookShareUrl;
    window.open(
      facebookShareUrl,
      'sharer',
      'toolbar=0,status=0,width=600,height=350',
    );
    return false;
  }

  /**
   *
   */
  public shareOnLinkedIn() {
    let linkedInUrl = encodeURIComponent(
      'https://lms.helixopp.com/linkedin_share.php?img_url=' + this.url,
    );
    linkedInUrl = this.linkedInAPi + linkedInUrl;
    window.open(
      linkedInUrl,
      'sharer',
      'toolbar=0,status=0,width=600,height=350',
    );
    return false;
  }

  /**
   *
   * @param eleNo
   */
  loadAudio(eleNo) {
    this.profileAudio = <HTMLAudioElement>(
      document.getElementById('myProfileAudio')
    );
    /**
     * ToDo:
     */
    switch (eleNo) {
      case 0:
        this.profileAudio.src =
          '../../assets/audio/myprofile/certifiacte/Certificate_Instructions.mp3';
        break;
      case 1:
        this.profileAudio.src = '../../assets/audio/shared/Close_Button.mp3';
        break;
      case 2:
        this.profileAudio.src =
          '../../assets/audio/myprofile/certifiacte/Share_Certificate_on_LinkedIn_Button.mp3';
        break;
      case 3:
        this.profileAudio.src =
          '../../assets/audio/myprofile/certifiacte/Share_Certificate_on_Twitter.mp3';
        break;
      case 4:
        this.profileAudio.src =
          '../../assets/audio/myprofile/certifiacte/Share_Certificate_on_Facebook_Button.mp3';
        break;
      case 5:
        this.profileAudio.src =
          '../../assets/audio/myprofile/certifiacte/DownloadCertificateButton.mp3';
        break;
      default:
        this.profileAudio.src = '';
    }
  }
  /**
   *
   */
  public closeModal() {
    this.bsModalRef.hide();
    this.nodeService.addNode({ modalopen: false });
  }

  /**
   *
   */
  loadCertificates(filter) {
    this.loading = true;
    const requestDataMyLearning = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        token: this.loginToken,
        filter: filter,
      },
      params: {
        token: this.loginToken,
        function: API_FUNCTIONS.MY_COURSE,
        format: 'json',
      },
    };
    this.api.postApiCall(requestDataMyLearning).subscribe(
      (response: any) => {
        this.programListData = response.programs;
        this.programListData.forEach((program) => {
          let completedLessons = program.lessons.filter(lesson => lesson.lesson_status.lesson_progress === 100);
          if (completedLessons.length) {
            program.lessons = completedLessons;
            this.certData.push(program); 
          }
        });
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );
  }

  /**
   *
   * @param event
   * @param pId
   * @param lid
   * @param progname
   * @param type
   */
  public downloadCertificate(event, pId, lid, progname, type) {
    event.stopPropagation();
    let data: CourseData = {
      token: this.loginToken,
      course_id: pId,
    };
    
    if (type !== 'prog') {
      data.lesson_id = lid;
    }

    const requestRating = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: data,
      params: {
        token: this.loginToken,
        function: API_FUNCTIONS.PROG_CERTIFICATE,
        format: 'json',
      },
    };
    this.loading = true;
    this.api.postApiCall(requestRating).subscribe(
      (response: any) => {
        this.loading = false;
        if (!response.hasOwnProperty('certificate_url')) {
          alert(response);
        } else {
          window.open(response.certificate_url);
        }
      },
      () => {
        this.loading = false;
      },
    );
  }
}
