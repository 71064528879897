<div class="profile-container">
  <div class="profile-content" (window:resize)="onResize($event)">
    <div class="lt-content" *ngIf="mylearningsMenu">
      <div class="pro-con">
        <img class="pro-img" [src]="profileinfo.profileImage" />
        <h2>
          {{
            profileinfo.profileFirstName.length > 22
              ? (profileinfo.profileFirstName | slice: 0 : 22) + '...'
              : profileinfo.profileFirstName
          }}
          {{
            profileinfo.profileLastName.length > 22
              ? (profileinfo.profileLastName | slice: 0 : 22) + '...'
              : profileinfo.profileLastName
          }}
        </h2>
        <h4>
          {{
            profileinfo.profileEmailId.length > 35
              ? (profileinfo.profileEmailId | slice: 0 : 35) + '...'
              : profileinfo.profileEmailId
          }}
        </h4>
        <a
          [tabindex]="modalopen ? -1 : 0"
          href="javascript:void(0)"
          (focus)="loadAudio(1)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(1)"
          (mouseleave)="loadAudio(99)"
          (click)="changepasswordModalFun()"
        >
          <h5>Change Password</h5>
        </a>
        <a
          [tabindex]="modalopen ? -1 : 0"
          attr.aria-label="edit profile"
          (focus)="loadAudio(2)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(2)"
          (mouseleave)="loadAudio(99)"
          (click)="editProfilePage()"
          class="edit-profile"
          href="javascript:void(0)"
          tooltip="Edit profile"
          placement="bottom"
        >
          <img
            attr.aria-hidden="true"
            src="../../assets/img/icn-edit.svg"
            style="width: 20px"
          />
        </a>
        <a
          [tabindex]="modalopen ? -1 : 0"
          attr.aria-label="hide menu"
          (focus)="loadAudio(2)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(2)"
          (mouseleave)="loadAudio(99)"
          (click)="updateMylearningsMenuVisibility()"
          class="show-menu-close"
          href="javascript:void(0)"
          tooltip=""
          placement="bottom"
        >
          <img
            _ngcontent-c8=""
            class="ic_close"
            src="../../assets/img/close.svg"
          />
        </a>
      </div>
      <ul class="lt-menu">
        <li>
          <a
            [tabindex]="modalopen ? -1 : 0"
            (focus)="loadAudio(3)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(3)"
            (mouseleave)="loadAudio(99)"
            href="javascript:void(0);"
            [ngClass]="{ active: this.isActivelearning }"
            (click)="mylearningsTab()"
            >My Learning</a
          >
        </li>
        <li>
          <a
            [tabindex]="modalopen ? -1 : 0"
            (focus)="loadAudio(6)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(6)"
            (mouseleave)="loadAudio(99)"
            href="javascript:void(0);"
            [ngClass]="{ active: this.isActivebadges }"
            (click)="badgeTab()"
            >Badges</a
          >
        </li>
        <li>
          <a
            [tabindex]="modalopen ? -1 : 0"
            (focus)="loadAudio(7)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(7)"
            (mouseleave)="loadAudio(99)"
            href="javascript:void(0);"
            [ngClass]="{ active: this.isActivecertificates }"
            (click)="certificateTab()"
            >Certificates</a
          >
        </li>
        <li>
          <a
            [tabindex]="modalopen ? -1 : 0"
            (focus)="loadAudio(4)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(4)"
            (mouseleave)="loadAudio(99)"
            href="javascript:void(0);"
            [ngClass]="{ active: this.isActivebilling }"
            (click)="billingTab()"
            >Billing</a
          >
        </li>
      </ul>
      <div class="logout">
        <button
          [tabindex]="modalopen ? -1 : 0"
          (focus)="loadAudio(5)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(5)"
          (mouseleave)="loadAudio(99)"
          class="button btn btn-outline"
          (click)="logout()"
        >
          Logout
        </button>
      </div>
    </div>
    <div class="rt-content my-learning-panel">
      <a
        ng-model="myVar"
        [tabindex]="modalopen ? -1 : 0"
        attr.aria-label="show sidebar"
        (focus)="loadAudio(2)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(2)"
        (mouseleave)="loadAudio(99)"
        (click)="showMylearningsMenuVisibility()"
        class="show-learning-left"
        href="javascript:void(0)"
        placement="bottom"
      >
        <img
          ng-model="myVar"
          attr.aria-hidden="true"
          src="../../assets/img/hamburger.png"
          style="width: 40px"
        />
      </a>
      <app-mylearning *ngIf="mylearnings"></app-mylearning>
      <app-badges *ngIf="badges"></app-badges>
      <app-certificate *ngIf="certificate"></app-certificate>
      <app-view-bill *ngIf="billing"></app-view-bill>
    </div>
  </div>
</div>
<audio
  id="myProfileAudio"
  [src]="myProfileAudioUrl"
  autoPlay="true"
  controls
  class="hide"
></audio>

<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>
