<div class="modal-body" aria-label="Forgot password Dialogue">
  <button
    tooltip="close"
    placement="right"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalClose()"
  >
    <span aria-hidden="true">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </button>
  <h2>Forgot Password</h2>
  <form
    class="change-password"
    [formGroup]="forgotPasswordForm"
    (ngSubmit)="forgotPassword()"
  >
    <div class="form-group">
      <label for="exampleInputEmail1">Email ID</label>
      <input
        (focus)="forgotPasswordAudio(0)"
        (focusout)="forgotPasswordAudio(99)"
        (mouseleave)="forgotPasswordAudio(99)"
        (mouseenter)="forgotPasswordAudio(0)"
        type="email"
        class="form-control"
        id="CurrentPassword"
        placeholder="Enter Your Email"
        formControlName="emailId"
      />
      <div
        style="color: red"
        *ngIf="
          forgotPasswordForm.controls.emailId.invalid &&
          forgotPasswordForm.controls.emailId.touched
        "
      >
        Invalid email address
      </div>
    </div>
    <button
      (focus)="forgotPasswordAudio(1)"
      (focusout)="forgotPasswordAudio(99)"
      (mouseleave)="forgotPasswordAudio(99)"
      (mouseenter)="forgotPasswordAudio(1)"
      type="submit"
      class="btn btn-default button button--aylen"
      [disabled]="!forgotPasswordForm.valid"
    >
      Submit
    </button>
  </form>
</div>

<audio
  id="forgotAudio"
  [src]="forgotPwdAudio"
  autoPlay="true"
  controls
  class="hide"
></audio>
