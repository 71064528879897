/********************************************************************
 * Created ON:27/11/2018
 * Created by: Pallavi
 * Description: Contact Us display file.
 * Revision History:
 * Modification date:
 * Description: T
 **********************************************************************/
import { Component, OnInit } from '@angular/core';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../constant/apiEndpoint.constant';
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { WebApiService } from '../../services/web-api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { StorageService } from '../../services/storage.service';
import { NodeService } from '../../services/node.service';
import { SuccessmodalComponent } from '../successmodal/successmodal.component';
import { MODAL_CONFIG } from '../../constant/modalConfig.constant';
import { MuteService } from '../../services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit {
  public contactUsData: any = {};
  public userDetails: any = {
    first_name: '',
    last_name: '',
    email: '',
  };
  public allAudioTags: any;
  public ContactUsAudioUrl = '';
  /**
   *
   * @param api
   * @param bsModalRef
   * @param commonService
   * @param storageService
   * @param nodeService
   * @param bsModalService
   * @param muteService
   */
  constructor(
    private api: WebApiService,
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private storageService: StorageService,
    private nodeService: NodeService,
    private bsModalService: BsModalService,
    private muteService: MuteService,
  ) {}
  /**
   * Contact us form validation
   */
  public contactUsForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z ]+'),
      Validators.maxLength(100),
    ]),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(75),
    ]),
    subject: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('', [Validators.required]),
  });

  /**
   *
   */
  ngOnInit() {
    const loginToken = this.storageService.getFromSessioStorage('loginToken');
    this.ContactUsAudioUrl = '../../assets/audio/LContact_Us_Page.mp3';
    if (loginToken)
      this.userDetails = this.storageService.getFromSessioStorage('userInfo');
    this.contactUsForm.controls.name.setValue(
      this.userDetails.first_name + ' ' + this.userDetails.last_name,
    );
    this.contactUsForm.controls.email.setValue(this.userDetails.email);
    this.muteService.muteFun();
  }

  /**
   * Making API call for contact us
   */
  public contactUs() {
    const requestData = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: this.contactUsForm.value,
      params: {
        token: this.commonService.getWsToken(),
        function: API_FUNCTIONS.CONTACT_US,
        format: 'json',
      },
    };
    this.api.postApiCall(requestData).subscribe(
      (response: any) => {
        this.contactUsData = response;
        this.bsModalRef.hide();
        const initialState = {
          success_message: 'Thank you. Your message has been sent.',
          from_module: 'contact_us',
        };
        this.bsModalRef = this.bsModalService.show(
          SuccessmodalComponent,
          Object.assign({ initialState }, MODAL_CONFIG.successmodalconfig),
        );
        this.nodeService.addNode({ modalopen: true });
      },
      () => {
        // Error handling
      },
    );
  }
  /**
   *
   */
  public modalhide() {
    this.bsModalRef.hide();
    this.nodeService.addNode({ modalopen: false });
  }
  /**
   *
   * @param elementNo
   */
  public loadAudio(elementNo) {
    switch (elementNo) {
      case 0:
        this.ContactUsAudioUrl = '../../assets/audio/shared/Close_Button.mp3';
        break;
      case 1:
        this.ContactUsAudioUrl =
          '../../assets/audio/contactus/Contact_Us_Name_Field.mp3';
        break;
      case 2:
        this.ContactUsAudioUrl = '../../assets/audio/Email_Field.mp3';
        break;
      case 3:
        this.ContactUsAudioUrl =
          '../../assets/audio/contactus/Contact_Us_Subject_Field.mp3';
        break;
      case 4:
        this.ContactUsAudioUrl =
          '../../assets/audio/contactus/Contact_Us_Message_Field.mp3';
        break;
      case 5:
        this.ContactUsAudioUrl =
          '../../assets/audio/contactus/Contact_Us_Submit_Button.mp3';
        break;
      default:
        this.ContactUsAudioUrl = '';
    }
  }
}
