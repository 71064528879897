/********************************************************************
 * Created ON:27/12/2018
 * Created by: Pallavi
 * Description: Common component for loader.
 * Revision History:
 * Modification date:
 * Description: T
 **********************************************************************/
import { Component, OnInit } from '@angular/core';

/**
 *
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
  /**
   *
   */
  constructor() {}

  /**
   *
   */
  ngOnInit() {}
}
