/********************************************************************
 * Created ON:29/11/2018
 * Created by: Pallavi
 * Description: Individual program details display file.
 * Every lessons and sub-modules are fetched and displayed here
 * Revision History:
 * Modification date: 03/12/2018
 * Description: The star values of program review is made readonly
 **********************************************************************/

import { Component, OnInit, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { StorageService } from '../../shared/services/storage.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PaymentComponent } from '../payment/payment.component';
import { PlatformLocation } from '@angular/common';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { WebApiService } from '../../shared/services/web-api.service';
import { CommonService } from '../../shared/services/common.service';
import { NodeService } from '../../shared/services/node.service';
import { MODAL_CONFIG } from '../../shared/constant/modalConfig.constant';
import { Subscription } from 'rxjs';
import { MuteService } from '../../shared/services/mute.service';

import { ErrormodalComponent } from '../../shared/component/errormodal/errormodal.component';

/**
 *
 */
@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.css'],
})
export class ViewDetailsComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  public paymentModalStatusEvent: EventEmitter<any> = new EventEmitter();
  public freeModuleStatusEvent: EventEmitter<any> = new EventEmitter();
  public freeModName: any;
  public freeModUrl: any;
  public freeModuleLesson: any;
  public videoSupport = false;
  // incoming data from parent modal
  public schoolName: any;
  public programDetails: any;
  public schoolId: any;
  public openState = false;
  public loginToken: any;

  public isReadonly = true;
  public rating = 4;
  public enroll = 'Enroll';
  public continue = 'Resume';
  public start = 'Begin';
  // stores previous state of this modal
  public prevState = {
    viewdetails: false,
    sid: null,
    pid: null,
    sname: null,
  };
  public sub: Subscription;
  public paymentopen: boolean;
  public freeModuleOpen: boolean;
  public allAudioTags: any;
  public toggleBtn = false;
  public accordionIdx = -1;
  public accOpenState = [];

  public userInfo: any;

  /**
   *
   * @param modRef
   * @param storageService
   * @param modalService
   * @param commonService
   * @param api
   * @param modalRef
   * @param location
   * @param nodeService
   * @param router
   * @param muteService
   */
  constructor(
    public modRef: BsModalRef,
    private storageService: StorageService,
    private modalService: BsModalService,
    private commonService: CommonService,
    private api: WebApiService,
    private modalRef: BsModalRef,
    private location: PlatformLocation,
    private nodeService: NodeService,
    private router: Router,
    private muteService: MuteService,
  ) {
    this.location.onPopState(() => {
      this.modRef.hide();
      history.go(0);
    });
  }

  /**
   *
   */
  ngOnInit() {
    for (
      let lessonNo = 0;
      lessonNo < this.programDetails.lessons.length;
      lessonNo++
    )
      this.accOpenState[lessonNo] = false;
    this.nodeService.nodeObservable.subscribe((node) => {
      if (node['returnHome']) {
        this.nodeService.addNode({ returnPressed: true });
        this.freeModuleOpen ? this.closeFreeModule() : null;
        this.closeModal();
      }
      if (node['freeModuleClose']) {
        this.closeFreeModule();
      }
    });
    this.loginToken = this.storageService.getFromSessioStorage('loginToken');
    document.body.classList.add('view-details-open');
    /**checks if mute is active and mutes and unmutes the audio respectively */
    this.muteService.muteFun();
    this.programDetails.lessons.forEach((lesson) => {
      let counter = 0;
      lesson.modules.forEach((modContent) => {
        modContent.mod_name !== 'quiz' && counter < lesson.modules.length - 1
          ? modContent.module_progress == 100
            ? counter++
            : null
          : null;
      });
      lesson.moduleCount = lesson.modules.length - 1;
      lesson.quizCounter = counter;
    });
  }
  /**
   *
   */
  ngOnDestroy() {
    document.body.classList.remove('view-details-open');
    this.nodeService.addNode({ modalopen: false });
  }

  /**
   * navigates to classroom page that displays
   * selected program or prompts for payment
   * if not enrolled
   *
   * @param program
   */
  public programActions(program) {
    this.userInfo = this.storageService.getFromSessioStorage('userInfo'); // To Check Terms accepted or not
    if (this.userInfo != null) {
      if (program.program_payment) {
        this.prevState = {
          viewdetails: true,
          sid: this.schoolId,
          pid: this.programDetails.prog_id,
          sname: this.schoolName,
        };
        this.storageService.addToLocalStorage('prevState', this.prevState);
        let lid: any;
        let mid: any;
        for (let lIndx = 0; lIndx < program.lessons.length; lIndx++) {
          const lesson = program.lessons[lIndx];
          let foundMod = false;
          for (let mIndx = 0; mIndx < lesson.modules.length; mIndx++) {
            if (lesson.modules[mIndx].mod_name != 'quiz') {
              lid = lesson.lesson_id;
              mid = lesson.modules[mIndx].module_id;
            }
            if (lesson.modules[mIndx].module_progress != 100 && lid && mid) {
              foundMod = true;
              break;
            }
          }
          if (foundMod) break;
        }
        this.router.navigate(['/courses/classroom'], {
          queryParams: {
            pid: program.course_id,
            lid: lid,
            mid: mid,
            sid: this.schoolId,
          },
        });
        this.modRef.hide();
      } else if (program.cost != 0) {
        this.openState = true;
        this.paymentModalStatusEvent.emit({ paymentModalState: true });
        const initialState = {
          course_id: program.course_id,
          desc: program.description,
          prog_name: program.name,
          cost: program.cost,
          message: program.paypal_message,
          purchase_type: 'program',
          cid: program.programm_level_cid,
          schoolId: this.schoolId,
          from: 'view_details',
        };
        this.modalRef = this.modalService.show(
          PaymentComponent,
          Object.assign({ initialState }, MODAL_CONFIG.paymentconfig),
        );
        this.modalRef.content.closeEvent.subscribe((data) => {
          this.openState = data.paymentModalState;
          this.paymentModalStatusEvent.emit({ paymentModalState: false });
        });
      } else {
        const progReqData = {
          endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
          data: {
            token: this.loginToken
              ? this.loginToken
              : this.commonService.getWsToken(),
            course_id: program.course_id,
          },
          params: {
            token: this.loginToken
              ? this.loginToken
              : this.commonService.getWsToken(),
            function: API_FUNCTIONS.FREE_PROGRAM,
            format: 'json',
          },
        };
        this.api.postApiCall(progReqData).subscribe(
          (response: any) => {
            if(response['error_code'] === '0') {
              this.programDetails.program_payment = true;
              this.programDetails.lessons.forEach((lesson) => {
                lesson.lesson_payment = true;
              });
            } else {
              const initialState = {
                error_message: response['error_description']
              };
              this.modalRef = this.modalService.show(
                  ErrormodalComponent,
                  Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
              );
            }
          },
          () => {},
        );
      }
    } else {
      //this.router.navigate(['/terms']);
      const initialState = {
        error_message:
          '<h3>You are not enrolled in this course <b>' +
          program.name +
          '</b></h3><br/>You must sign in to your Helix Opportunity Institute account on the campus page, before viewing any content. Please register a new account if you don’t already have one.',
      };
      this.modalRef = this.modalService.show(
        ErrormodalComponent,
        Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
      );
    }
  }

  /**
   * On click of the join feature
   *
   * @param event
   */
  public makePayment = (event) => {
    event.stopPropagation();
  };

  /**
   * navigates to classroom page that displays
   * selected lesson or prompts for payment
   * if not enrolled
   *
   * @param status
   * @param lesson
   * @param x
   */
  public lessonAction(status, lesson, x) {
    this.userInfo = this.storageService.getFromSessioStorage('userInfo'); // To Check Terms accepted or not
    if (this.userInfo != null) {
      if (!status && lesson.lesson_cost != 0) {
        this.event.emit({ lessonIndx: x });
        this.openState = true;
        this.paymentModalStatusEvent.emit({ paymentModalState: true });
        console.log(this.schoolId, '------->');
        const initialState = {
          course_id: lesson.lesson_id,
          desc: lesson.description,
          prog_name: lesson.name,
          cost: lesson.lesson_cost,
          message: lesson.paypal_message,
          purchase_type: 'lesson',
          cid: lesson.lesson_level_cid,
          schoolId: this.schoolId,
          from: 'view_details',
        };
        this.modalRef = this.modalService.show(
          PaymentComponent,
          Object.assign({ initialState }, MODAL_CONFIG.paymentconfig),
        );
        this.modalRef.content.closeEvent.subscribe((data) => {
          this.openState = data.paymentModalState;
          this.paymentModalStatusEvent.emit({ paymentModalState: false });
        });
      } else if (!status) {
        const lessonsReqData = {
          endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
          data: {
            token: this.loginToken
              ? this.loginToken
              : this.commonService.getWsToken(),
            lesson_id: lesson.lesson_id,
          },
          params: {
            token: this.loginToken
              ? this.loginToken
              : this.commonService.getWsToken(),
            function: API_FUNCTIONS.FREE_LESSONS,
            format: 'json',
          },
        };
        this.api.postApiCall(lessonsReqData).subscribe(
          (response: any) => {
            if(response['error_code'] === '0') {
              this.programDetails.lessons[x].lesson_payment = true;
              this.nodeService.addNode({progData: true});
            } else {
              const initialState = {
                error_message: response['error_description']
              };
              this.modalRef = this.modalService.show(
                  ErrormodalComponent,
                  Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
              );
            }
          },
          () => {},
        );
      }
    } else {
      //this.router.navigate(['/terms']);
      const initialState = {
        error_message:
          '<h3>You are not enrolled in this lesson <b>' +
          lesson.name +
          '</b> </h3><br/>You must sign in to your Helix Opportunity Institute account on the campus page, before viewing any content. Please register a new account if you don’t already have one.',
      };
      this.modalRef = this.modalService.show(
        ErrormodalComponent,
        Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
      );
    }
  }

  /**
   * navigates to classroom page have required lessson open
   *
   * @param lid
   * @param modContent
   * @param lessonName
   */
  public moduleAction(lid, modContent) {
    if (modContent.mod_name == 'quiz') {
      this.router.navigate(['/courses/classroom'], {
        queryParams: {
          pid: this.programDetails.course_id,
          lid: lid,
          mid: modContent.module_id,
          sid: this.schoolId,
        },
      });
    }
    //Zoom comment out
    //   else if(modContent.mod_name == 'zoom'){
    //     let zoomUrl = modContent.content + '?id=' + modContent.module_id + '&token=' + this.loginToken;
    //     this.router.navigate(['/myzoom'], {
    //       queryParams: { url: encodeURIComponent(zoomUrl), progName: this.programDetails.name, lessonName: lessonName }
    //     });
    //  }
    else {
      this.router.navigate(['/courses/classroom'], {
        queryParams: {
          pid: this.programDetails.course_id,
          lid: lid,
          mid: modContent.module_id,
          sid: this.schoolId,
        },
      });
    }
    this.modRef.hide();
  }

  /**
   * Opens a modal when user wishes to access free module without logging in
   *
   * @param moduleId
   * @param moduleName
   * @param freeModule
   * @param lessonName
   * @param lessionId
   */
  public showFreeLesson(
    moduleId,
    moduleName,
    freeModule: TemplateRef<any>,
    lessonName,
    lessionId,
  ) {
    this.userInfo = this.storageService.getFromSessioStorage('userInfo'); // To Check Terms accepted or not
    if (this.userInfo == null) {
      //this.router.navigate(['/terms']);
      const initialState = {
        error_message:
          'You must sign in to your Helix Opportunity Institute account on the campus page, before viewing any content. Please register a new account if you don’t already have one.',
      };
      this.modalRef = this.modalService.show(
        ErrormodalComponent,
        Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
      );
    } else {
      this.closeModal(); /* Changes done here for classroom view */

      this.freeModuleOpen = true;
      this.freeModuleLesson = lessonName;
      this.freeModuleStatusEvent.emit({ freeModuleOpen: true });
      this.openState = true;
      /* Changes done here for classroom view */
      const queryParams = {
        pid: this.programDetails.course_id,
        lid: lessionId,
        mid: moduleId,
        sid: 0,
        types: 'free_module',
      };
      this.router.navigate(['/courses/classroom'], {
        queryParams: queryParams,
      });
      /* Changes done above  for classroom view and below code commented  */
      /*
    this.api.postApiCall(freeModReqData).subscribe(
      (response: any) => {
        this.freeModName = moduleName;
        this.freeModUrl = response.url;
        let tempUrl = '';
        let isSupp = '';
       
        if (this.freeModUrl) {
          let type = this.freeModUrl.split(/\#|\?/)[0].split('.').pop().trim();
          let vid = document.createElement('video');
          isSupp = vid.canPlayType('video/' + type);
          if (isSupp !== "" && isSupp !== null) {
            tempUrl = this.freeModUrl;
          } else {

          }
        }
        if (tempUrl !== '' && tempUrl !== null)
          this.videoSupport = false;
        else
          this.videoSupport = true;
         
        this.modalRef = this.modalService.show(freeModule, MODAL_CONFIG.freeModConfig);
      }, (error) => {

      }) 
    */
    }
  }
  /**
   *
   */
  public closeModal() {
    this.modRef.hide();
    this.nodeService.addNode({ modalopen: false });
    // this.nodeService.addNode({ returnPressed: false });
  }
  /**
   *
   */
  public closeFreeModule() {
    this.modalRef.hide();
    this.freeModuleOpen = false;
    this.freeModuleStatusEvent.emit({ freeModuleOpen: false });
    this.openState = false;
  }
  /**
   *
   */
  public focusTotoggle() {
    this.toggleBtn = true;
  }
  /**
   *
   */
  public focusOutTotoggle() {
    this.toggleBtn = false;
  }

  /**buttons audio */
  /**
   *
   * @param eleNo
   * @param programData
   * @param lessonPaymentStatus
   * @param moduleProgressStatus
   */
  public loadAudio(
    eleNo,
    programData?,
    lessonPaymentStatus?,
    moduleProgressStatus?,
  ) {
    const adminAudio = <HTMLAudioElement>document.getElementById('AdminAudio');
    if (this.schoolId == 2) {
      //common sense school
      switch (eleNo) {
        case 0:
          adminAudio.src =
            '../../assets/audio/adminbuilding/CommonSenseDesignSchoolCloseButton.mp3';
          break;
        case 1:
          if (programData.program_payment)
            if (programData.progress == 0)
              adminAudio.src =
                '../../assets/audio/shared/Start_Program_Button.mp3';
            //start
            else
              adminAudio.src =
                '../../assets/audio/adminbuilding/commonsense/Resume_Program_Button.mp3';
          //continue
          else
            adminAudio.src =
              '../../assets/audio/adminbuilding/commonsense/Common_Sense_Design_Enroll_Button.mp3'; //enroll
          break;
        case 2:
          adminAudio.src =
            '../../assets/audio/adminbuilding/commonsense/CommonSenseDesignEnrollLesson.mp3'; //join
          break;
        case 3:
          adminAudio.src =
            '../../assets/audio/adminbuilding/commonsense/Free_Common_Sense_Design_Module.mp3'; // free module start
          break;
        case 4:
          if (lessonPaymentStatus || programData.program_payment) {
            if (moduleProgressStatus == 0)
              adminAudio.src =
                '../../assets/audio/adminbuilding/commonsense/Begin_Module.mp3';
            //start
            else if (moduleProgressStatus == 100)
              adminAudio.src =
                '../../assets/audio/adminbuilding/commonsense/Restart_Module.mp3';
            //start again
            else
              adminAudio.src =
                '../../assets/audio/adminbuilding/commonsense/Resume_Module.mp3';
          }
          break;

        default:
      }
    } else if (this.schoolId == 3) {
      //business school
      switch (eleNo) {
        case 0:
          adminAudio.src =
            '../../assets/audio/adminbuilding/BusinessDevelopmentSchoolCloseButton.mp3';
          break;
        case 1:
          if (programData.program_payment)
            if (programData.progress == 0)
              adminAudio.src =
                '../../assets/audio/adminbuilding/globalinclusion/Global_Inclusion_Begin_Program_Button.mp3';
            //start
            else
              adminAudio.src =
                '../../assets/audio/adminbuilding/globalinclusion/Global_Inclusion_Resume_Program.mp3';
          //continue
          else
            adminAudio.src =
              '../../assets/audio/adminbuilding/globalinclusion/Global_Inclusion_Enroll_Button.mp3'; //enroll
          break;
        case 2:
          adminAudio.src =
            '../../assets/audio/adminbuilding/globalinclusion/BusinessDevelopmentEnrollLesson.mp3'; //join
          break;
        case 3:
          adminAudio.src =
            '../../assets/audio/adminbuilding/globalinclusion/Free_Global_Inclusion_Module .mp3'; //free module start
          break;
        case 4:
          if (lessonPaymentStatus || programData.program_payment) {
            if (moduleProgressStatus == 0)
              adminAudio.src =
                '../../assets/audio/adminbuilding/globalinclusion/Begin_Module.mp3';
            //start
            else if (moduleProgressStatus == 100)
              adminAudio.src =
                '../../assets/audio/adminbuilding/globalinclusion/Restart_Module.mp3';
            //start again
            else {
              adminAudio.src =
                '../../assets/audio/adminbuilding/globalinclusion/Resume_Module.mp3'; //resume
            }
          }
          break;
        default:
      }
    } else {
      //reception and cooperative
      switch (eleNo) {
        case 0:
          adminAudio.src = '../../assets/audio/shared/Close_Button.mp3';
          break;
        case 1:
          if (programData.program_payment)
            if (programData.progress == 0)
              adminAudio.src =
                '../../assets/audio/adminbuilding/Begin_Program_Button.mp3';
            //start
            else
              adminAudio.src =
                '../../assets/audio/adminbuilding/Cooperative_Resume_Program.mp3';
          //continue
          else
            this.schoolId == 1
              ? (adminAudio.src =
                  '../../assets/audio/adminbuilding/cooperative/CooperativeUnderstandingEnrollButton.mp3')
              : (adminAudio.src =
                  '../../assets/audio/adminbuilding/Program_Enrollment_Button.mp3'); //enroll
          break;
        case 2:
          adminAudio.src =
            '../../assets/audio/adminbuilding/Lesson_Enrollment_Button.mp3'; //join
          break;
        case 3:
          adminAudio.src =
            '../../assets/audio/adminbuilding/Free_Cooperative_Understanding_Module.mp3'; //free module start
          break;
        case 4:
          if (lessonPaymentStatus || programData.program_payment) {
            if (moduleProgressStatus == 0)
              adminAudio.src =
                '../../assets/audio/adminbuilding/Begin_Module_Button.mp3';
            //start
            else if (moduleProgressStatus == 100)
              adminAudio.src =
                '../../assets/audio/adminbuilding/Restart_Module.mp3';
            //start again
            else {
              adminAudio.src =
                '../../assets/audio/adminbuilding/Resume_Module_Button.mp3'; //resume
            }
          }
          break;
        default:
      }
    }
  }

  /**function to hold the index
   * value of selected accordion group for exapanding and minimisingg */
  /**
   *
   * @param idx
   */
  public onKeyPressed(idx) {
    if (idx == this.accordionIdx)
      this.accordionIdx = this.accordionIdx >= 0 ? -1 : idx;
    else this.accordionIdx = idx;
  }

  /**Audios for minimizing and maximising accordion  based on selected school*/
  /**
   *
   * @param lessonNo
   */
  public accordionExpandAudio(lessonNo) {
    const adminAudio = <HTMLAudioElement>document.getElementById('AdminAudio');
    switch (this.schoolId) {
      case 2:
        if (this.accOpenState[lessonNo] == true)
          adminAudio.src =
            '../../assets/audio/shared/Common_Sense_Design_School_Minimize_Lesson.mp3';
        else
          adminAudio.src =
            '../../assets/audio/shared/Common_Sense_Design_School_Expand_Lesson.mp3';
        break;
      case 3:
        if (this.accOpenState[lessonNo] == true)
          adminAudio.src =
            '../../assets/audio/shared/Business_Develpment_School_Minimize_Lesson.mp3';
        else
          adminAudio.src =
            '../../assets/audio/shared/Business_Development_School_Expand_Lesson.mp3';
        break;
      default:
        if (this.accOpenState[lessonNo] == true)
          adminAudio.src =
            '../../assets/audio/adminbuilding/cooperative/CooperativeUnderstandingMinimizeLesson.mp3';
        else
          adminAudio.src =
            '../../assets/audio/adminbuilding/cooperative/CooperativeUnderstandingExpandLesson.mp3';
    }
  }
  /**
   *
   * @param status
   * @param lessonNo
   */
  public accdChange(status, lessonNo) {
    this.accOpenState[lessonNo] = status;
  }
  /**
   *
   * @param cname
   */
  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}
