<div class="modal-body">
  <a
    href="javascript:void(0)"
    (focus)="loadAudio(0)"
    (focusout)="loadAudio(99)"
    (mouseenter)="loadAudio(0)"
    (mouseleave)="loadAudio(99)"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeModal()"
    tooltip="close"
    placement="bottom"
  >
    <span aria-hidden="true" class="btn-close">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <h2>Edit Profile</h2>
  <form [formGroup]="editProfileForm" (ngSubmit)="successprofileModal()">
    <div class="row">
      <div class="col-xs-12">
        <div class="proile-picture">
          <img [src]="profile_pic" width="105" />
          <div class="change-picture">
            <div class="input-file-container">
              <input
                (focus)="loadAudio(1)"
                (focusout)="loadAudio(99)"
                class="input-file"
                id="my-file"
                type="file"
                (change)="fileChange($event.target.files)"
                accept="image/x-png"
                formControlName="imageFormData"
                [tabindex]="0"
              />
              <label
                (mouseenter)="loadAudio(1)"
                (mouseleave)="loadAudio(99)"
                for="my-file"
                class="input-file-trigger"
                >Change</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <label for="FirstName">First Name</label>
          <input
            (focus)="loadAudio(2)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(2)"
            (mouseleave)="loadAudio(99)"
            type="text"
            class="form-control"
            id="FirstName"
            placeholder="First Name"
            formControlName="firstName"
          />
          <span
            *ngIf="
              editProfileForm.controls.firstName.errors
                ? editProfileForm.controls.firstName.errors.maxlength
                : false;
              else requiredFirstName
            "
          >
            Maximum length exceeded
          </span>
          <ng-template #requiredFirstName>
            <span
              *ngIf="
                editProfileForm.controls.firstName.errors
                  ? editProfileForm.controls.firstName.errors.required &&
                    editProfileForm.controls.firstName.touched
                  : false;
                else invalidFirstName
              "
            >
              This field is required
            </span>
            <ng-template #invalidFirstName>
              <span
                *ngIf="
                  editProfileForm.controls.firstName.invalid &&
                  editProfileForm.controls.firstName.touched
                "
                >Invalid First name</span
              >
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="form-group">
          <label for="LastName">Last Name</label>
          <input
            (focus)="loadAudio(3)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(3)"
            (mouseleave)="loadAudio(99)"
            type="text"
            class="form-control"
            id="LastName"
            placeholder="Last Name"
            formControlName="lastName"
          />
          <span
            *ngIf="
              editProfileForm.controls.lastName.errors
                ? editProfileForm.controls.lastName.errors.maxlength
                : false;
              else invalidLastName
            "
          >
            Maximum length exceeded
          </span>
          <ng-template #invalidLastName>
            <span
              *ngIf="
                editProfileForm.controls.lastName.invalid &&
                editProfileForm.controls.lastName.touched
              "
              >Invalid Last name</span
            >
          </ng-template>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Email ID</label>
      <input
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        placeholder="Email"
        formControlName="emailId"
        disabled
      />
      <span
        *ngIf="
          editProfileForm.controls.emailId.invalid &&
          editProfileForm.controls.emailId.touched
        "
        >Invalid email address</span
      >
    </div>
    <button
      (focus)="loadAudio(4)"
      (focusout)="loadAudio(99)"
      (mouseenter)="loadAudio(4)"
      (mouseleave)="loadAudio(99)"
      type="submit"
      class="btn btn-default button button--aylen"
      [disabled]="editProfileForm.touched && editProfileForm.invalid"
    >
      Save
    </button>
  </form>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }">
  </ngx-loading>
</div>
