<div class="modal-body terms_of_use">
  <a
    tooltip="close"
    placememt="left"
    href="javascript:void(0)"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalClose()"
  >
    <span aria-hidden="true" class="btn-close">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <h4 class="heading">Posted: September 23, 2020</h4>
  <h2 class="heading" style="text-align: center">
    HELIX OPPORTUNITY INSTITUTE TERMS OF SERVICE
  </h2>
  <p class="term_p">
    <!--It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content
    here, content here', making it look like readable English. Many desktop publishing packages and web page editors now
    use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their
    infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and
    the like).   -->
    <b
      >IMPORTANT: THIS AGREEMENT IS A LEGALLY BINDING AGREEMENT BETWEEN YOU AND
      HELIX OPPORTUNITY, LLC APPLICABLE TO YOUR PURCHASE AND/OR USE OF HELIX
      OPPORTUNITY’S SAAS PRODUCTS AND/OR HELIX OPPORTUNITY’S TRAINING SYSTEM,
      THE HELIX OPPORTUNITY INSTITUTE. BY CLICKING ON "I AGREE", YOU AGREE TO BE
      BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY
      THE TERMS OF THIS AGREEMENT, CLICK ON THE "CANCEL" BUTTON AND YOU MAY NOT
      USE ANY HELIX OPPORTUNITY SAAS PRODUCT NOR ACCESS THE SYSTEM.</b
    >
    <br /><br />

    These Terms of Service constitute an agreement (this “Agreement”) by and
    between Helix Opportunity, LLC, a California Company whose principal place
    of business is 833 Market St. #421, San Francisco, CA 94103 (“Vendor”) and
    the individual, corporation, LLC, partnership, sole proprietorship, or other
    business entity executing this Agreement (“Customer”). This Agreement is
    effective as of the date Customer clicks “Accepted and Agreed To” (the
    “Effective Date”). Customer’s use of and Vendor’s provision of Vendor’s
    System (as defined below in Section 1.6) are governed by this Agreement.<br /><br />

    <b
      >1.______<label style="text-decoration: underline">DEFINITIONS</label>.</b
    >
    The following capitalized terms will have the following meanings whenever
    used in this Agreement.<br /><br /><br />

    1.1 <span style="text-decoration: underline">“Customer Data”</span> means
    data in electronic form input or collected through the System by or from
    Customer, including without limitation by Customer’s Users.<br /><br />
    1.2 <span style="text-decoration: underline">“Documentation”</span> means
    Vendor's materials and/or manuals and instructions provided by Vendor which
    are related to use of its System and/or SaaS products, as the same may be
    amended, replaced and/or supplemented from time to time. <br /><br />
    1.3 <span style="text-decoration: underline">"Order" </span> means an order
    for a SaaS product and/or access to the System, for the stated time period
    identified when the purchase was made or access was requested. Vendor
    reserves the right to reject any Order at any time for any reason
    whatsoever.<br /><br />
    1.4 <span style="text-decoration: underline">“Privacy Policy”</span> means
    Vendor’s privacy policy, currently posted at
    http://helixopp.institute/privacy. <br /><br />
    1.5 <span style="text-decoration: underline">"System"</span> means,
    generally, the computer servers, Vendor programs, and methods by which
    Vendor provides SaaS products to its Customers.<br /><br />
    1.6 <span style="text-decoration: underline">"SLA"</span> means the relevant
    Vendor standard service level agreement(s) for its products and services, to
    the extent the same are offered and/or published. SLA’s are currently posted
    at http://helixopp.institute/sla. <br /><br />
    1.7 <span style="text-decoration: underline">"Term</span> is defined in
    Section 11.1 below.<br /><br />
    1.8 <span style="text-decoration: underline">"User"</span> means any
    individual who uses the System on Customer’s behalf or through Customer’s
    account or passwords, whether authorized or not.<br /><br />
    <b
      >2.______<label style="text-decoration: underline"> THE SYSTEM. </label
      >.</b
    ><br /><br />

    2.1
    <span style="text-decoration: underline">Use of the System.</span> During
    the Term, Customer may access and use the System pursuant to the terms of
    any outstanding Order, including such features and functions as the Order
    requires.<br /><br />
    2.2 <span style="text-decoration: underline">Service Levels.</span> Vendor
    shall provide the remedies listed in the SLA for any failure of the System
    listed in the SLA. Such remedies are Customer’s sole remedy for any failure
    of the System, and Customer recognizes and agrees that if the SLA does not
    list a remedy for a given failure, it has no remedy.<br /><br />
    2.3 <span style="text-decoration: underline">Documentation:</span> Customer
    may reproduce and use the Documentation solely as necessary to support
    Users’ use of the System. <br /><br />
    2.4 <span style="text-decoration: underline">System Revisions.</span> Vendor
    may revise System features and functions or the SLA at any time, including
    without limitation by removing such features and functions or reducing
    service levels. If any such revision to the System materially reduces
    features or functionality provided pursuant to an Order, Customer may within
    30 days of notice of the revision terminate such Order, without cause, or
    terminate this Agreement without cause if such Order is the only one
    outstanding. If any such revision to the SLA materially reduces service
    levels provided pursuant to an outstanding Order, the revisions will not go
    into effect with respect to such Order until the start of the Term beginning
    45 or more days after Vendor posts the revision and so informs Customer.<br /><br />

    <b>3.______<label style="text-decoration: underline"> PAYMENT. </label>.</b
    ><br /><br />
    3.1
    <span style="text-decoration: underline">Subscription Fees. </span> Customer
    shall pay Vendor the fee set forth in each Order (the “Subscription Fee”)
    for each Term. Payment of the Subscription Fee will entitle you to access to
    the SaaS product or service ordered only for the time period identified at
    the time of your Order. Vendor will not be required to refund the
    Subscription Fee under any circumstances.<br /><br />

    3.2 <span style="text-decoration: underline"> Taxes. </span> Amounts due
    under this Agreement are payable to Vendor without deduction and are net of
    any tax, tariff, duty, or assessment imposed by any government authority
    (national, state, provincial, or local), including without limitation any
    sales, use, excise, ad valorem, property, withholding, or value added tax
    withheld at the source. If applicable law requires withholding or deduction
    of such taxes or duties, Customer shall separately pay Vendor the withheld
    or deducted amount. However, the prior two sentences do not apply to taxes
    based on Vendor’s net income.<br /><br />

    <b
      >4.______<label style="text-decoration: underline">
        CUSTOMER DATA & PRIVACY. </label
      >.</b
    ><br /><br />

    4.1
    <span style="text-decoration: underline"> Use of Customer Data. </span>
    Unless it receives Customer’s prior written consent, Vendor: (a) shall not
    access, process, or otherwise use Customer Data other than as necessary to
    facilitate the System; and (b) shall not intentionally grant any third party
    access to Customer Data, including without limitation Vendor’s other
    customers, except subcontractors that are subject to a reasonable
    nondisclosure agreement. Notwithstanding the foregoing, Vendor may disclose
    Customer Data as required by applicable law or by proper legal or
    governmental authority. Vendor shall endeavor to give Customer prompt notice
    of any such legal or governmental demand and reasonably cooperate with
    Customer in any effort to seek a protective order or otherwise to contest
    such required disclosure, at Customer’s expense. As between the parties,
    Customer retains ownership of Customer Data.<br /><br />

    4.2 <span style="text-decoration: underline"> Privacy Policy. </span> The
    Privacy Policy applies only to the System and does not apply to any third
    party website or service linked to the System or recommended or referred to
    through the System or by Vendor’s staff. <br /><br />

    4.3
    <span style="text-decoration: underline"> Risk of Exposure. </span> Customer
    recognizes and agrees that hosting data online involves risks of
    unauthorized disclosure or exposure and that, in accessing and using the
    System, Customer assumes such risks. Vendor offers no representation,
    warranty, or guarantee that Customer Data will not be exposed or disclosed
    through errors or the actions of third parties.<br /><br />

    4.4 <span style="text-decoration: underline"> Data Accuracy. </span> Vendor
    will have no responsibility or liability for the accuracy of data uploaded
    to the System by Customer, including without limitation Customer Data and
    any other data uploaded by Users.<br /><br />

    4.5 <span style="text-decoration: underline"> Data Deletion. </span> Vendor
    may permanently erase Customer Data if Customer’s account is delinquent,
    suspended, or terminated. Vendor shall have no obligation to maintain any
    Customer Data except during the Term (as such obligation is otherwise
    limited in this Agreement). Customer agrees and acknowledges that Vendor has
    no obligation to retain Customer Data and that Customer Data may be
    irretrievably deleted at the end of the Term.<br /><br />

    4.6 <span style="text-decoration: underline">Excluded Data. </span> Customer
    represents and warrants that Customer Data does not and will not include,
    and Customer has not and shall not upload or transmit to Vendor's computers
    or other media, any data (“<span style="text-decoration: underline"
      >Excluded Data</span
    >”) regulated pursuant to any law, rule, order or regulation of any
    governmental entity having jurisdiction over such data (collectively, the
    "<span style="text-decoration: underline">Excluded Data Laws</span>").
    CUSTOMER RECOGNIZES AND AGREES THAT: (a) VENDOR HAS NO LIABILITY FOR ANY
    FAILURE TO PROVIDE PROTECTIONS SET FORTH IN THE EXCLUDED DATA LAWS OR
    OTHERWISE TO PROTECT EXCLUDED DATA; AND (b) VENDOR’S SYSTEMS ARE NOT
    INTENDED FOR MANAGEMENT OR PROTECTION OF EXCLUDED DATA AND MAY NOT PROVIDE
    ADEQUATE OR LEGALLY REQUIRED SECURITY FOR EXCLUDED DATA.<br /><br />

    4.7
    <span style="text-decoration: underline">
      Aggregate & Anonymized Data.
    </span>
    Notwithstanding the provisions above of this Article 4, Vendor may use,
    reproduce, sell, publicize, or otherwise exploit Aggregate Data in any way,
    in its sole discretion. (“Aggregate Data” refers to Customer Data with the
    following removed: personally identifiable information and the names and
    addresses of Customer and any of its Users or customers.) <br /><br />
    4.8 <span style="text-decoration: underline"> Audit. </span> Vendor may
    audit Customer’s use of the System. Customer agrees to cooperate with
    Vendor’s audit and provide reasonable assistance and access to information.
    Any such audit shall not unreasonably interfere with Customer’s use of the
    System.<br /><br />
    <b
      >5.______<label style="text-decoration: underline">
        CUSTOMER’S RESPONSIBILITIES & RESTRICTIONS. </label
      >.</b
    ><br /><br />

    5.1
    <span style="text-decoration: underline"> Acceptable Use. </span> Customer
    shall not: (a) use the System for service bureau or time-sharing purposes or
    in any other way allow third parties to exploit the System; (b) provide
    System passwords or other log-in information to any third party; (c) share
    non-public System features or content with any third party; (d) access the
    System in order to build a competitive product or service, to build a
    product using similar ideas, features, functions or graphics of the System,
    or to copy any ideas, features, functions or graphics of the System; or (e)
    engage in web scraping or data scraping on or related to the System,
    including without limitation collection of information through any software
    that simulates human activity or any bot or web crawler. In the event that
    it suspects any breach of the requirements of this Section 5.1, including
    without limitation by Users, Vendor may suspend Customer’s access to the
    System without advanced notice, in addition to such other remedies as Vendor
    may have. This Agreement does not require that Vendor take any action
    against Customer or any User or other third party for violating this Section
    5.1, or this Agreement, but Vendor is free to take any such action it sees
    fit.<br /><br />
    5.2
    <span style="text-decoration: underline"> Unauthorized Access. </span>
    Customer shall take reasonable steps to prevent unauthorized access to the
    System, including without limitation by protecting its passwords and other
    log-in information. Customer shall notify Vendor immediately of any known or
    suspected unauthorized use of the System or breach of its security and shall
    use best efforts to stop said breach.<br /><br />
    5.3
    <span style="text-decoration: underline">Compliance with Laws. </span> In
    its use of the System, Customer shall comply with all applicable laws,
    including without limitation laws governing the protection of personally
    identifiable information and other laws applicable to the protection of
    Customer Data. <br /><br />
    5.4
    <span style="text-decoration: underline"> Users & System Access. </span>
    Customer is responsible and liable for: (a) Users’ use of the System,
    including without limitation unauthorized User conduct and any User conduct
    that would violate Section 5.1 or the requirements of this Agreement
    applicable to Customer; and (b) any use of the System through Customer’s
    account, whether authorized or unauthorized. <br /><br />

    <b
      >6.______<label style="text-decoration: underline">
        Intellectual Property (“IP”) Rights & FEEDBACK. </label
      >.</b
    ><br /><br />
    6.1
    <span style="text-decoration: underline"> IP Rights to the System. </span>
    Vendor retains all right, title, and interest in and to the System,
    including without limitation all software used to provide the System and all
    graphics, user interfaces, logos, and trademarks reproduced through the
    System. This Agreement does not grant Customer any intellectual property
    license or rights in or to the System or any of its components. Customer
    recognizes that the System and its components are protected by copyright and
    other laws.<br /><br />
    6.2 <span style="text-decoration: underline"> Feedback. </span> Vendor has
    not agreed to and does not agree to treat as confidential any Feedback (as
    defined below) Customer or Users provide to Vendor, and nothing in this
    Agreement or in the parties’ dealings arising out of or related to this
    Agreement will restrict Vendor’s right to use, profit from, disclose,
    publish, keep secret, or otherwise exploit Feedback, without compensating or
    crediting Customer or the User in question. Notwithstanding the provisions
    of Article 7 below, Feedback will not be considered Confidential
    Information. (<span style="text-decoration: underline"> Feedback </span>
    refers to any suggestion or idea for improving or otherwise modifying any of
    Vendor’s products or services.) <br /><br />

    <b
      >7.______<label style="text-decoration: underline">
        CONFIDENTIAL INFORMATION.
      </label></b
    >
    <span style="text-decoration: underline">“Confidential Information”</span>
    refers to the following items Vendor discloses to Customer: (a) any document
    Vendor marks “Confidential”; (b) any information Vendor orally designates as
    “Confidential” at the time of disclosure, provided Vendor confirms such
    designation in writing within thirty (30) business days; (c) the
    Documentation, whether or not marked or designated confidential; and (d) any
    other nonpublic, sensitive information Customer should reasonably consider a
    trade secret or otherwise confidential. Notwithstanding the foregoing,
    Confidential Information does not include information that: (i) is in
    Customer’s possession at the time of disclosure; (ii) is independently
    developed by Customer without use of or reference to Confidential
    Information; (iii) becomes known publicly, before or after disclosure, other
    than as a result of Customer’s improper action or inaction; or (iv) is
    approved for release in writing by Customer. <br /><br />
    7.1 <span style="text-decoration: underline">Nondisclosure. </span> Customer
    shall not use Confidential Information for any purpose other than the to
    facilitate the transactions contemplated by this Agreement (the “Purpose”).
    Customer: (a) shall not disclose Confidential Information to any employee or
    contractor of Customer unless such person needs access in order to
    facilitate the Purpose and executes a nondisclosure agreement with Recipient
    with terms no less restrictive than those of this Article 7; and (b) shall
    not disclose Confidential Information to any other third party without
    Vendor’s prior written consent. Without limiting the generality of the
    foregoing, Customer shall protect Confidential Information with the same
    degree of care it uses to protect its own confidential information of
    similar nature and importance, but with no less than reasonable care.
    Customer shall promptly notify Vendor of any misuse or misappropriation of
    Confidential Information that comes to Customer’s attention. Notwithstanding
    the foregoing, Customer may disclose Confidential Information as required by
    applicable law or by proper legal or governmental authority. Customer shall
    give Vendor prompt notice of any such legal or governmental demand and
    reasonably cooperate with Vendor in any effort to seek a protective order or
    otherwise to contest such required disclosure, at Vendor’s expense.<br /><br />
    7.2 <span style="text-decoration: underline">Injunction. </span> Customer
    agrees that breach of this Article 7 would cause Vendor irreparable injury,
    for which monetary damages would not provide adequate compensation, and that
    in addition to any other remedy, Vendor will be entitled to injunctive
    relief against such breach or threatened breach, without proving actual
    damage or posting a bond or other security. <br /><br />
    7.3
    <span style="text-decoration: underline"> Termination & Return. </span> Upon
    termination of this Agreement, Customer shall return all copies of
    Confidential Information to Vendor or certify, in writing, the destruction
    thereof.<br /><br />
    7.4
    <span style="text-decoration: underline">Retention of Rights. </span> This
    Agreement does not transfer ownership of Confidential Information or grant a
    license thereto. Vendor will retain all right, title, and interest in and to
    all Confidential Information. <br /><br />
    <b
      >8.______<label style="text-decoration: underline">
        REPRESENTATIONS & WARRANTIES. </label
      >.</b
    ><br /><br />
    8.1 <span style="text-decoration: underline"> From Vendor. </span> Vendor
    represents and warrants that it is the owner of the System and of each and
    every component thereof, or the recipient of a valid license thereto, and
    that it has and will maintain the full power and authority to grant the
    rights to use the System set forth in this Agreement without the further
    consent of any third party. Vendor’s representations and warranties in the
    preceding sentence do not apply to use of the System in combination with
    hardware or software not provided by Vendor. In the event of a breach of the
    warranty in this Section 8.1, Vendor, at its own expense, shall promptly
    take the following actions: (a) secure for Customer the right to continue
    using the System; (b) replace or modify the System to make it noninfringing;
    or (c) terminate the infringing features of the Service and refund to
    Customer any prepaid fees for such features, in proportion to the portion of
    the Term left after such termination. In conjunction with Customer’s right
    to terminate for breach where applicable, the preceding sentence states
    Vendor’s sole obligation and liability, and Customer’s sole remedy, for
    breach of the warranty in this Section 8.1 and for potential or actual
    intellectual property infringement by the System.<br /><br />
    8.2
    <span style="text-decoration: underline"> From Customer. </span> Customer
    represents and warrants that: (a) it has the full right and authority to
    enter into, execute, and perform its obligations under this Agreement and
    that no pending or threatened claim or litigation known to it would have a
    material adverse impact on its ability to perform as required by this
    Agreement; (b) it has accurately identified itself and it has not provided
    any inaccurate information about itself to or through the System; and (c) it
    is a corporation, the sole proprietorship of an individual 18 years or
    older, or another entity authorized to do business pursuant to applicable
    law.<br /><br />
    8.3
    <span style="text-decoration: underline"> Warranty Disclaimers. </span>
    Except to the extent set forth in the SLA and in Section 8.1 above, CUSTOMER
    ACCEPTS THE SYSTEM “AS IS” AND AS AVAILABLE, WITH NO REPRESENTATION OR
    WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
    NONINFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR ANY IMPLIED WARRANTY
    ARISING FROM STATUTE, COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF
    TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING: (a) VENDOR HAS NO
    OBLIGATION TO INDEMNIFY OR DEFEND CUSTOMER OR USERS AGAINST CLAIMS RELATED
    TO INFRINGEMENT OF INTELLECTUAL PROPERTY; (b) VENDOR DOES NOT REPRESENT OR
    WARRANT THAT THE SYSTEM WILL PERFORM WITHOUT INTERRUPTION OR ERROR; AND (c)
    VENDOR DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM IS SECURE FROM HACKING
    OR OTHER UNAUTHORIZED INTRUSION OR THAT CUSTOMER DATA WILL REMAIN PRIVATE OR
    SECURE.<br /><br />

    <b
      >9.______<label style="text-decoration: underline">
        INDEMNIFICATION. </label
      >.</b
    >Customer shall defend, indemnify, and hold harmless Vendor and the Vendor
    Associates (as defined below) against any
    <span style="text-decoration: underline">“Indemnified Claim,”</span> meaning
    any third party claim, suit, or proceeding arising out of or related to
    Customer's alleged or actual use of, misuse of, or failure to use the
    System, including without limitation: (a) claims by Users or by Customer's
    employees, as well as by Customer’s own customers; (b) claims related to
    unauthorized disclosure or exposure of personally identifiable information
    or other private information, including Customer Data; (c) claims related to
    infringement or violation of a copyright, trademark, trade secret, or
    privacy or confidentiality right by written material, images, logos or other
    content uploaded to the System through Customer’s account, including without
    limitation by Customer Data; and (d) claims that use of the System through
    Customer’s account harasses, defames, or defrauds a third party or violates
    the CAN-Spam Act of 2003 or any other law or restriction on electronic
    advertising. Indemnified Claims include, without limitation, claims arising
    out of or related to Vendor’s negligence. Customer’s obligations set forth
    in this Article 9 include retention and payment of attorneys and payment of
    court costs, as well as settlement at Customer’s expense and payment of
    judgments. Vendor will have the right, not to be exercised unreasonably, to
    reject any settlement or compromise that requires that it admit wrongdoing
    or liability or subjects it to any ongoing affirmative obligations. (The
    “<span style="text-decoration: underline">Vendor Associates</span>” are
    Vendor’s officers, directors, shareholders, parents, subsidiaries, agents,
    successors, and assigns.) <br /><br />
    <b
      >10.______<label style="text-decoration: underline">
        REPRESENTATIONS & WARRANTIES. </label
      >.</b
    ><br /><br />
    10.1 <span style="text-decoration: underline"> Dollar Cap. </span> VENDOR’S
    CUMULATIVE LIABILITY FOR ALL CLAIMS ARISING OUT OF OR RELATED TO THIS
    AGREEMENT WILL NOT EXCEED THE FEES PAID BY CUSTOMER TO ACCESS THE SYSTEM FOR
    A TWELVE (12) MONTH PERIOD.<br /><br />
    10.2 <span style="text-decoration: underline"> Excluded Damages. </span> IN
    NO EVENT WILL VENDOR BE LIABLE TO CUSTOMER FOR LOST PROFITS OR LOSS OF
    BUSINESS OR FOR ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR
    PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT. <br /><br />
    10.3
    <span style="text-decoration: underline"
      >Clarifications & Disclaimers.
    </span>
    THE LIABILITIES LIMITED BY THIS ARTICLE 10 APPLY: (a) TO LIABILITY FOR
    NEGLIGENCE; (b) REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT,
    STRICT PRODUCT LIABILITY, OR OTHERWISE; (c) EVEN IF VENDOR IS ADVISED IN
    ADVANCE OF THE POSSIBILITY OF THE DAMAGES IN QUESTION AND EVEN IF SUCH
    DAMAGES WERE FORESEEABLE; AND (d) EVEN IF CUSTOMER’S REMEDIES FAIL OF THEIR
    ESSENTIAL PURPOSE. If applicable law limits the application of the
    provisions of this Article 10, Vendor’s liability will be limited to the
    maximum extent permissible. For the avoidance of doubt, Vendor’s liability
    limits and other rights set forth in this Article 10 apply likewise to
    Vendor’s affiliates, licensors, suppliers, advertisers, agents, sponsors,
    directors, officers, employees, consultants, and other representatives.
    <br /><br />

    <b
      >11.______<label style="text-decoration: underline">
        Term & Termination. </label
      >.</b
    ><br /><br />
    11.1 <span style="text-decoration: underline"> Term.</span> The term of this
    Agreement (the <span style="text-decoration: underline"> Term</span>) will
    commence on the Effective Date and continue for the period set forth in the
    Order or, if none, for one (1) year. Thereafter, the Term will renew for
    successive one (1) year periods, unless either party refuses such renewal by
    written notice 30 or more days before the renewal date. <br /><br />
    11.2
    <span style="text-decoration: underline"> Termination for Cause. </span>
    Either party may terminate this Agreement for the other’s material breach by
    written notice specifying in detail the nature of the breach, effective in
    30 days unless the other party first cures such breach, or effective
    immediately if the breach is not subject to cure. <br /><br />
    11.3
    <span style="text-decoration: underline"> Effects of Termination. </span>
    Upon termination of this Agreement, Customer shall cease all use of the
    System and delete, destroy, or return all copies of the Documentation in its
    possession or control. The following provisions will survive termination or
    expiration of this Agreement: (a) any obligation of Customer to pay fees
    incurred before termination; (b) Articles and Sections 6 (IP & Feedback), 7
    (Confidential Information), 8.3 (Warranty Disclaimers), 9 (Indemnification),
    and 10 (Limitation of Liability); and (c) any other provision of this
    Agreement that must survive to fulfill its essential purpose.<br /><br />

    <b
      >12.______<label style="text-decoration: underline">
        MISCELLANEOUS. </label
      >.</b
    ><br /><br />
    12.1
    <span style="text-decoration: underline"> Independent Contractors. </span>
    The parties are independent contractors and shall so represent themselves in
    all regards. Neither party is the agent of the other, and neither may make
    commitments on the other’s behalf.<br /><br />
    12.2 <span style="text-decoration: underline"> Notices. </span> Vendor may
    send notices pursuant to this Agreement to Customer’s email contact points
    provided by Customer, and such notices will be deemed received 24 hours
    after they are sent. Customer may send notices pursuant to this Agreement to
    dfazio@helixopp.com, and such notices will be deemed received 72 hours after
    they are sent.<br /><br />
    12.3 <span style="text-decoration: underline"> Force Majeure.</span> No
    delay, failure, or default, other than a failure to pay fees when due, will
    constitute a breach of this Agreement to the extent caused by acts of war,
    terrorism, hurricanes, earthquakes, other acts of God or of nature, strikes
    or other labor disputes, riots or other acts of civil disorder, embargoes,
    or other causes beyond the performing party’s reasonable control, whether
    similar or dissimilar to the foregoing.<br /><br />
    12.4
    <span style="text-decoration: underline"> Assignment & Successors.</span>
    Customer may not assign this Agreement or any of its rights or obligations
    hereunder without Vendor’s express written consent. Except to the extent
    forbidden in this Section 12.4, this Agreement will be binding upon and
    inure to the benefit of the parties’ respective successors and assigns.<br /><br />
    12.5 <span style="text-decoration: underline"> Severability. </span> To the
    extent permitted by applicable law, the parties hereby waive any provision
    of law that would render any clause of this Agreement invalid or otherwise
    unenforceable in any respect. In the event that a provision of this
    Agreement is held to be invalid or otherwise unenforceable, such provision
    will be interpreted to fulfill its intended purpose to the maximum extent
    permitted by applicable law, and the remaining provisions of this Agreement
    will continue in full force and effect. <br /><br />
    12.6 <span style="text-decoration: underline"> No Waiver. </span> Neither
    party will be deemed to have waived any of its rights under this Agreement
    by lapse of time or by any statement or representation other than by an
    authorized representative in an explicit written waiver. No waiver of a
    breach of this Agreement will constitute a waiver of any other breach of
    this Agreement.<br /><br />
    12.7
    <span style="text-decoration: underline">
      Choice of Law & Jurisdiction: </span
    >This Agreement and all claims arising out of or related to this Agreement
    will be governed solely by the internal laws of the State of California
    including without limitation applicable federal law, without reference to:
    (a) any conflicts of law principle that would apply the substantive laws of
    another jurisdiction to the parties’ rights or duties; (b) the 1980 United
    Nations Convention on Contracts for the International Sale of Goods; or (c)
    other international laws. The parties consent to the personal and exclusive
    jurisdiction of the federal and state courts of San Francisco, California.
    This Section 12.7 governs all claims arising out of or related to this
    Agreement, including without limitation tort claims. <br /><br />
    12.8 <span style="text-decoration: underline"> Conflicts.</span> In the
    event of any conflict between this Agreement and any Vendor policy posted
    online, including without limitation the Privacy Policy, the terms of this
    Agreement will govern.<br /><br />
    12.9
    <span style="text-decoration: underline"> Technology Export.</span> Customer
    shall not: (a) permit any third party to access or use the System in
    violation of any U.S. law or regulation; or (b) export any software provided
    by Vendor or otherwise remove it from the United States except in compliance
    with all applicable U.S. laws and regulations. Without limiting the
    generality of the foregoing, Customer shall not permit any third party to
    access or use the System in, or export such software to, a country subject
    to a United States embargo (as of the Effective Date, Cuba, Iran, North
    Korea, Sudan, and Syria). <br /><br />
    12.10
    <span style="text-decoration: underline"> Entire Agreement.</span> This
    Agreement sets forth the entire agreement of the parties and supersedes all
    prior or contemporaneous writings, negotiations, and discussions with
    respect to its subject matter. Neither party has relied upon any such prior
    or contemporaneous communications.<br /><br />
    12.11 <span style="text-decoration: underline"> Amendment. </span> Vendor
    may amend this Agreement from time to time by posting an amended version at
    its Website and sending Customer written notice thereof. Such amendment will
    be deemed accepted and become effective 30 days after such notice (the
    “<span style="text-decoration: underline">Proposed Amendment Date</span>”)
    unless Customer first gives Vendor written notice of rejection of the
    amendment. In the event of such rejection, this Agreement will continue
    under its original provisions, and the amendment will become effective at
    the start of Customer’s next Term following the Proposed Amendment Date
    (unless Customer first terminates this Agreement pursuant to Article 11,
    Term & Termination). Customer’s continued use of the Service following the
    effective date of an amendment will confirm Customer’s consent thereto. This
    Agreement may not be amended in any other way except through a written
    agreement by authorized representatives of each party. Vendor may revise the
    Privacy Policy and Acceptable Use Policy at any time by posting a new
    version of either at the Website, and such new version will become effective
    on the date it is posted; provided if such amendment materially reduces
    Customer’s rights or protections, notice and consent will be subject to the
    requirements above in this Section 12.12.<br /><br />

    <b
      >THIS AGREEMENT IS A LEGALLY BINDING AGREEMENT BETWEEN YOU AND HELIX
      OPPORTUNITY, LLC APPLICABLE TO YOUR PURCHASE AND/OR USE OF HELIX
      OPPORTUNITY’S SAAS PRODUCTS AND/OR HELIX OPPORTUNITY’S SYSTEM. BY CLICKING
      ON "I AGREE", YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU
      DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, CLICK ON THE
      "CANCEL" BUTTON AND YOU MAY NOT USE ANY HELIX OPPORTUNITY SAAS PRODUCT NOR
      ACCESS THE SYSTEM
    </b>
    <br /><br />
    <span class="buttons">
      <span class="action_btn">
        <button
          name="submit"
          class="action_btn submit"
          type="submit"
          value="Save"
          (click)="agreeButton()"
        >
          I Agree
        </button>

        <button
          name="submit"
          class="action_btn cancel"
          type="submit"
          value="Cancel"
          (click)="cancelButton()"
        >
          Cancel
        </button>

        <span id="saved"></span>
      </span>
    </span>
    <br /><br />
  </p>
  <!-- <div>
    <input [checked]="test" (keyup.enter)="remembermeHandler()" type="checkbox" id="rememberme"/>
    <button type="button"  class="btn btn-default button button--aylen" (click)="signIn()">SIGN IN</button>

  </div> -->
</div>
