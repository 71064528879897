/********************************************************************
 * Created ON:05/12/2018
 * Created by: Avaya
 * Description: Guard for login user.
 * Revision History:
 * Modification date:
 * Description: T
 **********************************************************************/
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrormodalComponent } from '../component/errormodal/errormodal.component';
/**
 *
 */
@Injectable()
export class AuthGuard {
  public errormodalconfig = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'childmodal successmodal',
  };
  /**
   *
   * @param router
   * @param storageService
   * @param bsModalService
   * @param bsModalRef
   */
  constructor(
    private router: Router,
    private storageService: StorageService,
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) {}

  /**
   *
   */
  canActivate() {
    if (this.storageService.getFromSessioStorage('userInfo')) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url and return false
    this.router.navigate(['']);
    const initialState = { error_message: 'Please login to access this page' };
    this.bsModalRef = this.bsModalService.show(
      ErrormodalComponent,
      Object.assign({ initialState }, this.errormodalconfig),
    );

    return false;
  }
}
