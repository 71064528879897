<div class="modal-body">
  <a
    tooltip="close"
    placememt="left"
    href="javascript:void(0)"
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalhide()"
    (focus)="loadAudio(0)"
    (focusout)="loadAudio(99)"
    (mouseenter)="loadAudio(0)"
    (mouseleave)="loadAudio(99)"
  >
    <span aria-hidden="true" class="btn-close" [attr.aria-hidden]="true">
      <img src="../../assets/img/close.svg" class="ic_close" />
    </span>
  </a>
  <div class="left">
    <div class="contact-content">
      <img style="width: 309px" src="../../assets/img/logo.svg" alt="decorative" />
      <p>
        You can use this contact form to send us an email. Please just enter
        your information in the corresponding fields.
      </p>
      <div class="address">
        <ul>
          <li>
            <div class="icon">
              <img src="../../assets/img/icn-location.svg" width="13" />
            </div>
            <div class="con">
              <a href=""
                >833 Market Street suite 421,
                <br />
                San Francisco, California, 94103
              </a>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="../../assets/img/icn-phone.svg" width="18" />
            </div>
            <div class="con"><a href="">(510) 590-7363</a></div>
          </li>
          <li>
            <div class="icon">
              <img src="../../assets/img/icn-mail.svg" width="18" />
            </div>
            <div class="con">
              <a
                href="mailto:info@helixopp.com
              ?subject=Helix%20query"
                target="_top"
                >info@helixopp.com</a
              >
              <!-- <a href="mailto:someone@example.com?Subject=Hello%20again" target="_top">Send Mail</a> -->
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="../../assets/img/icn-web.svg" width="18" />
            </div>
            <div class="con">
              <a href="www.helixopp.com" target="_blak">www.helixopp.com</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="tab-con register">
      <h2>Contact US</h2>
      <form [formGroup]="contactUsForm" (ngSubmit)="contactUs()">
        <div class="form-group">
          <label for="exampleInputEmail1">Name</label>
          <input
            (focus)="loadAudio(1)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(1)"
            (mouseleave)="loadAudio(99)"
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            placeholder="Enter your name"
            formControlName="name"
            aria-label="Enter Name"
          />
          <span *ngIf="contactUsForm.controls.name.touched">
            <span
              *ngIf="
                contactUsForm.controls.name.errors
                  ? contactUsForm.controls.name.errors.required
                  : false;
                else invalidName
              "
            >
              This field is required</span
            >
            <ng-template #invalidName>
              <span *ngIf="contactUsForm.controls.name.invalid">
                Invalid Name</span
              >
            </ng-template>
          </span>
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1">Email ID</label>
          <input
            (focus)="loadAudio(2)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(2)"
            (mouseleave)="loadAudio(99)"
            aria-label="enter email"
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            placeholder="Email"
            formControlName="email"
          />
          <span *ngIf="contactUsForm.controls.email.touched">
            <span
              *ngIf="
                contactUsForm.controls.email.errors
                  ? contactUsForm.controls.email.errors.required
                  : false;
                else invalidEmail
              "
            >
              This field is required</span
            >
            <ng-template #invalidEmail>
              <span *ngIf="contactUsForm.controls.email.invalid">
                Invalid email address</span
              >
            </ng-template>
          </span>
        </div>
        <div class="form-group">
          <label for="Subject">Subject</label>
          <input
            (focus)="loadAudio(3)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(3)"
            (mouseleave)="loadAudio(99)"
            aria-label="Enter Subject"
            type="text"
            class="form-control"
            id="subject"
            placeholder="Enter subject"
            formControlName="subject"
          />
          <span
            *ngIf="
              contactUsForm.controls.subject.invalid &&
              contactUsForm.controls.subject.touched
            "
          >
            This field is required.</span
          >
        </div>
        <div class="form-group">
          <label for="ConfirmPassword">Message</label>
          <textarea
            (focus)="loadAudio(4)"
            (focusout)="loadAudio(99)"
            (mouseenter)="loadAudio(4)"
            (mouseleave)="loadAudio(99)"
            aria-label="enter message"
            class="form-control txtarea"
            placeholder="Enter your message"
            formControlName="message"
          ></textarea>
          <span
            *ngIf="
              contactUsForm.controls.message.invalid &&
              contactUsForm.controls.message.touched
            "
          >
            This field is required.</span
          >
        </div>

        <button
          (focus)="loadAudio(5)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(5)"
          (mouseleave)="loadAudio(99)"
          type="submit"
          class="btn btn-default button button--aylen"
          [disabled]="contactUsForm.invalid"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
<audio
  id="ContactUsAudio"
  [src]="ContactUsAudioUrl"
  autoPlay="true"
  controls
  class="hide"
></audio>
