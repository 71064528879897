import { map } from 'rxjs/operators';
/********************************************************************
 * Created ON:12/11/2018
 * Created by: Avaya
 * Description: Common API service file.
 *              All type a API call method files are defined here
 * Revision History:
 * Modification date: 26/11/2018
 * Description: Added Api call method for posting images
 ***/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';

import { API_ENDPOINTS } from '../constant/apiEndpoint.constant';

/**
 *
 */
@Injectable()
export class WebApiService {
  private apiDomain: string = environment.apiUrl;
  /**
   *
   * @param http
   * @param storageService
   * @param router
   */
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
  ) {}

  // Read token from localstorage
  private getAuthToken() {
    let token = '';
    // ToDo Once token done need to integrate that
    const loginToken = this.storageService.getFromLocalStorage('userInfo');

    if (loginToken && loginToken.authToken) {
      token = loginToken.authToken;
    } else {
      // this.router.navigateByUrl('');
    }
    return token;
  }

  /**
   * Get Api call
   *
   * @param endPoint
   * GET call if Auth token des not exist.
   * Mainly used to call create token API
   */

  /**
   *
   * @param data
   */
  public getApiCallWithoutToken(data) {
    const url = this.apiDomain + data.endPoint;
    const contentType = data.contentType || 'application/json';
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Content-Type', contentType);
    return this.http
      .get(url, {
        headers: requestHeaders,
        params: data.data ? data.data : '',
      })
      .pipe(map((res) => res));
  }

  /**
   * Get Api call
   *
   * @param authenticate
   * @param endPoint
   * @param data
   */
  public getApiCall(data) {
    const url = this.apiDomain + data.endPoint;
    const contentType = data.contentType || 'application/json';
    let requestHeaders = new HttpHeaders();

    requestHeaders = requestHeaders.set('Content-Type', contentType);
    // if (data.authenticate) {
    const authToken = this.getAuthToken();
    requestHeaders = requestHeaders.set('Authorization', authToken);
    // }
    return this.http
      .get(url, {
        headers: requestHeaders,
        params: data.data ? data.data : '',
        responseType: data.responseType ? data.responseType : '',
      })
      .pipe(map((res) => res));
    // .catch((err) => {
    //     if (err.status === 401) {
    //         // redirect user to login
    //         this.storageService.removeFromLocalStorage('loginData');
    //         this.userService.userLoggedOut();
    //     }
    //     return Observable.throw(err)
    // });
  }

  /**
   * POST API RQUEST
   *
   * @param data
   * @param responseType
   */
  // public postApiCallWithoutAuthToken(data) {
  //     const url = this.apiDomain + data.endPoint;
  //     let input = new FormData();
  //     // Add your values in here
  //     input.append("username", "user2");
  //     input.append("password", "Qwerty@123#");
  //     input.append("service", "angular");
  //     // input.append('email', 'email.gmail.com');
  //     // input.append('password', 'password');
  //     const contentType = data.contentType || 'multipart/form-data';
  //     let requestHeaders = new HttpHeaders();
  //     requestHeaders = requestHeaders.set('Content-Type', contentType);
  //     requestHeaders.append('withCredentials', 'false');
  //     const header = {
  //         headers: requestHeaders
  //     };
  //     if (data.responseType) {
  //         header['responseType'] = data.responseType;
  //     }
  //     return this.http.post("http://helixmoodle.com/moodle/login/token.php", input, header);
  // }

  // public postApiCall(data) {
  //     const url = this.apiDomain + data.endPoint;
  //     // converting incoming data into formData
  //     let input = new FormData();
  //     for (let eachElement in data.data) {
  //         input.append(eachElement, data.data[eachElement]);
  //     }
  //     // const contentType = data.contentType || 'application/x-www-form-urlencoded';
  //     let requestHeaders = new HttpHeaders();
  //     // requestHeaders = requestHeaders.set('Content-Type', contentType);
  //     requestHeaders.append('withCredentials', 'false');
  //     // requestHeaders.set("cache-control", "no-cache");
  //     // requestHeaders = requestHeaders.set('Accept', 'multipart/form-data');
  //     // if (data.authenticate) {
  //     const authToken = this.getAuthToken();
  //     // requestHeaders = requestHeaders.set('Authorization', authToken);
  //     // }
  //     const header = {
  //         headers: requestHeaders
  //     };
  //     if (data.responseType) {
  //         header['responseType'] = data.responseType;
  //     }
  //     return this.http.post(url, input, header);
  // }

  public postApiCall(data) {
    const url = this.apiDomain + data.endPoint;
    // converting incoming data into formData
    const input = new FormData();
    for (const eachElement in data.data) {
      input.append(eachElement, data.data[eachElement]);
    }
    let params = new HttpParams();
    if (data.params) {
      params = params.set('wstoken', data.params.token);
      params = params.set('wsfunction', data.params.function);
      params = params.set('moodlewsrestformat', data.params.format);
    } else {
      params = null;
    }
    // if (data.contentType) {
    //     let searchParams: any;
    //     searchParams = Object.keys(data.data).map((key) => { return encodeURIComponent(key) + '=' + encodeURIComponent(data.data[key]); }).join('&');
    //     const contentType = data.contentType;
    //     let requestHeaders = new HttpHeaders();
    //     requestHeaders = requestHeaders.set('Content-Type', contentType);
    //     return this.http.post(url, searchParams, { headers: requestHeaders })
    // }
    // else {
    return this.http.post(url, input, { params });
    // }
  }

  /**
   *
   * @param data
   * POST API call without authToken
   */
  public postApiCallWithoutAuthToken(data) {
    const url = this.apiDomain + data.endPoint;
    const contentType = data.contentType || 'application/json';
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Content-Type', contentType);
    const header = {
      headers: requestHeaders,
    };
    if (data.responseType) {
      header['responseType'] = data.responseType;
    }
    return this.http.post(url, data.data, header);
  }
  /**
   *
   * @param file
   * API call for content-type: multipart/form-data
   * @param data
   */
  public postImage(data) {
    const formData: FormData = new FormData();
    formData.append('file', data.file, data.file.name);
    formData.append('token', data.token);
    return this.http.post(
      `${this.apiDomain + API_ENDPOINTS.UPLOAD_PROFILE_IMAGE}`,
      formData,
    );
  }
}
