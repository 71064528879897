<div
  *ngIf="!menuOpen"
  class="content"
  [ngClass]="{ active: showPanel === true }"
>
  <img src="../../assets/img/logo.svg" class="logo" [hidden]="hotspot" />
  <div class="home-img">
    <div [hidden]="hotspot" class="sign-btn">
      <button
        (focus)="loadAudio(5)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(5)"
        (mouseleave)="loadAudio(99)"
        [tabindex]="modalOpen ? -1 : 0"
        class="button button--aylen btn btn-primary"
        (click)="signinModal(signin, $event); $event.stopPropagation()"
        *ngIf="!isLogin"
      >
        Sign In / Register
      </button>
      <!-- just for test purpose -->
      <button
        (focus)="loadAudio(6)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(6)"
        (mouseleave)="loadAudio(99)"
        [tabindex]="modalOpen ? -1 : 0"
        class="button button--aylen btn btn-primary"
        *ngIf="isLogin"
        (click)="gotoClassroom()"
      >
        My Learning
      </button>
    </div>
  </div>
  <!--<img src="../../assets/img/helix_bg/Campus_View.jpg" class="home-img" [style.transform]="transformY" />-->
  <span>
    <a
      href="javascript:void(0);"
      class="hotspot-circle circle-one"
      (click)="adminPage1()"
      [hidden]="hotspot"
      (focus)="loadAudio(1)"
      (mouseenter)="loadAudio(1)"
      [tooltip]="landingData.cooperativeSchool.name"
      [attr.aria-label]="landingData.cooperativeSchool.name"
      placement="right"
      [tabindex]="modalOpen ? -1 : 0"
      (click)="exploreadminPage(1)"
    >
      <div class="hotspot-circle-inner"></div>
      <div class="hotspot-circle-middle"></div>
      <div class="hotspot-circle-outer"></div>
      <!-- <div class="popover-page arrow_box" *ngIf="pop_page1">
          <div class="pop-content">
            <div class="lt-img">
              <img [src]="landingData.cooperativeSchool.displayImg" />
            </div>
            <div class="rt-content">
              <h4>
                <div [innerHTML]="landingData.cooperativeSchool.name"></div>
              </h4>
              <p [innerHTML]="landingData.cooperativeSchool.displayText"></p>
              <a [attr.aria-label]="'Explore this school'" class="explore-tab" href="javascript:void(0);"
                (click)="exploreadminPage(1);">
                <h5>Explore
                  <img src="../../assets/img/dropdown-arrow.svg" />
                </h5>
              </a>
            </div>
          </div>
          <a [attr.aria-label]="'Close description of this school'" href="javascript:void(0)" class="close-btn"
            (click)="childadminPage1($event);">
            <img src="../../assets/img/icn-close-white.png" width="10" />
          </a>
        </div> -->
    </a>
  </span>
  <a
    href="javascript:void(0);"
    [tabindex]="modalOpen ? -1 : 0"
    class="hotspot-circle circle-two"
    (click)="adminPage2()"
    (focus)="loadAudio(2)"
    (mouseenter)="loadAudio(2)"
    [hidden]="hotspot"
    [tooltip]="landingData.commonSenseSchool.name"
    [attr.aria-label]="landingData.commonSenseSchool.name"
    placement="right"
    (click)="exploreadminPage(2)"
  >
    <div class="hotspot-circle-inner"></div>
    <div class="hotspot-circle-middle"></div>
    <div class="hotspot-circle-outer"></div>
    <!-- <div class="popover-page arrow_box" *ngIf="pop_page2">
        <div class="pop-content">
          <div class="lt-img">
            <img [src]="landingData.commonSenseSchool.displayImg" />
          </div>
          <div class="rt-content">
            <h4>
              <div [innerHTML]="landingData.commonSenseSchool.name"></div>
            </h4>
            <p [innerHTML]="landingData.commonSenseSchool.displayText"></p>
            <a [attr.aria-label]="'Explore this school'" href="javascript:void(0);" class="explore-tab" (click)="exploreadminPage(2);">
              <h5>Explore
                <img src="../../assets/img/dropdown-arrow.svg" />
              </h5>
            </a>
          </div>
        </div>
        <a [attr.aria-label]="'Close description of this school'" href="javascript:void(0)" class="close-btn" (click)="childadminPage2($event);">
          <img src="../../assets/img/icn-close-white.png" width="10" />
        </a>
      </div> -->
  </a>
  <a
    href="javascript:void(0);"
    [tabindex]="modalOpen ? -1 : 0"
    class="hotspot-circle circle-three"
    (click)="adminPage3()"
    [attr.aria-label]="landingData.admission.name"
    (focus)="loadAudio(0)"
    (mouseenter)="loadAudio(0)"
    [tooltip]="landingData.admission.name"
    placement="bottom"
    (click)="exploreadminPage(0)"
  >
    <div class="hotspot-circle-inner"></div>
    <div class="hotspot-circle-middle"></div>
    <div class="hotspot-circle-outer"></div>
    <!-- <div class="popover-page arrow_box" *ngIf="pop_page3">
        <div class="pop-content">
          <div class="lt-img">
            <img [src]="landingData.admission.displayImg" />
          </div>
          <div class="rt-content">
            <h4>
              <div [innerHTML]="landingData.admission.name"></div>
            </h4>
            <p [innerHTML]="landingData.admission.displayText"></p>
            <a [attr.aria-label]="'Explore this school'" href="javascript:void(0);" class="explore-tab" (click)="exploreadminPage(0);">
              <h5>Explore
                <img src="../../assets/img/dropdown-arrow.svg" />
              </h5>
            </a>
          </div>
        </div>
        <a [attr.aria-label]="'Close description of this school'" href="javascript:void(0)" class="close-btn" (click)="childadminPage3($event);">
          <img src="../../assets/img/icn-close-white.png" width="10" />
        </a>
      </div> -->
  </a>
  <a
    href="javascript:void(0);"
    [tabindex]="modalOpen ? -1 : 0"
    class="hotspot-circle circle-four"
    (click)="adminPage4()"
    (focus)="loadAudio(3)"
    (mouseenter)="loadAudio(3)"
    [hidden]="hotspot"
    [tooltip]="landingData.buissnessSchool.name"
    [attr.aria-label]="landingData.buissnessSchool.name"
    placement="left"
    (click)="exploreadminPage(3)"
  >
    <div class="hotspot-circle-inner"></div>
    <div class="hotspot-circle-middle"></div>
    <div class="hotspot-circle-outer"></div>
    <!-- <div class="popover-page-right arrow_box" *ngIf="pop_page4">
        <div class="pop-content">
          <div class="lt-img">
            <img [src]="landingData.buissnessSchool.displayImg" />
          </div>
          <div class="rt-content">
            <h4>
              <div [innerHTML]="landingData.buissnessSchool.name"></div>
            </h4>
            <p [innerHTML]="landingData.buissnessSchool.displayText"></p>
            <a [attr.aria-label]="'Explore this school'" href="javascript:void(0);" class="explore-tab" (click)="exploreadminPage(3);">
              <h5>Explore
                <img src="../../assets/img/dropdown-arrow.svg" />
              </h5>
            </a>
          </div>
        </div>
        <a  [attr.aria-label]="'Close description of this school'" href="javascript:void(0)" class="close-btn" (click)="childadminPage4($event);">
          <img src="../../assets/img/icn-close-white.png" width="10" />
        </a>
      </div> -->
  </a>
  <a
    [attr.aria-label]="'Contact Us'"
    href="javascript:void(0);"
    class="contat-con"
    (focus)="loadAudio(4)"
    (focusout)="loadAudio(99)"
    (mouseenter)="loadAudio(4)"
    (mouseleave)="loadAudio(99)"
    (click)="contactModal()"
    [tabindex]="modalOpen ? -1 : 0"
  >
    <div class="con">
      <img src="../../assets/img/contact.svg" width="18" />
      <h4>Contact</h4>
    </div>
  </a>
  <div class="home-footer">
    <ul class="left">
      <li (click)="openToU()">
        <!-- [tabindex]="modalOpen?-1:0" -->
        <span style="cursor: pointer" [tabindex]="modalOpen ? -1 : 0"
          >Terms of use</span
        >
      </li>
      <li>|</li>
      <li (click)="openPolicy()">
        <span style="cursor: pointer" [tabindex]="modalOpen ? -1 : 0">
          Privacy policy</span
        >
      </li>
      <li>|</li>
      <li (click)="openSla()">
        <span style="cursor: pointer" [tabindex]="modalOpen ? -1 : 0"
          >Service Level agreements</span
        >
      </li>
    </ul>
    <ul class="right" [attr.aria-hidden]="true">
      <li>Copyright © 2018 Helix Opportunity Institute</li>
    </ul>
  </div>
  <div class="chat-container">
    <app-help-guide
      (ended)="test()"
      [audioUrl]="adoUrl"
      [textUrl]="txtUrl"
      newClass="chat-icon-home"
      [newClassDialogue]="'chat-container-con'"
      [animatedCharecter]="'../../assets/img/professorCharacter/Girl1-min.gif'"
    ></app-help-guide>
  </div>
</div>
<audio
  id="homeAudio"
  [src]="homeAudioUrl"
  autoPlay="true"
  controls
  class="hide"
></audio>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }">
</ngx-loading>
