import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../shared/services/storage.service';
import { MuteService } from '../shared/services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
})
export class QuizComponent implements OnInit {
  public quizUrl: any;
  public allAudioTags: any;
  public progName: any;
  public lessonName: any;
  /**
   *
   * @param route
   * @param sanitizer
   * @param storageService
   * @param muteService
   */
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private storageService: StorageService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.muteService.muteFun();
    this.route.queryParams.subscribe(
      (params) => {
        this.quizUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          decodeURIComponent(params.url),
        );
        this.progName = params.progName;
        this.lessonName = params.lessonName;
      },
      () => {},
    );
  }

  /**
   *
   */
  public goBack() {
    window.history.back();
  }
}
