/********************************************************************
 * Created ON:31/12/2018
 * Created by: Avaya
 * Description: Component for showing Terms id Use.
 * Revision History:
 * Modification date:
 * Description: T
 **********************************************************************/
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NodeService } from '../../services/node.service';
import { StorageService } from '../../services/storage.service';
import { MuteService } from '../../services/mute.service';
import { Router } from '@angular/router';
/**
 *
 */
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css'],
})
export class TermsOfUseComponent implements OnInit {
  public allAudioTags: any;
  /**
   *
   * @param bsmodalref
   * @param nodeService
   * @param storageService
   * @param muteService
   * @param router
   */
  constructor(
    private bsmodalref: BsModalRef,
    private nodeService: NodeService,
    private storageService: StorageService,
    private muteService: MuteService,
    private router: Router,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.muteService.muteFun();
  }
  /**
   * Function to close open modal
   */
  public modalClose = () => {
    this.bsmodalref.hide();
    this.nodeService.addNode({ modalopen: false });
    this.router.navigate(['']);
  };
  /**
   *
   */
  agreeButton() {
    const d = new Date();
    d.setTime(d.getTime() + 190 * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie =
      'HellixAcceptedCookies' + '=' + '1' + ';' + expires + ';path=/';
    this.bsmodalref.hide();
    this.nodeService.addNode({ modalopen: false });
    this.router.navigate(['']);
  }
  /**
   *
   */
  cancelButton() {
    //   var user = this.getCookie("HellixAcceptedCookies");
    // if (user != "") {
    //   alert("Welcome again " + user);
    // } else{
    //   alert("Noo");
    // }
    this.bsmodalref.hide();
    this.nodeService.addNode({ modalopen: false });
    this.router.navigate(['']);
  }

  /**
   *
   * @param cname
   */
  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}
