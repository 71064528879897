/********************************************************************
 * Created ON:25/11/2018
 * Created by: Pallavi
 * Description: File where passwords can be changed using previous password
 * Revision History:
 * Modification date: 29/11/2018
 * Description: Closing of modal in .ts file
 **********************************************************************/

import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../../shared/constant/apiEndpoint.constant';
import { WebApiService } from '../../shared/services/web-api.service';
import { SuccessmodalComponent } from '../../shared/component/successmodal/successmodal.component';
import { CommonService } from '../../shared/services/common.service';
import { StorageService } from '../../shared/services/storage.service';
import { ErrormodalComponent } from '../../shared/component/errormodal/errormodal.component';
import { MODAL_CONFIG } from '../../shared/constant/modalConfig.constant';
import { NodeService } from '../../shared/services/node.service';
import { MuteService } from '../../shared/services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent implements OnInit {
  public wrongPassword = false;
  public showPwdCurrent = false;
  public showPwdNew = false;
  public showPwdConfirm = false;
  public loading = false;
  public error_msg: any;
  public allAudioTags: any;

  // To check password condition
  // public strongRegex = this.commonService.regExpForPassword();

  // tslint:disable-next-line:max-line-length
  public emailRegex = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
  );

  // change password form declration and validation
  public changePasswordForm = new UntypedFormGroup(
    {
      currentPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      newPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.commonService.strongRegex),
        Validators.maxLength(100),
      ]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    },
    this.passwordMatchValidator,
  );

  // function to compare new assword and confirm password fields
  /**
   *
   * @param g
   */
  public passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('newPassword').value === g.get('confirmPassword').value
      ? null
      : { mismatch: true };
  }

  /**
   *
   * @param api
   * @param commonService
   * @param storageService
   * @param bsModalRef
   * @param modalService
   * @param nodeService
   * @param muteService
   */
  constructor(
    private api: WebApiService,
    private commonService: CommonService,
    private storageService: StorageService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private nodeService: NodeService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.nodeService.addNode({ modalopen: true });
    this.muteService.muteFun();
  }

  // method to hide the modal using modal reference
  /**
   *
   */
  public changepasswordhideModal() {
    this.changePasswordForm.reset();
    this.bsModalRef.hide();
    this.nodeService.addNode({ modalopen: false });
  }

  /**
   * function to make api call to update new password
   * and display success modal if password updated successfully
   */
  public successprofileModal() {
    this.loading = true;
    const loginToken = this.storageService.getFromSessioStorage('loginToken');
    const requestData = {
      endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
      data: {
        token: loginToken,
        old_password: this.changePasswordForm.controls.currentPassword.value,
        new_password: this.changePasswordForm.controls.newPassword.value,
        confirm_new_password:
          this.changePasswordForm.controls.confirmPassword.value,
      },
      params: {
        token: loginToken,
        function: API_FUNCTIONS.CHANGE_PASSWORD,
        format: 'json',
      },
    };
    this.api.postApiCall(requestData).subscribe(
      (response: any) => {
        if (response.status == 'success') {
          const initialState = {
            success_message: 'You have successfully changed your password.',
            from_module: 'change_password',
          };
          this.bsModalRef.hide();
          this.wrongPassword = false;
          this.bsModalRef = this.modalService.show(
            SuccessmodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.successmodalconfig),
          );
        } else if (response.error_code == 12) {
          this.wrongPassword = true;
          this.error_msg = response.error_description;
        } else {
          const initialState = { error_message: response.error_description };
          this.bsModalRef = this.modalService.show(
            ErrormodalComponent,
            Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
          );
        }
        this.loading = false;
        this.changePasswordForm.reset();
      },
      () => {
        this.bsModalRef.hide();
        this.loading = false;
      },
    );
  }
  /**
   *
   * @param pwdType
   */
  public showPassword(pwdType) {
    if (pwdType == 'pwd')
      this.showPwdCurrent = this.showPwdCurrent ? false : true;
    else if (pwdType == 'newpwd')
      this.showPwdNew = this.showPwdNew ? false : true;
    else this.showPwdConfirm = this.showPwdConfirm ? false : true;
  }
  /**
   *
   * @param eleNo
   */
  public loadAudio(eleNo) {
    const profileAudio = <HTMLAudioElement>(
      document.getElementById('myProfileAudio')
    );
    /**
     * ToDo
     * Current password,
     * New password audio has to be added
     */
    switch (eleNo) {
      case 0:
        profileAudio.src = '../../assets/audio/shared/Close_Button.mp3';
        break;
      case 1:
        profileAudio.src =
          '../../assets/audio/shared/Current_Password_Field.mp3';
        break;
      case 2:
        profileAudio.src = '../../assets/audio/shared/New_Password_Field.mp3';
        break;
      case 3:
        profileAudio.src =
          '../../assets/audio/shared/Password_Confirmation_Field.mp3';
        break;
      case 4:
        profileAudio.src = '../../assets/audio/shared/Save_Button.mp3';
        break;
      default:
        profileAudio.src = '';
    }
  }
}
