<div class="rt-con-tab">
  <h2 class="title">My Certificate</h2>
  <div></div>
  <tabset type="pills">
    <div class="row centered" *ngIf="certData.length == 0; else progBlock">
      NO DATA AVAILABLE
    </div>
    <ng-template #progBlock>
      <div class="tab-content-inner">
        <div class="acc-content">
          <div class="row">
            <div *ngFor="let program of certData">
              <div class="col-xs-12 col-sm-6 col-md-6" *ngFor="let lesson of program.lessons; let i = index">
                <div class="acc-col">
                  <div class="acc-top-con">
                    <h2 [tabindex]="modalopen ? -1 : 0" [attr.aria-label]="lesson.lesson_title">
                      {{ lesson.lesson_title }}
                    </h2>
                    <div [tabindex]="modalopen ? -1 : 0" class="pro-name">
                      <img [attr.aria-hidden]="true" src="../../assets/img/icn-author.svg" width="18" />
                      <h4 [attr.aria-label]="'Author' + lesson.author">
                        {{ lesson.author }}
                      </h4>
                    </div>
                  </div>
                  <div class="acc-bottom-con">
                    <div class="acc-bottom-rating" *ngIf="lesson.lesson_status.lesson_progress == 100; else incompleteBlock">
                      <span class="pull-right certificate">
                        <button
                          [tabindex]="modalopen ? -1 : 0"
                          class="lesson-certificate"
                          (focus)="loadAudio(8)"
                          (mouseenter)="loadAudio(8)"
                          (click)="downloadCertificate($event, program.program_id, lesson.lesson_id, lesson.lesson_title, 'lesson')"
                        >
                          Certificate
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </tabset>
  <div id="content" class="to_pdf">&nbsp;</div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
