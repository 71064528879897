<div class="rt-con-tab" *ngIf="mylearnings">
  <h2 class="title" *ngIf="page == 'mylearning'">My Learning</h2>
  <h2 class="title" *ngIf="page == 'certificate'">My Certificate</h2>
  <div></div>
  <tabset type="pills">
    <div class="filter-nav" *ngIf="page == 'mylearning'">
      <button
        [tabindex]="modalopen ? -1 : 0"
        class="button btn btn-outline"
        [ngClass]="filter === 'all' ? 'nav-link active' : null"
        (click)="changeFilter('all')"
        (focus)="loadAudio(0)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(0)"
        (mouseleave)="loadAudio(99)"
      >
        All
      </button>
      <button
        [tabindex]="modalopen ? -1 : 0"
        class="button btn btn-outline"
        [ngClass]="filter === 'new' ? 'nav-link active' : null"
        (click)="changeFilter('new')"
        (focus)="loadAudio(1)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(1)"
        (mouseleave)="loadAudio(99)"
      >
        New
      </button>
      <button
        [tabindex]="modalopen ? -1 : 0"
        class="button btn btn-outline"
        [ngClass]="filter === 'inprogress' ? 'nav-link active' : null"
        (click)="changeFilter('inprogress')"
        (focus)="loadAudio(2)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(2)"
        (mouseleave)="loadAudio(99)"
      >
        In Progress
      </button>
      <button
        [tabindex]="modalopen ? -1 : 0"
        class="button btn btn-outline"
        [ngClass]="filter === 'completed' ? 'nav-link active' : null"
        (click)="changeFilter('completed')"
        (focus)="loadAudio(3)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(3)"
        (mouseleave)="loadAudio(99)"
      >
        Completed
      </button>
    </div>
    <div
      class="row centered"
      *ngIf="programListData.length == 0; else progBlock"
    >
      {{ errormsg }}
    </div>
    <ng-template #progBlock>
      <div class="tab-content-inner">
        <accordion>
          <accordion-group
            #accGroup
            [isOpen]="progIdx == accordionIdx ? true : false"
            [tabindex]="modalopen ? -1 : 0"
            *ngFor="let program of programListData; let progIdx = index"
            (isOpenChange)="accdChange($event, progIdx)"
          >
            <div accordion-heading>
              <div class="head-wrap">
                <div class="rt-con my-learning-rt">
                  <div class="left-con" style="width: calc(100% - 250px)">
                    <div class="top-line">
                      <h4>{{ program.program_name }}</h4>
                      <div class="lesson_mobile">
                        <img src="../../assets/img/icn-lesson.svg" width="17" />
                        <h5>{{ program.lessons.length }} Lessons</h5>
                      </div>
                      <div class="show-on-mobile">
                        <!-- <div class="right-con" *ngIf="program.program_status.progress == 100  ; else nextBlock"> -->
                        <div
                          class="right-con"
                          *ngIf="
                            program.program_progress == 100;
                            else nextBlock
                          "
                        >
                          <!-- <span [tabindex]="modalopen?-1:0" class="rating_star"                              [attr.aria-label]="'rating'+program.program_rating+'Press tab and swipe right arrow and press enter to rate the program for max five'"> -->
                          <!-- <rating [attr.aria-hidden]="true" [(ngModel)]="program.program_rating" [max]="max"                                [titles]="['one','two','three']"                                (click)="starRatingProg(program.program_id,program.program_rating)"                                (keypress)="starRatingProg(program.program_id,program.program_rating)"></rating> -->
                          <!-- <rating [readonly]="true" [max]="max" [titles]="['one','two','three']" (click)="starRatingProg(program.program_id,'4')"></rating> -->
                          <!-- </span> -->
                          <span class="pull-right certificate">
                            <button
                              [tabindex]="modalopen ? -1 : 0"
                              (focus)="loadAudio(8)"
                              (mouseenter)="loadAudio(8)"
                              (click)="
                                downloadInvoice(
                                  $event,
                                  program.program_id,
                                  null,
                                  program.program_name,
                                  'prog'
                                )
                              "
                            >
                              Certificate
                            </button>
                          </span>
                        </div>
                        <ng-template #nextBlock>
                          <button
                            (focus)="loadAudio(7)"
                            (focusout)="loadAudio(99)"
                            (mouseenter)="loadAudio(7)"
                            (mouseleave)="loadAudio(99)"
                            [tabindex]="modalopen ? -1 : 0"
                            style="background: transparent; border: none"
                            class="right-con"
                            *ngIf="
                              program.program_progress == 0;
                              else blockProgress
                            "
                            class="acc-bottom-rt prog-start"
                            triggers="focus"
                            (click)="classroomSctions(progIdx, null)"
                          >
                            <h5 class="start-con">Start</h5>
                          </button>
                          <ng-template #blockProgress>
                            <div class="right-con prog-bar">
                              <div class="top-line">
                                <p class="pro-txt">Overall Progress</p>
                              </div>
                              <div class="bottom-line">
                                <div class="">
                                  <div class="pro-bar">
                                    <progressbar
                                      [value]="program.program_progress"
                                    ></progressbar>
                                  </div>
                                  <div class="pro-bar-val">
                                    {{ program.program_progress }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="right-con" class="acc-bottom-rt" (click)="classroomSctions(progIdx, null)">                                <h5>Continue</h5>                              </div> -->
                          </ng-template>
                        </ng-template>
                      </div>
                    </div>
                    <div class="bottom-line">
                      <p>{{ program.school_name }}</p>
                      <div style="clear: both"></div>
                      <div
                        aria-pressed="false"
                        class="ng-view-collapse"
                        role="button"
                        tabindex="0"
                        (focus)="focusTotoggle()"
                        (focusout)="focusOutTotoggle()"
                        (focus)="accordionExpandAudio(progIdx)"
                        (focusout)="accordionExpandAudio(-1)"
                        (mouseenter)="accordionExpandAudio(progIdx)"
                        (mouseleave)="accordionExpandAudio(-1)"
                        [tabindex]="modalopen ? -1 : 0"
                        (keypress)="onKeyPressed(progIdx)"
                      >
                        <h5
                          class="view_details_accordian plus c-type"
                          style="text-align: left !important"
                          title="Press enter to expand"
                          aria-label="1"
                        >
                          View Details
                        </h5>
                        <h5
                          class="view_details_accordian minus c-type"
                          style="text-align: left !important"
                          title="Press enter to collapse details"
                          aria-label="1"
                        >
                          Collapse Details
                        </h5>
                      </div>
                      <div style="clear: both"></div>
                    </div>
                  </div>
                  <div class="hide-on-mobile">
                    <!-- <div class="right-con" *ngIf="program.program_status.progress == 100  ; else nextBlock"> -->
                    <div
                      class="right-con"
                      *ngIf="program.program_progress == 100; else nextBlock"
                    >
                      <!-- <span [tabindex]="modalopen?-1:0" class="rating_star"
                      [attr.aria-label]="'rating'+program.program_rating+'Press tab and swipe right arrow and press enter to rate the program for max five'"> -->
                      <!-- <rating [attr.aria-hidden]="true" [(ngModel)]="program.program_rating" [max]="max"
                        [titles]="['one','two','three']"
                        (click)="starRatingProg(program.program_id,program.program_rating)"
                        (keypress)="starRatingProg(program.program_id,program.program_rating)"></rating> -->
                      <!-- <rating [readonly]="true" [max]="max" [titles]="['one','two','three']" (click)="starRatingProg(program.program_id,'4')"></rating> -->
                      <!-- </span> -->
                      <span class="pull-right certificate">
                        <button
                          [tabindex]="modalopen ? -1 : 0"
                          (focus)="loadAudio(8)"
                          (mouseenter)="loadAudio(8)"
                          (click)="
                            downloadInvoice(
                              $event,
                              program.program_id,
                              null,
                              program.program_name,
                              'prog'
                            )
                          "
                        >
                          Certificate
                        </button>
                      </span>
                    </div>
                    <ng-template #nextBlock>
                      <button
                        (focus)="loadAudio(7)"
                        (focusout)="loadAudio(99)"
                        (mouseenter)="loadAudio(7)"
                        (mouseleave)="loadAudio(99)"
                        [tabindex]="modalopen ? -1 : 0"
                        style="background: transparent; border: none"
                        class="right-con"
                        *ngIf="
                          program.program_progress == 0;
                          else blockProgress
                        "
                        class="acc-bottom-rt prog-start"
                        triggers="focus"
                        (click)="classroomSctions(progIdx, null)"
                      >
                        <h5 class="start-con">Start</h5>
                      </button>
                      <ng-template #blockProgress>
                        <div class="right-con prog-bar">
                          <div class="top-line">
                            <p class="pro-txt">Overall Progress</p>
                          </div>
                          <div class="bottom-line">
                            <div class="">
                              <div class="pro-bar">
                                <progressbar
                                  [value]="program.program_progress"
                                ></progressbar>
                              </div>
                              <div class="pro-bar-val">
                                {{ program.program_progress }}%
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="right-con" class="acc-bottom-rt" (click)="classroomSctions(progIdx, null)">
                        <h5>Continue</h5>
                      </div> -->
                      </ng-template>
                    </ng-template>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
              <div style="clear: both"></div>
            </div>
            <div class="acc-content">
              <div class="row">
                <div
                  class="col-xs-12 col-sm-6 col-md-6"
                  *ngFor="let lesson of program.lessons; let i = index"
                >
                  <div class="acc-col">
                    <div class="acc-top-con">
                      <h2
                        [tabindex]="modalopen ? -1 : 0"
                        [attr.aria-label]="lesson.lesson_title"
                      >
                        {{ lesson.lesson_title }}
                      </h2>
                      <div [tabindex]="modalopen ? -1 : 0" class="pro-name">
                        <img
                          [attr.aria-hidden]="true"
                          src="../../assets/img/icn-author.svg"
                          width="18"
                        />
                        <h4 [attr.aria-label]="'Author' + lesson.author">
                          {{ lesson.author }}
                        </h4>
                      </div>
                    </div>
                    <div class="acc-bottom-con">
                      <div
                        class="acc-bottom-rating"
                        *ngIf="
                          lesson.lesson_status.lesson_progress == 100;
                          else incompleteBlock
                        "
                      >
                        <!-- <span class="pull-right certificate">
                          <button [tabindex]="modalopen?-1:0" class="lesson-certificate" (focus)="loadAudio(8)" (mouseenter)="loadAudio(8)"
                            (click)="downloadInvoice($event,program.program_id,lesson.lesson_id,lesson.lesson_title,'lesson');">Certificate</button>

                        </span> -->

                        <span class="pull-right certificate">
                          <button
                            [tabindex]="modalopen ? -1 : 0"
                            class="lesson-certificate"
                            (focus)="loadAudio(8)"
                            (mouseenter)="loadAudio(8)"
                            (click)="
                              downloadInvoice(
                                $event,
                                program.program_id,
                                lesson.lesson_id,
                                lesson.lesson_title,
                                'lesson'
                              )
                            "
                          >
                            Certificate
                          </button>
                        </span>

                        <span
                          [tabindex]="modalopen ? -1 : 0"
                          [attr.aria-label]="
                            'rating' +
                            lesson.lesson_status.lesson_rating +
                            'Press tab and swipe right arrow and press enter to rate lesson for max five'
                          "
                        >
                          <!-- <rating style="float:left" [attr.aria-hidden]="true"
                            [(ngModel)]="lesson.lesson_status.lesson_rating" [max]="max"
                            (keypress)="starRating(lesson.lesson_id,lesson.lesson_status.lesson_rating)"
                            (click)="starRating(lesson.lesson_id,lesson.lesson_status.lesson_rating)"></rating> -->
                        </span>
                      </div>
                      <ng-template #incompleteBlock>
                        <div
                          *ngIf="
                            lesson.lesson_status.lesson_progress == 0;
                            else progressBlock
                          "
                        >
                          <div class="acc-bottom-lt">
                            <div class="start">
                              <img
                                src="../../assets/img/icn-time.svg"
                                width="18"
                              />
                              <h6>
                                {{ lesson.lesson_status.lesson_duration }}
                              </h6>
                            </div>
                          </div>
                          <button
                            (focus)="loadAudio(6)"
                            (focusout)="loadAudio(99)"
                            (mouseenter)="loadAudio(6)"
                            (mouseleave)="loadAudio(99)"
                            [tabindex]="modalopen ? -1 : 0"
                            style="background: transparent; border: none"
                            class="acc-bottom-rt"
                            (click)="classroomSctions(progIdx, i)"
                          >
                            <h5>Start</h5>
                          </button>
                        </div>
                      </ng-template>
                      <ng-template #progressBlock>
                        <div
                          class="acc-bottom-rating"
                          *ngIf="lesson.quiz; else noQuiz"
                        >
                          <span class="pull-right certificate">
                            <button
                              [tabindex]="modalopen ? -1 : 0"
                              (focus)="loadAudio(5)"
                              (focusout)="loadAudio(99)"
                              (mouseenter)="loadAudio(5)"
                              (mouseleave)="loadAudio(99)"
                              class="lesson-certificate"
                              (click)="goToQuiz(lesson.lesson_id)"
                            >
                              Quiz
                            </button>
                          </span>
                          <span
                            [tabindex]="modalopen ? -1 : 0"
                            [attr.aria-label]="
                              'rating' +
                              lesson.lesson_status.lesson_rating +
                              'Press tab and swipe right arrow and press enter to rate lesson for max five'
                            "
                          >
                            <!-- <rating style="float: left;" [attr.aria-hidden]="true"
                              [(ngModel)]="lesson.lesson_status.lesson_rating" [max]="max"
                              (click)="starRating(lesson.lesson_id,lesson.lesson_status.lesson_rating)"
                              (keypress)="starRating(lesson.lesson_id,lesson.lesson_status.lesson_rating)"
                              [titles]="['one','two','three']"></rating> -->
                          </span>
                        </div>
                        <ng-template #noQuiz>
                          <div class="acc-bottom-lt">
                            <div class="pro-bar" style="width: 100px">
                              <progressbar
                                [value]="lesson.lesson_status.lesson_progress"
                              ></progressbar>
                            </div>
                            <div class="pro-bar-val">
                              {{ lesson.lesson_status.lesson_progress }}%
                            </div>
                          </div>
                          <button
                            (focus)="loadAudio(4)"
                            (focusout)="loadAudio(99)"
                            (mouseenter)="loadAudio(4)"
                            (mouseleave)="loadAudio(99)"
                            [tabindex]="modalopen ? -1 : 0"
                            style="background: transparent; border: none"
                            class="acc-bottom-rt"
                            (click)="classroomSctions(progIdx, i)"
                          >
                            <h5>Continue</h5>
                          </button>
                        </ng-template>
                      </ng-template>
                    </div>
                    <div class="less-title">
                      Lesson {{ lesson.lesson_number }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </accordion-group>
        </accordion>
      </div>
    </ng-template>
  </tabset>
  <div id="content" class="to_pdf">&nbsp;</div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }">
  </ngx-loading>
</div>
