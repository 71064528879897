/********************************************************************
 * Created ON:31/12/2018
 * Created by: Avaya
 * Description: Component for showing policy.
 * Revision History:
 * Modification date:
 * Description: T
 **********************************************************************/
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NodeService } from '../../services/node.service';
import { StorageService } from '../../services/storage.service';
import { MuteService } from '../../services/mute.service';
import { Router } from '@angular/router';
/**
 *
 */
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css'],
})
export class PolicyComponent implements OnInit {
  public allAudioTags: any;
  /**
   *
   * @param router
   * @param bsmodalref
   * @param nodeService
   * @param storageService
   * @param muteService
   */
  constructor(
    private router: Router,
    private bsmodalref: BsModalRef,
    private nodeService: NodeService,
    private storageService: StorageService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.muteService.muteFun();
  }
  /**
   * Function to close open modal
   */
  public modalClose = () => {
    this.bsmodalref.hide();
    this.nodeService.addNode({ modalopen: false });
    this.router.navigate(['']);
  };
}
