<div class="rt-con-tab">
  <h2 class="title">Billing</h2>
  <tabset type="pills">
    <div class="filter-nav">
      <button
        [tabindex]="modalopen ? -1 : 0"
        (focus)="loadAudio(1)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(1)"
        (mouseleave)="loadAudio(99)"
        class="button btn btn-outline"
        [ngClass]="filter === 'prog' ? 'nav-link active' : null"
        (click)="changeFilter('prog')"
      >
        Purchased Programs
      </button>
      <button
        [tabindex]="modalopen ? -1 : 0"
        (focus)="loadAudio(2)"
        (focusout)="loadAudio(99)"
        (mouseenter)="loadAudio(2)"
        (mouseleave)="loadAudio(99)"
        class="button btn btn-outline"
        [ngClass]="filter === 'lesson' ? 'nav-link active' : null"
        (click)="changeFilter('lesson')"
      >
        Purchased Lessons
      </button>
    </div>
    <div class="tab-content-inner">
      <div class="billing-con">
        <div
          *ngIf="
            billData.programs.length != 0 || billData.lessons.length != 0;
            else displayMessage
          "
        >
          <div
            class="row"
            *ngIf="billData.programs.length != 0 && filter == 'prog'"
          >
            <!-- <div>
              <h4 class="sub-title">Purchased Programs</h4>
            </div> -->
            <div
              class="col-xs-6 padBottom"
              *ngFor="let invoice of billData.programs"
            >
              <div class="billing-wrap">
                <div class="billing-wrap-con">
                  <div class="left">
                    <h2>{{ invoice.program_details.program_name }}</h2>
                    <p>{{ invoice.program_details.school_name }}</p>
                  </div>
                  <div class="right">
                    <h2>
                      <span style="font-weight: normal">Total Cost :</span
                      ><span style="padding-left: 3px"
                        >${{ invoice.program_details.cost }}</span
                      >
                    </h2>
                  </div>
                  <div class="text-uppercase lession-con">
                    <span *ngFor="let lesson of invoice.lessons">
                      {{ lesson.lesson_name }}:
                      <strong>${{ lesson.lesson_cost }}</strong></span
                    >
                  </div>
                  <ul>
                    <li>
                      <div class="bill-heading">Transaction ID</div>
                      <div class="bill-con-val">
                        {{ invoice.program_details.txn_id }}
                      </div>
                    </li>
                    <li>
                      <div class="bill-heading">Mode of payment</div>
                      <div class="bill-con-val">
                        {{ invoice.program_details.payment_method }}
                      </div>
                    </li>
                    <li>
                      <div class="bill-heading">Date and Time</div>
                      <div class="bill-con-val">
                        {{
                          invoice.program_details.time * 1000 | date: 'short'
                        }}
                      </div>
                    </li>
                  </ul>
                </div>
                <form
                  ngNoForm
                  target="_blank"
                  method="post"
                  [action]="downloadUrl"
                >
                  <input type="hidden" name="wstoken" [value]="loginToken" />
                  <input type="hidden" name="wsfunction" [value]="wsfunction" />
                  <input type="hidden" name="moodlewsrestformat" value="json" />
                  <input type="hidden" name="token" [value]="loginToken" />
                  <input
                    type="hidden"
                    name="course_id"
                    [value]="invoice.program_details.program_id"
                  />

                  <div type="submit" class="billing-footer">
                    <h2>
                      <input
                        [tabindex]="modalopen ? -1 : 0"
                        (focus)="loadAudio(3)"
                        (focusout)="loadAudio(99)"
                        (mouseenter)="loadAudio(3)"
                        (mouseleave)="loadAudio(99)"
                        style="background: transparent; border: none"
                        type="submit"
                        value="Download Invoice"
                      />
                    </h2>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            class="row"
            *ngIf="billData.lessons.length != 0 && filter == 'lesson'"
          >
            <!-- <div>
              <h4 class="sub-title">Purchased Lessons</h4>
            </div> -->
            <div
              class="col-xs-6 padBottom"
              *ngFor="let lessonInvoice of billData.lessons"
            >
              <div class="billing-wrap">
                <div class="billing-wrap-con">
                  <div class="left">
                    <h2>{{ lessonInvoice.program_name }}</h2>
                    <p>{{ lessonInvoice.school_name }}</p>
                  </div>
                  <div class="right">
                    <h2>
                      <span style="font-weight: normal">Total Cost:</span
                      ><span style="padding-left: 3px"
                        >${{ lessonInvoice.cost }}</span
                      >
                    </h2>
                  </div>
                  <div class="text-uppercase lession-con">
                    <span>
                      {{ lessonInvoice.lesson_name }}:
                      <strong>${{ lessonInvoice.cost }}</strong></span
                    >
                  </div>
                  <ul>
                    <li>
                      <div class="bill-heading">Transaction ID</div>
                      <div class="bill-con-val">{{ lessonInvoice.txn_id }}</div>
                    </li>
                    <li>
                      <div class="bill-heading">Mode of payment</div>
                      <div class="bill-con-val">
                        {{ lessonInvoice.payment_method }}
                      </div>
                    </li>
                    <li>
                      <div class="bill-heading">Date and Time</div>
                      <div class="bill-con-val">
                        {{ lessonInvoice.time * 1000 | date: 'short' }}
                      </div>
                    </li>
                  </ul>
                </div>
                <form
                  ngNoForm
                  target="_blank"
                  method="post"
                  [action]="downloadUrl"
                >
                  <input type="hidden" name="wstoken" [value]="loginToken" />
                  <input type="hidden" name="wsfunction" [value]="wsfunction" />
                  <input type="hidden" name="moodlewsrestformat" value="json" />
                  <input type="hidden" name="token" [value]="loginToken" />
                  <input
                    type="hidden"
                    name="course_id"
                    [value]="lessonInvoice.program_id"
                  />
                  <input
                    type="hidden"
                    name="lesson_id"
                    [value]="lessonInvoice.lesson_id"
                  />
                  <div type="submit" class="billing-footer">
                    <h2>
                      <input
                        [tabindex]="modalopen ? -1 : 0"
                        (focus)="loadAudio(4)"
                        (focusout)="loadAudio(99)"
                        (mouseenter)="loadAudio(4)"
                        (mouseleave)="loadAudio(99)"
                        style="background: transparent; border: none"
                        type="submit"
                        value="Download Invoice"
                      />
                    </h2>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </tabset>
</div>
<ng-template #displayMessage>
  <div class="row centered">
    {{ noPurchasemsg }}
  </div>
</ng-template>
