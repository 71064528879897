/********************************************************************
 * Created ON:29/11/2018
 * Created by: Avaya
 * Description: Common success popup component
 *
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NodeService } from '../../services/node.service';
import { StorageService } from '../../services/storage.service';
import { MuteService } from '../../services/mute.service';
/**
 *
 */
@Component({
  selector: 'app-successmodal',
  templateUrl: './successmodal.component.html',
  styleUrls: ['./successmodal.component.css'],
})
export class SuccessmodalComponent implements OnInit {
  public success_message: any; // incoming success message that needs to be displayed
  public from_module: any;
  public sucessAudio = '';
  public allAudioTags: any;
  /**
   *
   * @param bsModalRef
   * @param nodeService
   * @param storageService
   * @param muteService
   */
  constructor(
    public bsModalRef: BsModalRef,
    private nodeService: NodeService,
    private storageService: StorageService,
    private muteService: MuteService,
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.muteService.muteFun();
    switch (this.from_module) {
      /**ToDo
       * resetpassword, changepassword,edit profile audios
       */
      case 'forgot_password':
        this.sucessAudio =
          '../../assets/audio/successmsg/Forgot_Password_Reset_Request .mp3';
        break;
      case 'reset_password':
        this.sucessAudio =
          '../../assets/audio/successmsg/Successfully_Changed_Password.mp3';
        break;
      case 'register':
        this.sucessAudio =
          '../../assets/audio/successmsg/Registration-Submitted_Message.mp3';
        break;
      case 'change_password':
        this.sucessAudio =
          '../../assets/audio/successmsg/Successfully_Changed_Password.mp3';
        break;
      case 'edit_profile':
        this.sucessAudio =
          '../../assets/audio/successmsg/Successfully_Edited_Profile.mp3';
        break;
      case 'contact_us':
        this.sucessAudio =
          '../../assets/audio/successmsg/Contact_Us_Message_Sent.mp3';
        break;
      default:
    }
  }
  /**
   * Close modal function
   */
  public successhideModal() {
    this.bsModalRef.hide();
    this.nodeService.addNode({ modalopen: false });
  }
}
