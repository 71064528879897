<!-- Enroll programs -->
<div class="modal-body" *ngIf="!signinModalState">
  <a
    href="javascript:void(0)"
    type="button"
    class="close-back pull-left"
    aria-label="Close payment dialogue"
    (click)="closeModal()"
    (focus)="loadAudio(0)"
    (focusout)="loadAudio(99)"
    (mouseenter)="loadAudio(0)"
    (mouseleave)="loadAudio(99)"
    tooltip="Close"
    placement="bottom"
  >
    <span aria-hidden="true">
      <img src="../../assets/img/icn_back.svg" class="ic_back" />
    </span>
  </a>
  <div class="payment-header">
    <h1 class="centered">Proceed to Checkout</h1>
  </div>

  <div class="left">
    <div class="payment-container">
      <div class="payment-details">
        <div class="img-container">
          <img
            class="responsive-img"
            src="assets/img/xWithCap.svg"
            alt="Decorative"
          />
        </div>
        <div class="left-con">
          <h3 tabindex="0">Selected training</h3>
          <h2 tabindex="0">{{ prog_name }}</h2>
          <div tabindex="0" [innerHtml]="desc"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="pt-right-con">
      <div>
        {{ message }}
      </div>
      <img src="assets/img/paypal.png" class="paypal" />
      <div *ngIf="userInfo; else signin">
        <div *ngIf="!purchase_status; else alreadyPurchased">
          <div class="paypalmsg">
            You will be redirected to Paypal website to complete your payment
          </div>
          <form [action]="this.paypalPaymentUrl" method="post" ngNoForm>
            <input type="hidden" name="cmd" value="_xclick" />
            <input type="hidden" name="charset" value="utf-8" />
            <input
              type="hidden"
              name="business"
              [value]="paypalDetails.paypal_account"
            />
            <input type="hidden" name="item_name" [value]="prog_name" />
            <input type="hidden" name="item_number" [value]="course_id" />
            <input type="hidden" name="quantity" value="1" />
            <input type="hidden" name="on0" value="User" />
            <input
              type="hidden"
              name="os0"
              [value]="userInfo.first_name + userInfo.last_name"
            />
            <input type="hidden" name="custom" [value]="custom_value" />

            <input
              type="hidden"
              name="currency_code"
              [value]="paypalDetails.currency"
            />
            <input type="hidden" name="amount" [value]="cost" />

            <input type="hidden" name="for_auction" value="false" />
            <input type="hidden" name="no_note" value="1" />
            <input type="hidden" name="no_shipping" value="1" />
            <input
              type="hidden"
              name="notify_url"
              [value]="
                purchase_type == 'program'
                  ? paypalDetails.prog_notify_url
                  : paypalDetails.lesson_notify_url
              "
            />
            <input
              type="hidden"
              name="return"
              value="https://helixopp.institute"
            />
            <input
              type="hidden"
              name="cancel_return"
              value="https://helixopp.institute"
            />
            <input type="hidden" name="rm" value="2" />
            <input
              type="hidden"
              name="cbt"
              value="Click here to enter your course"
            />

            <input
              type="hidden"
              name="first_name"
              [value]="userInfo.first_name"
            />
            <input
              type="hidden"
              name="last_name"
              [value]="userInfo.last_name"
            />
            <input type="hidden" name="address" value="" />
            <input type="hidden" name="city" value="" />
            <input type="hidden" name="email" [value]="userInfo.email" />
            <input type="hidden" name="country" value="" />

            <button
              (focus)="loadAudio(1)"
              (focusout)="loadAudio(99)"
              (mouseenter)="loadAudio(1)"
              (mouseleave)="loadAudio(99)"
              type="submit"
              class="button button--aylen btn btn-primary"
            >
              Pay with Paypal
            </button>
          </form>
        </div>
        <ng-template #alreadyPurchased>
          <div class="paypalmsg">This course is already purchased</div>
        </ng-template>
      </div>
      <ng-template #signin>
        <div class="paypalmsg">Please Sign In or Register to enroll</div>
        <button
          (focus)="loadAudio(2)"
          (focusout)="loadAudio(99)"
          (mouseenter)="loadAudio(2)"
          (mouseleave)="loadAudio(99)"
          class="button button--aylen btn btn-primary"
          (click)="signinModal(signin)"
        >
          Sign In / Register
        </button>
      </ng-template>
    </div>
  </div>
</div>
<!-- /Enroll programs -->
