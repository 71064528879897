/********************************************************************
 * Created ON:21/11/2018
 * Created by: Pallavi
 * Description: Program listing file.
 * Programs are listed based on school selected.
 * Revision History:
 * Modification date: 29/11/2018
 * Description: Seperation of detailed display of individual programs.
 **********************************************************************/

import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import {
  API_ENDPOINTS,
  API_FUNCTIONS,
} from '../shared/constant/apiEndpoint.constant';
import { WebApiService } from '../shared/services/web-api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PaymentComponent } from './payment/payment.component';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { StorageService } from '../shared/services/storage.service';
import { CommonService } from '../shared/services/common.service';
import { NodeService } from '../shared/services/node.service';
import { ErrormodalComponent } from '../shared/component/errormodal/errormodal.component';
import { MODAL_CONFIG } from '../shared/constant/modalConfig.constant';
import { MuteService } from '../shared/services/mute.service';

/**
 *
 */
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  animations: [
    trigger('state', [
      state(
        'visible',
        style({
          top: '{{topValue}}px',
          bottom: '{{bottomValue}}px',
          transform: 'scaleX(4.25) scaleY({{moveAnimation}})',
        }),
        { params: { moveAnimation: 3, topValue: -100, bottomValue: 50 } },
      ),
      state(
        'hidden',
        style({
          transform: 'scaleX(1) scaleY(1)',
        }),
      ),
      transition('* => visible', [animate('0.5s ease-out')]),
    ]),
  ],
})
export class AdminComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  @ViewChild(ViewDetailsComponent)
  viewModalInstance;
  public state: any;
  public modalRef: BsModalRef;
  public selectedSchool = null;
  public selectedProg = null;
  public selectedLessons = null;
  public loading = false;
  public receptionData: any;

  public showPanel = false;
  public returnBack = false;
  public innerWidth: any;
  public innerHeight: any;

  public totalwh: any;
  public totalActualwh: any;
  public scwidthAch: any;
  public totalScale: any;
  public transformY: any;

  public moveAnimation: any;
  public animateVlaue: any;
  public topValue: any;
  public bottomValue: any;

  public enroll = 'Enroll';
  public continue = 'Resume';
  public start = 'Begin';
  public prevState = {
    viewdetails: false,
    sid: null,
    pid: null,
    sname: null,
  };
  public adoUrl = '';
  public txtUrl = '';
  public viewprograms = false;
  public delay_zero = true;
  public max = 5;
  public isReadonly = true;
  public overStar: number;
  public selectedList: any = [];
  public percent: number;
  public programListData: any = [];
  public schoolId = 0;
  public loginToken: any;
  public schoolName = 'Admission';
  public menuOpen = false;
  public modalOpen = false;
  public receptionClass = 'reception';
  public childPaymentModal = false;
  public freeModule: any;
  public volumeElement: any;
  public myLearningElement: any;
  public allAudioTags: any;
  public adminAudioUrl = '';
  public character: any;
  public prevSelectedTab = 0;
  public schoolDisplayContent = '';
  public userInfo: any;
  public isProgramContainer = false;
  public isEnlarged = false;

  /**
   *
   * @param modalService
   * @param api
   * @param route
   * @param commonService
   * @param storageService
   * @param nodeService
   * @param bsModalRef
   * @param router
   * @param muteservie
   */
  constructor(
    private modalService: BsModalService,
    private api: WebApiService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private storageService: StorageService,
    private nodeService: NodeService,
    private bsModalRef: BsModalRef,
    private router: Router,
    private muteservie: MuteService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.receptionData = this.commonService.getReceptionImg();
      }
    });
  }
  /**
   *
   */
  ngOnDestory() {
    this.modalService.hide(this.modalService.getModalsCount());
  }

  /**
   *
   */
  ngOnInit() {
    this.callSiteInfoAPI();
    this.muteservie.muteFun();
    this.bsModalRef ? this.bsModalRef.hide() : null;
    this.receptionData = this.commonService.getReceptionImg();
    this.viewprograms = true;
    setTimeout(() => {
      this.delay_zero = false;
    }, 1000);
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.totalwh = this.innerWidth + this.innerHeight;
    this.totalScale = this.totalwh / 2304;
    const aaa = this.innerWidth / this.innerHeight;

    if (aaa < 1.78) {
      const scval = this.totalScale + 0.1;
      this.transformY = 'scaleY(' + scval + ')';
    }
    if (this.totalwh < 1793) {
      this.transformY = 'scaleY(1.34)';
    }
    if (this.totalwh > 1793 && this.totalwh < 2304) {
      this.transformY = 'scaleY(1.12)';
    }
    this.viewprogram();

    this.nodeService.nodeObservable.subscribe((node) => {
      if (node['progData'] !== undefined) {
        this.ProgEnrollmentCheck();
        if (this.selectedProg !== null && this.selectedSchool !== null) {
          let progPurchaseState = null;
          if (this.selectedLessons === null) {
            progPurchaseState =
              this.programListData[this.selectedSchool].programs[
                this.selectedProg
              ].program_payment;
          } else {
            progPurchaseState =
              this.programListData[this.selectedSchool].programs[
                this.selectedProg
              ].lessons[this.selectedLessons].lesson_payment;
            this.selectedLessons = null;
          }
          this.nodeService.addNode({ progPurchaseState: progPurchaseState });
          this.selectedProg = null;
          this.selectedSchool = null;
        }
      }
      if (node['isLogin'] !== undefined) {
        this.callSiteInfoAPI();
      }
      if (node['menuopen'] !== undefined) {
        this.menuOpen = node['menuopen'];
      }
      if (node['modalopen'] !== undefined) this.modalOpen = node['modalopen'];
    });
  }

  /**
   *
   */
  public callSiteInfoAPI() {
    const localStorageToken =
      this.storageService.getFromLocalStorage('loginToken');
    if (localStorageToken) {
      this.storageService.addToSessionStorage('loginToken', localStorageToken);
    }
    this.loginToken = this.storageService.getFromSessioStorage('loginToken');
    this.route.paramMap.subscribe((params) => {
      const audioElement = <HTMLAudioElement>(
        document.getElementById('audiofile')
      );
      audioElement ? audioElement.play() : null;
      this.txtUrl = '';
      this.showPanel = false;
      this.returnBack = true;
      this.viewprograms = false;
      this.nodeService.addNode({ viewProg: false });
      this.state = 'hidden';
      this.adminAudioUrl = '';
      if (this.childPaymentModal && this.modalRef) {
        this.nodeService.addNode({ returnPressed: true });
      }
      if (this.freeModule && this.modalRef) {
        this.nodeService.addNode({ freeModuleClose: true });
      }
      if (this.modalRef) {
        this.modalRef.hide();
        this.nodeService.addNode({ modalopen: false });
      }
      const id = params.get('id');
      this.schoolId = parseInt(id, 0);
      if (this.schoolId > 3) {
        this.schoolId = 0;
      }

      /**
       * Setting audio and video file URL and reception class
       */

      this.locateSelectedSchool();
      this.loading = true;
      const requestData = {
        endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
        data: { session_token: this.loginToken ? this.loginToken : '' },
        params: {
          token: this.loginToken
            ? this.loginToken
            : this.commonService.getWsToken(),
          function: API_FUNCTIONS.CONFIG,
          format: 'json',
        },
      };
      this.api.postApiCall(requestData).subscribe(
        (response: any) => {
          this.loading = false;
          if (response.error_code == 0) {
            const schools = response.siteconfig.schools;
            const programInfo: any = [];
            this.schoolName = schools[this.schoolId].name;
            for (let index = 0; index < schools.length; index++) {
              if (schools[index].orderno != 0) {
                programInfo.push(schools[index]);
              }
            }
            this.commonService.setProgListing(programInfo);
            this.programListData = this.commonService.getProgListing(
              this.schoolId,
            );
            console.log('Schools' + this.schoolId);
            this.nodeService.addNode({ progData: true });
            const paypalDetails = {
              prog_notify_url: response.siteconfig.course_notify_url,
              paypal_account: response.siteconfig.courselevel_paypal_account,
              currency: response.siteconfig.currency,
              lesson_notify_url: response.siteconfig.lesson_notify_url,
              paypal_address: response.siteconfig.paypal_address,
            };
            this.commonService.setPaypalDetails(paypalDetails);
          } else {
            this.loading = false;
            this.bsModalRef.hide();
            const initialState = { error_message: response.message };
            this.bsModalRef = this.modalService.show(
              ErrormodalComponent,
              Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
            );
          }
        },
        () => {
          // Error handling
          this.loading = false;
        },
      );
    });
  }

  /**setting attributes according to selected school */
  /**
   *
   */
  public locateSelectedSchool() {
    switch (this.schoolId) {
      case 0:
        this.receptionClass = 'reception';
        this.txtUrl = '../../assets/vtt/Inside_Admissions_Building.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Inside_Admissions_Building_New.mp3';
        this.character = '../../assets/img/professorCharacter/Girl1-min.gif';
        break;
      case 1:
        this.receptionClass = 'cooperative';
        this.txtUrl =
          '../../assets/vtt/Inside_Organizational_Development_Building.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Inside_Organizational_Development_Building_New.mp3';
        this.character = '../../assets/img/professorCharacter/Girl2-min.gif';
        this.schoolDisplayContent =
          'Welcome inside Helix Opportunity Institute’s Organizational Development School of Cooperative Understanding. Please, browse our organizational development programs located on the screen behind me.';
        break;
      case 2:
        this.receptionClass = 'commonsense';
        this.txtUrl =
          '../../assets/vtt/Inside_School_of_Common_Sense_Design.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Inside_School_of_Common_Sense_Design_New.mp3';
        this.character = '../../assets/img/professorCharacter/Guy-min.gif';
        this.schoolDisplayContent =
          'Welcome inside Helix Opportunity Institute’s School of Common Sense Design. Please, browse our design programs located on the screen behind me.';
        break;
      case 3:
        this.receptionClass = 'business';
        this.txtUrl = '../../assets/vtt/Inside_Business_Development_School.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Inside_Business_Development_School_New.mp3';
        this.character =
          '../../assets/img/professorCharacter/ProfessorBusiness2-min.gif';
        this.schoolDisplayContent =
          'Welcome inside Helix Opportunity Institute’s Business Development School of Global Inclusion. Please, browse our business development programs located on the screen behind me.';
        break;
      default:
        this.receptionClass = 'reception';
        this.txtUrl = '../../assets/vtt/LMS_Admission_Building.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Inside_Admissions_Building.mp3';
    }
  }

  /**checks if the all the lessons in the program is purchased.
   * If purchased, it will change the program purchase status to true.
   */
  /**
   *
   */
  public ProgEnrollmentCheck() {
    for (
      let schoolIdx = 0;
      schoolIdx < this.programListData.length;
      schoolIdx++
    ) {
      const prog = this.programListData[schoolIdx].programs;
      for (let progIdx = 0; progIdx < prog.length; progIdx++) {
        let count = 0;
        const lessons = prog[progIdx].lessons;
        for (let lessonIdx = 0; lessonIdx < lessons.length; lessonIdx++) {
          if (lessons[lessonIdx].lesson_payment == true) {
            count++;
          }
        }
        if (count == lessons.length) {
          this.programListData[schoolIdx].programs[progIdx].program_payment =
            true;
        }
      }
    }
  }

  /**
   *
   */
  public onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.totalwh = this.innerWidth + this.innerHeight;
    this.totalScale = this.totalwh / 2304;
    this.totalScale;
    const aaa = this.innerWidth / this.innerHeight;

    if (aaa < 1.78) {
      const scval = this.totalScale + 0.1;
      this.transformY = 'scaleY(' + scval + ')';
    }
    if (this.totalwh < 1793) {
      this.transformY = 'scaleY(1.34)';
    }
    if (this.totalwh > 1793 && this.totalwh < 2304) {
      this.transformY = 'scaleY(1.12)';
    }
  }

  // displays list of programs
  /**
   *
   */
  public viewprogram() {
    this.muteservie.muteFun();
    this.nodeService.addNode({ viewProg: true });
    this.returnBack = false;
    this.innerWidth = window.screen.width;
    this.innerHeight = window.screen.height;
    this.totalwh = this.innerWidth + this.innerHeight;
    this.totalActualwh = this.innerWidth / this.innerHeight;
    this.scwidthAch = this.innerWidth / 2304;
    this.totalScale = this.totalwh / 1704;
    if (this.totalActualwh > 1.76) {
      this.animateVlaue =
        this.totalActualwh + this.scwidthAch + this.totalScale + 0.1;
      this.topValue = -100;
      this.bottomValue = 50;
    } else {
      this.animateVlaue =
        this.totalActualwh + this.scwidthAch + this.totalScale + 1;
      this.topValue = -200;
      this.bottomValue = 0;
    }
    this.viewprograms = true;
    if (this.schoolId == 0) {
      document.getElementById('1-link').addEventListener('focus', handlefocus1);
      document.getElementById('2-link').addEventListener('focus', handlefocus2);
      document.getElementById('3-link').addEventListener('focus', handlefocus3);
      document
        .getElementById('1-link')
        .addEventListener('mouseover', handlefocus1);
      document
        .getElementById('2-link')
        .addEventListener('mouseover', handlefocus2);
      document
        .getElementById('3-link')
        .addEventListener('mouseover', handlefocus3);
    }
    const audioEle = <HTMLAudioElement>document.getElementById('AdminAudio');

    function handlefocus1() {
      audioEle.src =
        '../../assets/audio/adminbuilding/Catalog_Cooperative_Understanding_Tab.mp3';
    }
    function handlefocus2() {
      audioEle.src =
        '../../assets/audio/adminbuilding/Catalog_Common_Sense_Design_Tab.mp3';
    }
    function handlefocus3() {
      audioEle.src =
        '../../assets/audio/adminbuilding/Catalog_Global_Inclusion_Tab.mp3';
    }
  }

  /**
   * This function handles the enlarge button toggle
   */
  public enlargeScreen() {
    this.isEnlarged = !this.isEnlarged;
    const fooElement = document.getElementById('foo');
    if (fooElement) {
      fooElement.style.display = 'block';
      fooElement.setAttribute('class', this.isEnlarged ? 'hellix-main2' : `hellix-main admission-main ${this.receptionClass}`);
    }
  }

  /**
   *
   * @param schoolId
   */
  public catalogAudio(schoolId) {
    switch (schoolId) {
      case 0:
        this.adminAudioUrl =
          '../../assets/audio/adminbuilding/Admissions_Course_Catalog_welcome_page.mp3';
        break;
      case 1:
        this.adminAudioUrl =
          '../../assets/audio/adminbuilding/Cooperative_Understanding_Building_Catalog.mp3';
        break;
      case 2:
        this.adminAudioUrl =
          '../../assets/audio/adminbuilding/Common_ Sense_Design_Building_Catalog.mp3';
        break;
      case 3:
        this.adminAudioUrl =
          '../../assets/audio/adminbuilding/Business_Development_Building_Catalog.mp3';
        break;
      default:
        this.adminAudioUrl = '';
    }
  }
  /**
   *
   */
  return() {
    this.volumeElement = document.getElementById('volumeIcon');
    this.volumeElement.classList.remove('hide');

    if (this.loginToken) {
      this.myLearningElement = document.getElementById('myLearningIcon');
      this.myLearningElement.classList.remove('hide');
    }
    if (this.modalOpen) {
      if (this.childPaymentModal) {
        this.modalService.hide(this.modalService.getModalsCount());
        this.nodeService.addNode({ returnPressed: true });
      } else if (this.freeModule) {
        this.nodeService.addNode({ freeModuleClose: true });
      } else {
        this.modalRef.hide();
        this.nodeService.addNode({ modalopen: false });
      }
      this.catalogAudio(this.schoolId);
    } else {
      this.nodeService.addNode({ viewProg: false });
      this.showPanel = false;
      this.returnBack = true;
      this.viewprograms = false;
      this.state = 'hidden';
      this.adminAudioUrl = '';
      this.locateSelectedSchool();
      const audioElement = <HTMLAudioElement>(
        document.getElementById('audiofile')
      );
      audioElement.play();
    }
  }

  /**
   *
   * @param event
   */
  animationDone(event: AnimationEvent) {
    if (event.fromState === 'visible' && event.toState === 'hidden') {
      this.state = 'hidden';
    }
  }

  /**
   *
   * @param schoolIndex
   * @param progIndex
   */
  public ViewDetails(schoolIndex, progIndex) {
    // initilizing data that has to be passed to view details modal
    this.adminAudioUrl = '';
    this.selectedProg = progIndex;
    this.selectedSchool = schoolIndex;
    const initialState = {
      schoolName: this.programListData[schoolIndex].name,
      programDetails: this.programListData[schoolIndex].programs[progIndex],
      schoolId: this.programListData[schoolIndex].orderno,
    };
    // opening view details modal
    this.modalRef = this.modalService.show(
      ViewDetailsComponent,
      Object.assign({ initialState }, MODAL_CONFIG.viewdetailsconfig),
    );
    this.modalRef.content.event.subscribe((data) => {
      this.selectedLessons = data.lessonIndx;
    });
    this.modalRef.content.paymentModalStatusEvent.subscribe((data) => {
      this.childPaymentModal = data.paymentModalState;
    });
    this.modalRef.content.freeModuleStatusEvent.subscribe((data) => {
      this.freeModule = data.freeModuleOpen;
    });
    this.nodeService.addNode({ modalopen: true });
    this.isProgramContainer = true;
  }

  /**
   * navigates to classroom page that displays
   * selected program or prompts for payment
   * if not enrolled
   *
   * @param program
   * @param progIdx
   * @param schoolIdx
   */
  public programActions(program, progIdx, schoolIdx) {
    this.userInfo = this.storageService.getFromSessioStorage('userInfo'); // To Check Terms accepted or not
    if (this.userInfo != null) {
      this.selectedProg = progIdx;
      this.selectedSchool = schoolIdx;
      this.storageService.addToLocalStorage('prevState', this.prevState);
      if (program.program_payment) {
        let lid: any;
        let mid: any;
        for (let lIndx = 0; lIndx < program.lessons.length; lIndx++) {
          const lesson = program.lessons[lIndx];
          let foundMod = false;
          for (let mIndx = 0; mIndx < lesson.modules.length; mIndx++) {
            if (lesson.modules[mIndx].mod_name != 'quiz') {
              lid = lesson.lesson_id;
              mid = lesson.modules[mIndx].module_id;
            }
            if (lesson.modules[mIndx].module_progress != 100 && lid && mid) {
              foundMod = true;
              break;
            }
          }
          if (foundMod) break;
        }
        this.router.navigate(['/courses/classroom'], {
          queryParams: {
            pid: program.course_id,
            lid: lid,
            mid: mid,
            sid: this.schoolId,
          },
        });
      } else if (program.cost != 0) {
        const initialState = {
          course_id: program.course_id,
          desc: program.description,
          prog_name: program.name,
          cost: program.cost,
          message: program.paypal_message,
          purchase_type: 'program',
          cid: program.programm_level_cid,
          schoolId: this.schoolId,
          from: 'catalog',
        };

        this.nodeService.addNode({ modalopen: true });
        this.modalRef = this.modalService.show(
          PaymentComponent,
          Object.assign({ initialState }, MODAL_CONFIG.paymentconfig),
        );
        this.modalRef.content.closeEvent.subscribe((data) => {
          this.nodeService.addNode({ modalopen: data.paymentModalState });
        });
        this.isProgramContainer = true;
      } else {
        const progReqData = {
          endPoint: API_ENDPOINTS.COMMON_ENDPOINT,
          data: {
            token: this.loginToken
              ? this.loginToken
              : this.commonService.getGuestToken(),
            course_id: program.course_id,
          },
          params: {
            token: this.loginToken
              ? this.loginToken
              : this.commonService.getGuestToken(),
            function: API_FUNCTIONS.FREE_PROGRAM,
            format: 'json',
          },
        };
        this.api.postApiCall(progReqData).subscribe(
          (response: any) => {
            if(response['error_code'] === '0') {
              this.programListData[schoolIdx].programs[progIdx].program_payment = true;
              this.programListData[schoolIdx].programs[progIdx].lessons.forEach((lesson) => {
                lesson.lesson_payment = true;
              });
            } else {
              const initialState = {
                error_message: response['error_description']
              };
              this.modalRef = this.modalService.show(
                  ErrormodalComponent,
                  Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
              );
            }
          },
          () => {},
        );
      }
    } else {
      //this.router.navigate(['/terms']);

      const initialState = {
        error_message:
          '<h3>You are not enrolled in this course <b>' +
          program.name +
          '</b></h3><br/>You must sign in to your Helix Opportunity Institute account on the campus page, before viewing any content. Please register a new account if you don’t already have one.',
      };
      this.modalRef = this.modalService.show(
        ErrormodalComponent,
        Object.assign({ initialState }, MODAL_CONFIG.errormodalconfig),
      );
    }
  }

  /**Reception page catalog button audios.( Button behind the characters) */
  /**
   *
   */
  public loadCatalogBtnAudio() {
    switch (this.schoolId) {
      case 0:
        this.txtUrl =
          '../../assets/vtt/Admissions_Reception_Catalog_Button.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Admissions_Reception_Catalog_Button.mp3';
        break;
      case 1:
        this.txtUrl =
          '../../assets/vtt/Cooperative_Understanding_Reception_Catalog_Button.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Cooperative_Understanding_Reception_Catalog_Button.mp3';
        break;
      case 2:
        this.txtUrl =
          '../../assets/vtt/Common_Sense_reception_Catalog_Button.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Common_Sense_Design_Course_Catalog_Button.mp3';
        break;
      case 3:
        this.txtUrl =
          '../../assets/vtt/Business_School_Reception_Catalog_Button.vtt';
        this.adoUrl =
          '../../assets/audio/adminbuilding/reception/Global_Inclusion_Course_Catalog_Button.mp3';
        break;
      default:
        this.adoUrl = '';
    }
  }

  /**loading element audios based on school selected */
  /**
   *
   * @param eleNo
   * @param programData
   */
  public loadAudio(eleNo, programData?) {
    if (this.schoolId == 2) {
      switch (eleNo) {
        case 0:
          this.adminAudioUrl =
            '../../assets/audio/adminbuilding/commonsense/Common_Sense_Design_View_Details_Button.mp3';
          break;
        case 1:
          if (programData.program_payment)
            if (programData.progress == 0)
              this.adminAudioUrl =
                '../../assets/audio/shared/Start_Program_Button.mp3';
            //start
            else
              this.adminAudioUrl =
                '../../assets/audio/adminbuilding/commonsense/Resume_Program_Button.mp3';
          //continue
          else
            this.adminAudioUrl =
              '../../assets/audio/adminbuilding/commonsense/Common_Sense_Design_Enroll_Button.mp3'; //enroll
          break;
        case 2:
          this.adminAudioUrl =
            '../../assets/audio/adminbuilding/CommonSenseDesignSchoolReturnButton.mp3';
          break;
        default:
          this.adminAudioUrl = '';
      }
    } else if (this.schoolId == 3) {
      switch (eleNo) {
        case 0:
          this.adminAudioUrl =
            '../../assets/audio/adminbuilding/globalinclusion/Global_Inclusion_View_Details_Button.mp3';
          break;
        case 1:
          if (programData.program_payment)
            if (programData.progress == 0)
              this.adminAudioUrl =
                '../../assets/audio/adminbuilding/globalinclusion/Global_Inclusion_Begin_Program_Button.mp3';
            //start
            else
              this.adminAudioUrl =
                '../../assets/audio/adminbuilding/globalinclusion/Global_Inclusion_Resume_Program.mp3';
          //continue
          else
            this.adminAudioUrl =
              '../../assets/audio/adminbuilding/globalinclusion/Global_Inclusion_Enroll_Button.mp3'; //enroll
          break;
        case 2:
          this.adminAudioUrl =
            '../../assets/audio/adminbuilding/BusinessDevelopmentSchoolReturnButton.mp3';
          break;
        default:
          this.adminAudioUrl = '';
      }
    } else {
      switch (eleNo) {
        case 0:
          this.schoolId == 1
            ? (this.adminAudioUrl =
                '../../assets/audio/adminbuilding/cooperative/CoperativeUnderstandingViewDetailsButton.mp3')
            : (this.adminAudioUrl =
                '../../assets/audio/adminbuilding/View_Details_Button.mp3');
          break;
        case 1:
          if (programData.program_payment)
            if (programData.progress == 0)
              this.adminAudioUrl =
                '../../assets/audio/adminbuilding/Begin_Program_Button.mp3';
            //start
            else
              this.adminAudioUrl =
                '../../assets/audio/adminbuilding/Cooperative_Resume_Program.mp3';
          //continue
          else
            this.schoolId == 1
              ? (this.adminAudioUrl =
                  '../../assets/audio/adminbuilding/cooperative/CooperativeUnderstandingEnrollButton.mp3')
              : (this.adminAudioUrl =
                  '../../assets/audio/adminbuilding/Program_Enrollment_Button.mp3'); //enroll
          break;
        case 2:
          this.adminAudioUrl =
            '../../assets/audio/adminbuilding/Return_Button.mp3';
          break;
        default:
          this.adminAudioUrl = '';
      }
    }
    const audioId = document.getElementById('audiofile') as HTMLAudioElement;
    if (audioId)
      this.adminAudioUrl === ''
          ? audioId.ended
              ? null
              : audioId.play()
          : audioId.pause();
  }
  /**
   *
   * @param tabIdx
   */
  public changeTab(tabIdx) {
    this.prevSelectedTab = tabIdx;
  }

  /**
   *
   * @param cname
   */
  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}
