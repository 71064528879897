/********************************************************************
 * Created ON:06/11/2018
 * Created by: Avaya
 * Description: Main app component
 *
 * Revision History:
 * Modification date:
 * Description:
 **********************************************************************/
import { Component } from '@angular/core';
/**
 *
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Helix';
  public menu_open = false;
  public menu_close = false;

  /**
   *
   */
  constructor() {}
}
