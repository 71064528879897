<div class="profile-container">
  <div class="profile-content">
    <a
      href="javascript:void(0)"
      type="button"
      class="close-back pull-left roundbtn"
      aria-label="Close"
      tooltip="Close"
      placement="bottom"
      (click)="goBack()"
    >
      <span aria-hidden="true">
        <img src="../../assets/img/icn_back.svg" class="ic_back" />
      </span>
    </a>
    <div class="enroll-header">
      <p>{{ progName }}</p>
      <div class="left" style="width: calc(100% - 350px)">
        <h2>{{ lessonName }}</h2>
      </div>
    </div>
    <div>
      <iframe
        style="border: none"
        seamless
        [src]="quizUrl"
        width="100%"
        height="610px"
      ></iframe>
    </div>
  </div>
</div>
<!-- <ngx-loading show="true" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
